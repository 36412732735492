import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import MovieDetail from "./MovieDetail";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import { configState } from "../Config";
import {
  createSortHandler,
  newSearchSort,
} from "../components/TableSortHelperMethods";

/*
 **
 ** CodesTable
 **
 */
export class CodesTable extends React.Component {
  /*
   ** constructor
   **/
  constructor(props) {
    super(props);
    this.state = {
      source: this.props.source,
      movie_id: this.props.movie_id,
      code: this.props.code,
      desc: this.props.desc,
      selectedMovie: 0, // selected movie for detail section
      selectedPoster: "",
      selectedCountry: "",
      detailOpen: false,
      dataSource: [],
    };

    this.redoQuery = this.redoQuery.bind(this);
    this.redoQuery(
      this.props.source,
      this.props.movie_id,
      this.props.code,
      this.props.desc
    );
  }

  /*
   ** componentDidMount - when first mounting, do query, which is probably empty
   */
  componentDidMount() {
    this.redoQuery("", "", "", "");
  }

  /*
   ** Button click
   */
  handleClickOpen(movieId, country) {
    var poster = "https://www.movienewsletters.net/photos/" + movieId.toString() + "R1.jpg"
    this.setState({
      selectedMovie: movieId,
      selectedCountry: country,
      selectedPoster: poster,
      detailOpen: true,
    });
  }
  // Return from modal
  handleCloseModal = () => {
    this.setState({
      detailOpen: false,
    });
  };

  /*
   ** redoQuery - query from AJAX
   */
  async redoQuery(
    source,
    movie_id,
    code,
    desc
  ) {
    var url = configState.apiBase + "codes?function=search";

    if (source !== "") {
      url += "&source=" + encodeURIComponent(source)
    }
    if (movie_id !== "") {
      url += "&movie_id=" + encodeURIComponent(movie_id)
    }
    if (code !== "") {
      url += "&code=" + encodeURIComponent(code)
    }
    if (desc !== "") {
      url += "&desc=" + encodeURIComponent(desc)
    }
    console.log("Query URL: " + url);

    return fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        let newData = [];
        if (responseJson.rows) {
          if (this.state.orderBy)
            newData = newSearchSort(
              this.state.orderBy,
              this.state.order,
              responseJson.rows
            );
          else newData = responseJson.rows;
        }

        this.setState(
          {
            isLoading: false,
            dataSource: newData,
          },
          function () {
            // do something with new state
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });

  }

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps) {
    // if nothing important changed, we're done.
    if (
      prevProps.source === this.props.source &&
      prevProps.movie_id === this.props.movie_id &&
      prevProps.code === this.props.code &&
      prevProps.desc === this.props.desc
    ) {
      return;
    }

    this.setState({
      source: this.props.source, // search parameter
      movie_id: this.props.movie_id, // movie_id parameter
      code: this.props.code,
      desc: this.props.desc,
      onSelect: this.props.onSelect, // callback for selection
      isLoading: true,
      dataSource: [],
    });

    // New props, have to rerun query
    this.redoQuery(
      this.props.source,
      this.props.movie_id,
      this.props.code,
      this.props.desc
    );
  }



  /*
   ** render
   */
  render() {
    console.log("re-rendering movie table");
    // Still loading, show animation
    if (this.state.isLoading) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <span className="glyphicon-left glyphicon glyphicon-refresh spinning">
            <CircularProgress />
          </span>
        </div>
      );
    } else if (this.state.dataSource.length === 0) {
      return <div style={{ flex: 1, paddingTop: 20 }}>{""}</div>;
    }
    /* Movie table */
    return (
      <div>
        <MovieDetail
          open={this.state.detailOpen}
          movie_id={this.state.selectedMovie}
          country={this.state.selectedCountry}
          onClose={this.handleCloseModal}
          poster={this.state.selectedPoster}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <TableSortLabel
                  active={this.state.orderBy === "movie_id"}
                  direction={this.state.order}
                  onClick={createSortHandler("movie_id", this)}
                >
                  Movie ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={this.state.orderBy === "source"}
                  direction={this.state.order}
                  onClick={createSortHandler("source", this)}
                >
                  Source
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={this.state.orderBy === "code"}
                  direction={this.state.order}
                  onClick={createSortHandler("code", this)}
                >
                  Code
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={this.state.orderBy === "desc"}
                  direction={this.state.order}
                  onClick={createSortHandler("desc", this)}
                >
                  Description
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={this.state.orderBy === "attributes"}
                  direction={this.state.order}
                  onClick={createSortHandler("attributes", this)}
                >
                  Attributes
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.dataSource.map((row) => {
              return (
                <TableRow key={row.code_id} style={{ cursor: "pointer" }}>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        this.handleClickOpen(
                          row.movie_id,
                          "US"
                        );
                      }}
                      align="right"
                    >
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>{row.movie_id}</TableCell>
                  <TableCell>{row.source}</TableCell>
                  <TableCell>{row.code}</TableCell>
                  <TableCell>{row.desc}</TableCell>
                  <TableCell>{row.attributes}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );

  } // not isLoading
} // render
 // CodesTable.js
