import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { configState } from "../Config";

/*
 **
 ** MavenDetail
 **
 */
export class MavenDetail extends React.Component {
  /*
   ** constructor
   **/
  constructor(props) {
    super(props);
    this.state = {
      startdate: this.props.startdate,
      enddate: this.props.enddate,
      initials: this.props.initials,
      open: this.props.open,
      isLoading: true,
      dataSource: [],
    };

    this.showDetails = this.showDetails.bind(this);
    this.redoQuery = this.redoQuery.bind(this);
    this.redoQuery(this.props.startdate, this.props.enddate);
  }

  /*
   ** redoQuery - query from AJAX
   */
  async redoQuery(startDate, endDate, initials) {
    var url = "";
    var params = "";

    // API URL and parameters
    url = configState.apiBase + "mavenstats?function=details";
    if (startDate !== "" && startDate !== undefined) {
      params += "&startdate=" + startDate;
    } else params = "";
    if (endDate !== "" && endDate !== undefined) {
      params += "&enddate=" + endDate;
    } else params = "";
    if (initials !== "" && initials !== undefined) {
      params += "&initials=" + initials;
    } else params = "";

    // Require all params
    if (params === "") return;
    else url += params;

    // Get data
    console.log("Query URL: " + url);
    return fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState(
          {
            isLoading: false,
            dataSource: responseJson.rows == null ? [] : responseJson.rows,
          },
          function () {
            // do something with new state
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /*
   ** handleClose  - Close dialog
   */
  handleClose = () => {
    this.setState({
      open: false,
    });
    this.props.onClose();
  };

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    // if nothing important changed, we're done.
    if (
      prevProps.startdate === this.props.startdate &&
      prevProps.enddate === this.props.enddate &&
      prevProps.initials === this.props.initials
    ) {
      return;
    }

    this.setState({
      startdate: this.props.startdate, //
      enddate: this.props.enddate, //
      initials: this.props.initials,
      open: this.props.open,
      isLoading: true,
      dataSource: [],
    });

    // New props, have to rerun query
    this.redoQuery(this.props.startdate, this.props.enddate, this.props.initials);
  }

  showDetails() {
    // Still loading, show animation
    if (this.state.isLoading) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <span className="glyphicon-left glyphicon glyphicon-refresh spinning">
            <CircularProgress />
          </span>
        </div>
      );
    }

    if (
      this.state.dataSource.length === 0 &&
      (this.state.startdate !== "" || this.state.enddate !== "")
    ) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <h4>No Results</h4>
        </div>
      );
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Country</TableCell>
            <TableCell>Details</TableCell>
            <TableCell>Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.dataSource.map((row, index) => {
            return (
              <TableRow key={index} style={{ cursor: "pointer" }}>
                <TableCell align="right" component="th" scope="row">
                  {row.country}
                </TableCell>

                <TableCell>{row.initials}</TableCell>

                <TableCell>{row.updatecount}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  /*
   ** render
   */
  render() {

    /* Graph table */
    return (
      <div>
        <Dialog
          maxWidth="xl"
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              x
            </Button>
          </DialogActions>

          <DialogTitle id="responsive-dialog-title">
            Maven Details {this.state.initials}
          </DialogTitle>
          <DialogContent>{this.showDetails()}</DialogContent>
        </Dialog>
      </div>
    );
  } // render
} // MavenDetail.js

export default MavenDetail;
