import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

import { fetchData } from "./helpers";

const Row = ({ dupe = null, addNewDupe = null, updateDupe = null }) => {
  const [loading, setLoading] = useState(false);
  const [currentId, setCurrentId] = useState(dupe ? `${dupe.current_id}` : "");
  const [nextId, setNextId] = useState(dupe ? `${dupe.next_id}` : "");

  const handleSave = () => {
    setLoading(true);
    fetchData({
      endpoint: !!dupe ? "dupes/update" : "dupes/create",
      body: !!dupe ? { id: dupe.id, current_id: currentId, next_id : nextId} : { current_id: currentId, next_id : nextId},
      handleComplete: (response) => {
        setLoading(false);
        if (addNewDupe && response.data) {
          addNewDupe(response.data);
          setCurrentId("");
          setNextId("");
        }
        if (updateDupe && response.data) {
          updateDupe(response.data)
        }
      },
    });
  };
  
  return (
    <TableRow>
      <TableCell>
        <FormControl>
        <TextField
          label={"Current movie ID"}
          name="currentId"
          type="text"
          value={currentId}
          onChange={(e) => {
            setCurrentId(e.target.value);
          }}
          margin="normal"
        />
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl>
        <TextField
          label={"Next movie ID"}
          name="nextId"
          type="text"
          value={nextId}
          onChange={(e) => {
            setNextId(e.target.value);
          }}
          margin="normal"
        />
        </FormControl>
      </TableCell>

      <TableCell>
        {loading ? (
          <CircularProgress />
        ) : 
          (!!nextId && !!currentId && (!dupe || (`${dupe.next_id}` !== nextId || `${dupe.current_id}` !== currentId))) ?
        (
          <Button
            onClick={handleSave}
            variant="contained"
          >
            {!!dupe ? "Update" : "Add"}
          </Button>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default Row;
