import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

class TextBox extends React.Component {
	state = {
		open: false,
		text: this.props.text,
		title: this.props.title
	};

	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	render() {
		return (
			<div>
				<Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
					View
				</Button>
				<Dialog
					open={this.state.open}
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{this.state.title}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{this.state.text}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary" autoFocus>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}

export default TextBox;
