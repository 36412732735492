import React from "react";
import Divider from "@material-ui/core/Divider";

/*
 ** formatMovie - show up to three movies unless showing extra
 */
export default function ShowtimeMovieCards(row, data, parent) {
  data.movieCounter++;
  if (row.movie_id === data.oldMovie) return;
  else data.oldMovie = row.movie_id;
  const overflowMoves = data.movieCounter <= 3;
  return (
    <div
      className={overflowMoves ? "movie-container" : "movie-extra"}
      style={
        overflowMoves
          ? { marginTop: 20 }
          : { marginTop: 20, display: data.showExtra }
      }
    >
      <div style={{ marginBottom: 15 }}>
        <Divider />
      </div>
      <b
        onClick={() => {
          parent.setState({
            selectedMovie: parseInt(row.movie_id, 10),
            selectedCountry: row.country,
            detailOpen: true,
          });
          console.log("Selected Movie: " + parent.state.selectedMovie);
        }}
        style={{ cursor: "pointer", marginTop: 10, fontSize: 20 }}
      >
        {row.title}
      </b>
      <small style={{ marginLeft: 5, fontSize: 10 }}>
        ({row.mpaa})<br></br>{" "}
      </small>
    </div>
  );
}
