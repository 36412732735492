export const configVars = {
  live: {
    apiRoot: "https://api.scoreboard.webedia.us/",
    siteState: "LIVE",
  },
  staging: {
    apiRoot: "https://staging.api.scoreboard.webedia.us/",
    siteState: "STAGING",
  },
  dev: {
    apiRoot:
      "https://staging.api.scoreboard.webedia.us/" /* Development: 'http://cinemascoreapi.local/', */,
    siteState: "DEV",
  },
};

const hostname = window && window.location && window.location.hostname;

export var configState = {
  apiBase: "xxxxx",
  siteState: "xxxxx",
};
// export var apiBase = 'XXXXXX'
// export var siteState = 'XXXXX'

if (hostname === "staging.scoreboard.webedia.us") {
  configState.apiBase = configVars.staging.apiRoot;
  configState.siteState = configVars.staging.siteState;
} else if (
  hostname === "scoreboard.webedia.us" ||
  hostname === "www.scoreboard.webedia.us"
) {
  configState.apiBase = configVars.live.apiRoot;
  configState.siteState = configVars.live.siteState;
} else {
  configState.apiBase = configVars.dev.apiRoot;
  configState.siteState = configVars.dev.siteState;
}
