import React, { useEffect, useReducer, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import TextArea from "@material-ui/core/TextareaAutosize";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import isEqual from "lodash/isEqual";

import { fetchData } from "../../utils/fetchData";

const tryGetValue = (data, key, defaultValue = "") => {
  if (data && key in data) {
    return data[key] || defaultValue;
  }
  return defaultValue;
};

const fillValues = (data, isNew = false) => ({
  id: !isNew ? tryGetValue(data, "id") : null,
  typeloop: tryGetValue(data, "typeloop", "I"),
  name: tryGetValue(data, "name", "New Import"),
  automater: tryGetValue(data, "automater"),
  prefix: tryGetValue(data, "prefix"),
  gtsource: tryGetValue(data, "gtsource"),
  cssource: tryGetValue(data, "cssource"),
  intminutes: tryGetValue(data, "intminutes", 30),
  directory: tryGetValue(data, "directory"),
  urlfile: tryGetValue(data, "urlfile"),
  sendtoweb: tryGetValue(data, "sendtoweb", false),
  lastrun: !isNew ? tryGetValue(data, "lastrun") : "",
  file_limit: tryGetValue(data, "file_limit", 25),
  batch_file: tryGetValue(data, "batch_file"),
  run_once: tryGetValue(data, "run_once", false),
  url_file_content: tryGetValue(data, "url_file_content"),
});

const Form = ({ onClose, onComplete, data = null, isNew = false }) => {
  const [loading, setLoading] = useState(false);
  const [showCustomUrls, setShowCustomUrls] = useState(
    !!tryGetValue(data, "url_file_content"),
  );
  const [values, dispatchValues] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_VALUE":
          return {
            ...state,
            [action.key]: action.value,
          };
        default:
          throw Error("Unknown action.");
      }
    },
    fillValues(data, isNew),
  );

  useEffect(() => {
    if (
      showCustomUrls &&
      values.urlfile.includes(".txt") &&
      !values.urlfile.includes(".custom.txt")
    ) {
      setValue("urlfile", values.urlfile.replace(".txt", ".custom.txt"));
    }
    if (!showCustomUrls && values.urlfile.includes(".custom.txt")) {
      setValue("urlfile", values.urlfile.replace(".custom.txt", ".txt"));
    }
  }, [values.urlfile, showCustomUrls]);

  const handleSave = () => {
    setLoading(true);
    fetchData({
      endpoint: isNew ? "imports/create" : "imports/update",
      body: values,
      handleComplete: (response) => {
        if (isNew) {
          setValue("id", tryGetValue(response.data, "id"));
        }
        onComplete(response.data);
      },
      handleFinally: () => setLoading(false),
    });
  };

  const setValue = (key, value) =>
    dispatchValues({ type: "SET_VALUE", key, value });

  return (
    <Dialog open onClose={onClose}>
      <div
        style={{
          alignItems: "center",
          display: "grid",
          gridTemplateColumns: `repeat(6,minmax(0, 1fr))`,
          gap: "1rem",
          padding: "1rem",
          width: "100%",
        }}
      >
        <FormControl style={{ gridColumn: "span 1" }}>
          <InputLabel id="typeloop">Type Loop</InputLabel>
          <Select
            value={values.typeloop}
            name="typeloop"
            labelId="typeloop"
            onChange={(e) => {
              setValue("typeloop", e.target.value);
            }}
          >
            <MenuItem value="I">Import</MenuItem>
            <MenuItem value="G">Gather</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ gridColumn: "span 5" }}>
          <TextField
            label={"Name"}
            name="name"
            type="text"
            value={values.name}
            onChange={(e) => {
              setValue("name", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl style={{ gridColumn: "span 1" }}>
          <TextField
            label={"Automater"}
            name="automater"
            type="text"
            value={values.automater}
            onChange={(e) => {
              setValue("automater", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>
        <FormControl style={{ gridColumn: "span 5" }}>
          <TextField
            label={"Prefix"}
            name="prefix"
            type="text"
            value={values.prefix}
            onChange={(e) => {
              setValue("prefix", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>
        <FormControl style={{ gridColumn: "span 3" }}>
          <TextField
            label={"GT Source"}
            name="gtsource"
            type="text"
            value={values.gtsource}
            onChange={(e) => {
              setValue("gtsource", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>
        <FormControl style={{ gridColumn: "span 3" }}>
          <TextField
            label={"CS Source"}
            name="cssource"
            type="text"
            value={values.cssource}
            onChange={(e) => {
              setValue("cssource", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>
        <FormControl style={{ gridColumn: "span 6" }}>
          <TextField
            label={"Directory"}
            name="directory"
            type="text"
            value={values.directory}
            onChange={(e) => {
              setValue("directory", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>
        <FormControl style={{ gridColumn: "span 5" }}>
          <TextField
            label={"URL File"}
            name="urlfile"
            type="text"
            value={values.urlfile}
            onChange={(e) => {
              setValue("urlfile", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>
        <FormControl style={{ gridColumn: "span 1" }}>
          <FormLabel>
            Custom content
            <Checkbox
              checked={showCustomUrls}
              onChange={(_, checked) => {
                setShowCustomUrls(checked);
                if (!checked) {
                  setValue("url_file_content", "");
                }
              }}
              title="sendtoweb"
            ></Checkbox>
          </FormLabel>
        </FormControl>
        {showCustomUrls && (
          <FormControl style={{ gridColumn: "span 6" }}>
            <TextArea
              value={values.url_file_content}
              onChange={(e) => setValue("url_file_content", e.target.value)}
              style={{ resize: "vertical" }}
            />
          </FormControl>
        )}
        {values.typeloop === "I" && (
          <FormControl style={{ gridColumn: "span 6" }}>
            <TextField
              label={"Batch File"}
              name="batch_file"
              type="text"
              value={values.batch_file}
              onChange={(e) => {
                setValue("batch_file", e.target.value);
              }}
              margin="normal"
            />
          </FormControl>
        )}
        <FormControl style={{ gridColumn: "span 1" }}>
          <FormLabel>
            Send to web
            <Checkbox
              checked={values.sendtoweb}
              onChange={() => setValue("sendtoweb", !values.sendtoweb)}
              title="sendtoweb"
            ></Checkbox>
          </FormLabel>
        </FormControl>
        <FormControl style={{ gridColumn: "span 1" }}>
          <FormLabel>
            Run once
            <Checkbox
              checked={values.run_once}
              onChange={() => setValue("run_once", !values.run_once)}
              title="run_once"
            ></Checkbox>
          </FormLabel>
        </FormControl>
        <FormControl style={{ gridColumn: "span 2" }}>
          <TextField
            label={"Int minutes"}
            name="intminutes"
            type="number"
            value={values.intminutes}
            onChange={(e) => {
              setValue("intminutes", Number(e.target.value));
            }}
            margin="normal"
          />
        </FormControl>
        <FormControl style={{ gridColumn: "span 2" }}>
          <TextField
            label={"File limit"}
            name="file_limit"
            type="number"
            value={values.file_limit}
            onChange={(e) => {
              setValue("file_limit", Number(e.target.value));
            }}
            margin="normal"
          />
        </FormControl>

        <DialogActions style={{ gridColumn: "span 6" }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              disabled={isEqual(fillValues(data), values)}
            >
              Save
            </Button>
          )}
          <Button onClick={onClose} variant="contained" color="secondary">
            Close
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default Form;
