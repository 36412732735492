import React, { useState, useEffect, useReducer, Fragment } from "react";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import uniqueId from "lodash/uniqueId";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { fetchData } from "./helpers";
import Wrapper from "./Wrapper";
import CodesTable from "./Table";
import Code from "./Code";

const CodesList = ({ setTitle }) => {
  const [loading, setLoading] = useState(false);
  const [filterSource, setFilterSource] = useState("");
  const [filterSourceInput, setFilterSourceInput] = useState("");
  const [filterCode, setFilterCode] = useState("");
  const [allSources, setAllSources] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [unmappedSources, setUnmappedSources] = useState([]);
  const [statuses, setStatuses] = useState(["UNMAPPED"]);
  const [showUnmappedSources, setShowUnmappedSources] = useState(true);
  const [codes, dispatchCodes] = useReducer((codes, action) => {
    switch (action.type) {
      case "FILL":
        return action.nextCodes;
      case "UPDATE":
        return codes.map((code) =>
          (action.matchOn === "code" && action.id === code.code) ||
          (action.matchOn === "id" && action.id === code.id)
            ? { ...code, ...action.data }
            : code,
        );
      default:
        throw Error("Unknown action.");
    }
  }, []);

  useEffect(() => {
    setTitle("Needed code mappings");
    setLoading(true);
    fetchData({
      endpoint: "mapcodes/sources",
      handleComplete: (response) => {
        setAllSources(response.data.all);
        setUnmappedSources(response.data.unmapped);
        setLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    if (filterSource) {
      setLoading(true);
      fetchData({
        endpoint: "mapcodes/list",
        body: {
          source: filterSource,
        },
        handleComplete: (response) => {
          dispatchCodes({ type: "FILL", nextCodes: response.data.codes });
          setLoading(false);
        },
      });
    } else {
      setLoading(true);
      fetchData({
        endpoint: "mapcodes/sources",
        handleComplete: (response) => {
          setUnmappedSources(response.data.unmapped);
          setLoading(false);
        },
      });
    }
  }, [filterSource]);

  return (
    <Fragment>
      <Wrapper
        full
        loading={loading}
        header={
          <div
            style={{
              display: "grid",
              gap: "1rem",
              gridTemplateColumns: "2fr 1fr 2fr 1fr 1fr 1fr",
              justifyContent: "start",
              padding: "1rem 0",
            }}
          >
            <FormControl>
              <Autocomplete
                options={allSources}
                inputValue={filterSourceInput}
                onChange={(_, row) => {
                  setFilterSource(row);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Source"
                    value={filterSourceInput}
                    onChange={(e) => setFilterSourceInput(e.target.value)}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                label="Display unmapped sources"
                control={
                  <Switch
                    checked={showUnmappedSources}
                    onChange={(_, checked) => setShowUnmappedSources(checked)}
                  />
                }
              />
            </FormControl>
            {!!codes.length ? (
              <FormControl>
                <TextField
                  label="Search"
                  value={filterCode}
                  onChange={(e) => setFilterCode(e.target.value)}
                />
              </FormControl>
            ) : (
              <div />
            )}
            {!!codes.length ? (
              <FormControl>
                <InputLabel id="statuses">Status</InputLabel>
                <Select
                  value={statuses.join(",")}
                  name="statuses"
                  labelId="Status"
                  onChange={(e) => {
                    setStatuses(e.target.value.split(","));
                  }}
                >
                  <MenuItem value="UNMAPPED">Unmapped</MenuItem>
                  <MenuItem value="TO_MAP,TO_UPDATE">
                    Queued for mapping
                  </MenuItem>
                  <MenuItem value="COMPLETE,SKIPPED">Mapped</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <div />
            )}
            {filterSource ? (
              <Button onClick={() => setIsNew(!isNew)}>Add new code</Button>
            ) : (
              <div />
            )}
          </div>
        }
      >
        {isNew && (
          <Table>
            <TableBody>
              <Code
                code={{ source: filterSource, code: "", external_title: "" }}
                isNew
                handleUpdateData={(matchOn, id, data) => {
                  dispatchCodes({ type: "UPDATE", matchOn, id, data });
                  setIsNew(false);
                }}
              />
            </TableBody>
          </Table>
        )}
        {showUnmappedSources ? (
          <div>
            {unmappedSources.map((source) => (
              <Button
                key={uniqueId("source")}
                onClick={() => {
                  setFilterSource(source);
                  setFilterSourceInput(source);
                  setShowUnmappedSources(false);
                }}
              >
                {source}
              </Button>
            ))}
          </div>
        ) : (
          <CodesTable
            codes={codes}
            filterCode={filterCode}
            handleUpdateData={(matchOn, id, data) =>
              dispatchCodes({ type: "UPDATE", matchOn, id, data })
            }
            hasPagination
            statuses={statuses}
          />
        )}
      </Wrapper>
    </Fragment>
  );
};

export default CodesList;
