import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImageBox from "../ImageBox";
import Button from '@material-ui/core/Button';
import { createSortHandler } from "../TableSortHelperMethods";
import { configState } from "../../Config";

import TikTokConfigurationsAdd from "./TikTokConfigurationsAdd";

/* Class definition */
export default class TikTokConfigurationsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            dataSource: [],
            statsAnchorEl: null,
            addOpen: false,
            row: {}
        };

        this.executeQuery = this.executeQuery.bind(this);
        this.openAdd = this.openAdd.bind(this);
    }

    /*
     ** wasSearching - return true if there was a search in progress, false if all search params are blank
     */
    wasSearching() {
        if (Object.keys(this.props.searchData).length === 0) {
            return "Please enter search parameters and click the SEARCH button";
        } else {
            return "No results found";
        }
    }
    /*
     ** executeQuery - get data from API
     */
    async executeQuery() {
        let url = configState.apiBase + "imports?function=search";
        const filterPropKeys = ['crawler', 'id', 'run_status'];
        Object.keys(this.props.searchData).forEach(key => {
            if (filterPropKeys.includes(key) && !!this.props[key])
                url += "&" + key + '=' + this.props[key];
        });
        console.log("Tiktok Config Table: Loading URL: " + url);
        this.setState({ isLoading: true });
        try {
            const response = await fetch(url);
            const responseJson = await response.json();

            this.setState({
                isLoading: false,
                dataSource: responseJson.rows == null ? [] : responseJson.rows, // Full dataset
            });
        } catch (error) {
            console.error(error);
        }
    }

    /*
     ** toggleEnabled - enable or disable a config
     */
    async toggleEnabled(index) {
        let url = configState.apiBase + "imports?function=toggle&id=";
        url += this.state.dataSource[index]["id"] + "&enabled=";

        if (this.state.dataSource[index]["enabled"]) {
            url += 0;
            this.state.dataSource[index]["enabled"] = false;
        }
        else {
            url += 1;
            this.state.dataSource[index]["enabled"] = true;
        }

        console.log("Tiktok Config Table: Loading URL: " + url);
        try {
            await fetch(url);
            this.setState({ dataSource: this.state.dataSource });
        } catch (error) {
            console.error(error);
        }
    }


    openAdd = (row = {}) => () => {
        if (row.id) {
            const copy = {};
            ["crawler",
                "alt_prefix",
                "codes_source",
                "urls_file",
                "day_range",
                "run_interval_minutes",
                "id"].forEach(key => copy[key] = row[key]);

            this.setState({
                row: copy,
                addOpen: true
            });
        }
        else
            this.setState({
                row: row,
                addOpen: !this.state.addOpen
            });
    };

    /*
     ** kill - kills a config
     */
    async kill(runId) {
        let url = configState.apiBase + "imports?function=kill&id=" + runId;
        console.log("Tiktok Config Table: Loading URL: " + url);
        try {
            await fetch(url);
            this.executeQuery();
            // this.state.dataSource.splice(index, 1);
            // this.setState({ dataSource: this.state.dataSource });
        } catch (error) {
            console.error(error);
        }
    }
    /*
     ** Got new props. So change state if they really changed.
     */
    componentDidUpdate(prevProps) {
        if (this.props.crawler !== prevProps.crawler || this.props.id !== prevProps.id || this.props.run_status !== prevProps.run_status) {
            // Update the state and get the new data
            this.setState({
                searchData: this.props.searchData,
                isLoading: true,
                dataSource: [],
            });
            // Actually execute a new data fetch
            this.executeQuery();
        }
    }

    tableRefreshed = (value) => {
        this.setState({ refresh: value });
    };

    componentDidMount() {
        this.executeQuery();
    }

    /*
     ** render
     */
    render() {

        let returnDiv = <React.Fragment />;
        // Still loading, show animation
        if (this.state.isLoading) {
            returnDiv = <CircularProgress />;
        } else if (this.state.dataSource.length === 0) {
            returnDiv = (
                <div style={{ flex: 1, paddingTop: 20 }}>{this.wasSearching()}</div>
            );
        } else {
            /* Chain table */

            console.log("Tiktok Config Table: Rerendering.");
            returnDiv = (
                <div>
                    <ImageBox
                        open={this.state.imageOpen}
                        movie_id={this.state.selectedMovie}
                        posterUrl={this.state.selectedPoster}
                        onClose={this.handleCloseImage}
                    />
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            active={this.state.orderBy === "id"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("id", this)}
                                        >
                                            Id
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={this.state.orderBy === "crawler"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("crawler", this)}
                                        >
                                            Crawler
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={this.state.orderBy === "run_status"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("run_status", this)}
                                        >
                                            Status
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={this.state.orderBy === "run_message"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("run_message", this)}
                                        >
                                            Message
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        Update
                                    </TableCell>
                                    <TableCell>
                                        Toggle
                                    </TableCell>
                                    <TableCell>
                                        Kill
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {this.state.dataSource.map((row, index) => {
                                    return <TableRow key={index}>
                                        <TableCell padding="normal" component="th" scope="row">
                                            <span>{row["id"]}</span>
                                        </TableCell>
                                        <TableCell padding="normal" component="th" scope="row">
                                            <span>{row["crawler"]}</span>
                                        </TableCell>
                                        <TableCell padding="normal" component="th" scope="row">
                                            <span>{row["run_status"]}</span>
                                        </TableCell>
                                        <TableCell padding="normal" component="th" scope="row">
                                            <span>{row["run_message"]}</span>
                                        </TableCell>
                                        <TableCell align={"center"} padding="normal" component="th" scope="row">
                                            <Button onClick={this.openAdd(row)} color="primary" variant="contained">Update</Button>
                                        </TableCell>
                                        <TableCell align={"center"} padding="normal" component="th" scope="row">
                                            <Button onClick={() => { this.toggleEnabled(index) }} color={row["enabled"] ? "primary" : "secondary"} variant="contained">{row["enabled"] ? "Enabled" : "Disabled"}</Button>
                                        </TableCell>
                                        <TableCell align={"center"} padding="normal" component="th" scope="row">
                                            <Button onClick={() => { this.kill(row["run_id"]) }} color={"secondary"} variant="contained">Kill</Button>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            );
        } // not isLoading

        return (
            <React.Fragment>
                {returnDiv}
                <TikTokConfigurationsAdd executeQuery={this.executeQuery} row={this.state.row} openAdd={this.openAdd()} addOpen={this.state.addOpen} />
            </React.Fragment>);
    } // render
}
