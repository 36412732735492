import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { fetchData } from "./helpers";

const CodeFile = ({ file, allSources = [] }) => {
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState(file.source || "");

  const handleUpdate = () => {
    setLoading(true);
    fetchData({
      endpoint: "mapcodes/updateFile",
      body: {
        id: file.id,
        source,
      },
      handleComplete: (data) => {
        setLoading(false);
      },
    });
  };

  return (
    <TableRow>
      <TableCell>{file.file_name}</TableCell>
      <TableCell>
        <FormControl>
          <Autocomplete
            options={allSources}
            filterSelectedOptions
            inputValue={source}
            onInput={(e) => {
              setSource(e.target.value.toUpperCase());
            }}
            onChange={(e, data) => {
              setSource(data);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                margin="normal"
                label="Source"
                placeholder="Pick a source"
                style={{ width: "18.75rem" }}
              />
            )}
          />
        </FormControl>
      </TableCell>
      <TableCell>
        {file.checked_at ? new Date(file.checked_at).toLocaleString() : ""}
      </TableCell>
      <TableCell>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            disabled={source === file.source}
            onClick={handleUpdate}
            variant="contained"
          >
            Save
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default CodeFile;
