import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { TableBody } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import { configState } from "../Config";

/* Style */
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: 30,
    marginTop: 20,
    marginBottom: 100,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  hidden: {
    display: "none",
  },
  visible: {
    display: "inline",
  },
});

class OpeningDetail extends React.Component {
  state = {
    open: false,
    chain_id: 0,
    dataSource: [],
  };

  // handleClose
  handleClose = () => {
    this.setState({
      open: false,
    });
    this.props.onClose();
  };

  /*
   ** executeQuery - get data from API
   */
  async executeQuery(chain_id) {
    var url = configState.apiBase + "opendetail?function=search";
    if (chain_id !== "") url += "&chain_id=" + chain_id;
    console.log("Loading: " + url);
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows,
        },
        function () {
          // do something with new state
          console.log("OpeningDetail: got new data state");
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    console.log(
      "OpeningDetail: got new props with chain_id=" + this.props.chain_id
    );

    // If nothing important changed, we're done.
    if (
      prevProps.chain_id === this.props.chain_id &&
      prevProps.open === this.props.open
    )
      return;

    if (this.props.chain_id > 0) {
      this.setState({ open: true });
      this.executeQuery(this.props.chain_id);
    } else {
      this.setState({ open: false });
    }

    this.setState({
      chain_id: this.props.chain_id, // chain_id parameter
    });
  }

  /*
   ** showDetails - show the record
   */
  showDetails(classes) {
    if (this.state.dataSource.length === 0) {
      return <div style={{ flex: 1, paddingTop: 20 }}>No Data.</div>;
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>HouseID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>City</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Earliest</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.dataSource.map((row) => {
            return (
              <TableRow key={row.house_id}>
                <TableCell padding="dense" component="th" scope="row">
                  {row.house_id}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.city}</TableCell>
                <TableCell>{row.state}</TableCell>
                <TableCell align="right">{row.min}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  /*
   ** render
   */
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {this.props.title}
          </DialogTitle>
          <DialogContent>
            <b>Chain {this.state.chain_id}</b>
            <br></br>
            {this.showDetails(classes)}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Close
            </Button>
            <Button onClick={this.handleEdit} color="primary" autoFocus>
              Edit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(OpeningDetail);
