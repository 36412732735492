import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import Nav from "./Nav";
import Token from "../Token";

const Wrapper = ({ children, loading, header = null, full = true }) => (
  <div
    style={{
      margin: "0 auto",
      maxWidth: full ? "100%" : "62.5rem",
      padding: full ? "1rem" : "0",
      width: "100%",
    }}
  >
    <Token />
    <Nav />
    {header}
    {loading ? <CircularProgress /> : children}
  </div>
);

export default Wrapper;
