import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function Loading() {
  return (
    <div style={{ flex: 1, paddingTop: 20 }}>
      <span className="glyphicon-left glyphicon glyphicon-refresh spinning">
        <CircularProgress />
      </span>
    </div>
  );
}
export default Loading;
