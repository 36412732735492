import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import Token from "../components/Token";
import { styles } from "../components/CsStyles";
import TikTokRequests from "../components/TikTokRequests";
import TikTokConfigurations from "../components/TikTok/TikTokConfigurations";
import TikTokReports from "../components/TikTok/TikTokReports";
import AWSRequests from "../components/TikTok/AWSRequests";
import APIResults from "../components/TikTok/APIResults";

import Fade from "@material-ui/core/Fade";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";


/*
 ** TikTok - a page for accessing tiktok
 */
class TikTok extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchData: {},
            searchCriteria: [],
            state_and_city: false,
            tableIndex: "configuration",
            queryUrl: "",
            baseQueryUrl: "",
            searchMainField: "",
        };

        // Title
        if (this.props.setTitle != null) {
            this.props.setTitle("TikTok");
        }
    }


    /*
     **
     ** fetchData
     **
     */
    handleSearchCriteria = (data) => () => {
        if (data) {
            if (data.main !== undefined && data.main !== "") {
                data[this.state.searchMainField] = data.main;
            }

            let newData = { ...data };

            if (newData.country !== undefined)
                newData.country = newData.country.join(",");

            let url = this.state.baseQueryUrl;

            for (let key in newData) {
                if ((key === "country" && newData.country === "")
                    || key === "main") continue;

                if (!url.endsWith("?")) url += "&";

                url += key + "=" + newData[key];
            }
            this.setState({
                queryUrl: url,
            });
        }
    };

    /*
     ** render
     **
     */
    render() {
        const { classes } = this.props;
        const handleTableChange = (event, newValue) => {
            let data = {
                searchCriteria: this.state.searchCriteria,
                baseQueryUrl: this.state.baseQueryUrl,
            };
            data = TikTokRequests(newValue);
            this.setState({
                tableIndex: newValue,
                searchCriteria: data.searchCriteria,
                state_and_city: data.state_and_city,
                baseQueryUrl: data.baseQueryUrl,
                searchMainField: data.mainField,
                queryUrl: "",
                searchData: {},
            });

        };


        const pickTable = () => {
            switch (this.state.tableIndex) {
                case "configuration":
                    return <TikTokConfigurations {...this.props} classes={classes} />;
                case "reports":
                    return <TikTokReports />;
                case "awsrequests":
                    return <AWSRequests />;
                case "apiresults":
                    return <APIResults />;
                default:
                    return <React.Fragment />;
            }
        }

        return (
            <React.Fragment>
                <Token />
                <CssBaseline />
                <Box className={classes.appBarSpacer} />

                <Tabs
                    value={this.state.tableIndex}
                    onChange={handleTableChange}
                >
                    <Tab label="Configuration" value="configuration" />
                    <Tab label="Reports" value="reports" />
                    <Tab label="AWS Requests" value="awsrequests" />
                    <Tab label="API Tester" value="apiresults" />
                </Tabs>

                <Fade in={this.state.tableIndex !== false}>
                    <div>
                        <Box className={classes.appBarSpacer} />
                        {pickTable()}
                    </div>
                </Fade>
            </React.Fragment >
        );
    }
}

TikTok.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TikTok);
