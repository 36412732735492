import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ImageBox = (props) => {
  const [open, setOpen] = React.useState(props.open);
  const handleClose = (event) => {
    setOpen(false);
    props.onClose();
  };

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  var url = "";
  if (typeof props.movie_id === "undefined") return <span></span>;

  if (props.posterUrl != null && props.posterUrl !== "") {
    url = "https://www.movienewsletters.net/photos/" + props.posterUrl;
  } else {
    var movieid = props.movie_id.toString();
    movieid = movieid.padStart(6, "0");
    url = "https://www.movienewsletters.net/photos/" + movieid + "H1.jpg";
  }

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <img height="100%" width="100%" src={url} alt={props.movie_id} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageBox;
