import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Login from "./Login";
import Messages from "./Messages";
import { configState } from "../Config";

const styles = (theme) => ({
  hiddenstyle: {
    display: "none",
  },
  visiblestyle: {
    display: "flex",
  },
  barStyle: {
    zIndex: "1300",
    textAlign: "left",
  },
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  mainTitle: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.primary.main,
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

class TopAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.isOpen, // If sidebar is open
      auth: true,
      title: props.title,
      messageOpen: false,
      onClick: props.onClick, // Click on sidebar toggle
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ open: this.props.isOpen });
    }

    if (prevProps.title !== this.props.title) {
      this.setState({ title: this.props.title });
    }
  }

  handleChange = (event, checked) => {
    this.setState({ auth: checked });
  };

  handleDrawerOpen = (event) => {
    this.setState({ open: true });
    if (this.props.onClick != null) {
      this.props.onClick(event.target.value); // callback
    }
  };

  handleBadgeClick = (event) => {
    var oldState = this.state.messageOpen;
    this.setState({ messageOpen: !oldState });
  };

  appcTitle() {
    if (configState.siteState === "LIVE") {
      return "Scoreboard";
    } else {
      return "Scoreboard (" + configState.siteState + ")";
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <AppBar position="absolute" style={styles.barStyle}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
            >
              <MenuIcon
                className={
                  this.state.open ? classes.hiddenstyle : classes.visiblestyle
                }
              />
              <CloseIcon
                className={
                  !this.state.open ? classes.hiddenstyle : classes.visiblestyle
                }
              />
            </IconButton>

            <Typography variant="h6" color="inherit" className={classes.flex}>
              {this.state.title}
            </Typography>

            <Typography
              variant="h6"
              color="inherit"
              className={classes.mainTitle}
              noWrap
            >
              {this.appcTitle()}
            </Typography>
            <img
              alt="Webedia"
              height="50"
              src="/boxoffice_poweredby_color.png"
            ></img>

            <IconButton color="inherit" onClick={this.handleBadgeClick}>
              <Badge badgeContent={0} color="secondary" overlap="rectangular">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <Login />
            <Messages
              open={this.state.messageOpen}
              onClose={this.handleBadgeClick}
            />
          </Toolbar>
        </AppBar>
        <Toolbar></Toolbar>
      </div>
    );
  }
}

TopAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TopAppBar);
