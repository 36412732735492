import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Token from "../components/Token";
import { ForeignTable } from "../components/ForeignTable";
import { styles } from "../components/CsStyles";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { CountryCodes } from "../Maven.js";
import Autocomplete from "@material-ui/lab/Autocomplete";

class Foreign extends React.Component {
  constructor(props) {
    super(props);

    let d = new Date();
    this.state = {
      house_id: "", // User entered field
      house_name: "", // User entered field
      title: "", // User entered field
      startdate: d, // User data
      enddate: new Date(d.getFullYear() + 1, d.getMonth(), d.getDate()), // User data
      city: "", // User data
      state: "", // User data
      country: [], // User data
      chain_id: "", // User entered field
      chain_name: "", // User entered field

      search_house_id: "", // Search data
      search_house_name: "", // Search data
      search_title: "", // Search data
      search_startdate: "", // Search data
      search_enddate: "", // Search data
      search_city: "", // Search data
      search_state: "", // Search data
      search_country: "", // Search data
      search_chain_id: "", // Search data
      search_chain_name: "", // Search data

      open: false,
    };

    // Title
    if (this.props.setTitle != null) this.props.setTitle("International");

    this.fetchData = this.fetchData.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
  }

  /*
   **
   ** clearSearch
   **
   */
  clearSearch() {
    let d = new Date();
    this.setState({
      search_house_id: "",
      search_house_name: "",
      search_title: "",
      search_startdate: "",
      search_enddate: "",
      search_city: "",
      search_state: "",
      search_country: "",
      search_chain_id: "",
      search_chain_name: "",
      house_id: "",
      house_name: "",
      chain_id: "",
      chain_name: "",
      title: "",
      startdate: d,
      enddate: new Date(d.getFullYear() + 1, d.getMonth(), d.getDate()),
      city: "",
      state: "",
      country: [],
    });
  }

  /*
   ** handleChage - handle a key entered in a text form field
   */
  handleChange = (name) => (event, data) => {
    if (name === "country") {
      this.setState({
        [name]: data,
      });
    } else
      this.setState({
        [name]: event.target.value,
      });
    console.log("Schedule: set " + name + " to " + event.target.value);
  };

  handleDateChange = (name) => (date) => {
    this.setState({ [name]: date });
  };

  /*
   ** enterPressed - enter or return key pressed
   */
  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      // 13 is the enter keycode
      this.fetchData();
    }
  }

  /*
   **
   ** fetchData
   **
   */
  fetchData() {
    this.setState({
      search_country: this.state.country.join(","),
      search_startdate: format(this.state.startdate, "yyyy-MM-dd"),
      search_enddate: format(this.state.enddate, "yyyy-MM-dd"),
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Token />
        <CssBaseline />
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Typography variant="h4">Search</Typography>

            {/* Form begins here */}
            <form className={classes.container} noValidate autoComplete="off">
              <Autocomplete
                multiple
                className={classes.countrySelectAutoComplete}
                id="country"
                options={Object.keys(CountryCodes)}
                filterSelectedOptions
                onChange={this.handleChange("country")}
                onKeyPress={this.enterPressed.bind(this)}
                value={this.state.country}
                renderInput={(params) => (
                  <TextField
                    className={classes.searchTextField}
                    {...params}
                    variant="outlined"
                    margin="normal"
                    label="Country"
                    placeholder="Pick Country Name"
                  />
                )}
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={this.state.startdate}
                  onChange={this.handleDateChange("startdate")}
                  format="yyyy-MM-dd"
                  maxDate={this.state.enddate}
                  margin="normal"
                  className={classes.datePicker}
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={this.state.enddate}
                  onChange={this.handleDateChange("enddate")}
                  format="yyyy-MM-dd"
                  minDate={this.state.startdate}
                  margin="normal"
                  className={classes.datePicker}
                />
              </MuiPickersUtilsProvider>

              <Button
                onClick={this.fetchData}
                className={(classes.button, classes.datePicker)}
              >
                Search
              </Button>
              <Button
                onClick={this.clearSearch}
                className={(classes.button, classes.datePicker)}
              >
                Clear
              </Button>
            </form>

            <Typography variant="h4" className={classes.sectionTitle}>
              International
            </Typography>

            <div className={classes.tableContainer}>
              <ForeignTable
                {...this.props}
                country={this.state.search_country}
                startdate={this.state.search_startdate}
                enddate={this.state.search_enddate}
              />
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Foreign.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Foreign);
