import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { configState } from "../Config";
import { createSortHandler } from "../components/TableSortHelperMethods";
import IconButton from "@material-ui/core/IconButton";
import MiniMovieDetails from "../components/MiniMovieDetails";

/* Style */
const styles = {
  tableCollumnLabel: {
    whiteSpace: "nowrap",
  },
  tableCollumnCell: {
    cursor: "pointer",
  },
};

/*
 **
 ** RemapTable
 **
 */
export default class RemapTable extends React.Component {
  state = {
    search: this.props.search, // search parameter, text
    house_id: this.props.house_id, // search parameter, house_id
    chain_id: this.props.chain_id, // search parameter, chain_id
    country: this.props.country, // search parameter, country
    end_date: this.props.end_date, // search parameter, end date
    start_date: this.props.start_date, // search parameter, start date
    onSelect: this.props.onSelect, // callback for selection, return code or house_id
    onAdd: this.props.onAdd, // callback to add a remap
    isLoading: true,
    remap_id: 0, // remap_id if an edit is in process
    orderBy: "remap_id",
    order: "asc",
    page: 0,
    rowsPerPage: 50,
    dataSource: [],
    selectedMovie: 0, // selected movie for detail section
    selectedPoster: "",
    detailOpen: false,
  };

  /*
   ** componentDidMount
   */
  async componentDidMount() {
    // Run the initial query
    this.executeQuery();
  }

  handleClickOpen(movieId, country, poster) {
    this.setState({
      selectedMovie: movieId,
      selectedCountry: country,
      selectedPoster: poster,
      detailOpen: true,
    });
  }
  // Return from modal
  handleCloseModal = () => {
    this.setState({
      detailOpen: false,
    });
  };
  /*
   ** Paginators
   */
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value) });
  };

  handleAdd = (row) => {
    if (this.state.onAdd != null) this.state.onAdd(row);
  };
  /*
   ** executeQuery - get data from API
   */
  executeQuery = async () => {
    var url = configState.apiBase + "remaps?function=search";
    if (this.props.search !== "") url += "&wildcard=" + this.props.search;
    if (this.props.house_id !== "" && this.props.house_id > 0)
      url += "&house_id=" + this.props.house_id;
    if (this.props.chain_id !== "" && this.props.chain_id > 0)
      url += "&chain_id=" + this.props.chain_id;
    if (this.props.country !== "") url += "&country=" + this.props.country;
    if (this.props.start_date !== "")
      url += "&showdate=" + this.props.start_date;
    if (this.props.end_date !== "") url += "&showends=" + this.props.end_date;
    console.log("RemapTable: Loading: " + url);

    try {
      const response = await fetch(url);
      const responseJson = await response.json();


      let page = 0;
      if (this.props.lastPage) {
        page = Math.floor(this.state.dataSource.length / this.state.rowsPerPage);
      }


      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows,
          page: page
        },
        function () {
          this.props.tableRefreshed(false);
        }
      );
    } catch (error) {
      console.error("RemapTable: " + error);
    }
  };

  /*
   ** executeDelete
   */
  async executeDelete(remapId) {
    var url = configState.apiBase + "remaps?function=delete";
    url += "&remap_id=" + remapId;
    console.log("RemapTable: Deleting: " + url);
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows,
        },
        function () {
          // do something with new state
        }
      );
    } catch (error) {
      console.error("RemapTable: " + error);
    }
  }

  /*
   ** handleRowSelected
   */
  handleRowSelected = (event, remapId) => {
    if (remapId !== this.state.remap_id) {
      this.setState({ remap_id: remapId });

      // Call the callback
      if (this.state.onSelect != null) {
        this.state.onSelect(remapId);
      }
    } else {
      this.setState({ remap_id: 0 });

      if (this.state.onSelect != null) {
        this.state.onSelect(0);
      }
    }
  };

  /*
   ** handleDelete
   */
  handleDelete = (event, remapId) => {
    console.log("RemapTable: Delete: " + remapId);
    this.executeDelete(remapId);
  };

  // Return from modal
  handleCloseModal = () => {
    this.setState({
      detailOpen: false,
    });
  };
  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps) {
    // If the remap_id went to zero but was NOT zero before, we'll need to requery
    if (
      this.props.refresh ||
      (!(prevProps.remap_id !== 0 && this.props.remap_id !== 0) &&
        (this.props.search !== prevProps.search ||
          this.props.house_id !== prevProps.house_id ||
          this.props.chain_id !== prevProps.chain_id ||
          this.props.country !== prevProps.country ||
          this.props.start_date !== prevProps.start_date ||
          this.props.end_date !== prevProps.end_date))
    ) {
      // Requery
      this.executeQuery();
    }
  }

  isSelected = (id) => {
    if (id === this.state.remap_id) {
      return true;
    } else {
      return false;
    }
  };

  /*
   ** render
   */
  render() {
    // Still loading, show animation
    if (this.state.isLoading) {
      return <CircularProgress />;
    } else if (this.state.dataSource.length === 0) {
      return <div style={{ flex: 1, paddingTop: 20 }}></div>;
    } else {
      /* Remap table */
      return (
        <div>
          <div>
            <MiniMovieDetails
              open={this.state.detailOpen}
              movie_id={this.state.selectedMovie}
              country={this.state.selectedCountry}
              onClose={this.handleCloseModal}
              poster={this.state.selectedPoster}
            />
          </div>
          <Table style={{ flex: 1 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "remap_id"}
                    direction={this.state.order}
                    onClick={createSortHandler("remap_id", this)}
                    style={styles.tableCollumnLabel}
                  >
                    Remap Id
                  </TableSortLabel>
                </TableCell>

                <TableCell align="right">
                  <TableSortLabel
                    active={this.state.orderBy === "oldmovie"}
                    direction={this.state.order}
                    onClick={createSortHandler("oldmovie", this)}
                    style={styles.tableCollumnLabel}
                  >
                    Old Movie ID
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "oldname"}
                    direction={this.state.order}
                    onClick={createSortHandler("oldname", this)}
                    style={styles.tableCollumnLabel}
                  >
                    Old Movie Title
                  </TableSortLabel>
                </TableCell>

                <TableCell align="right">
                  <TableSortLabel
                    active={this.state.orderBy === "newmovie"}
                    direction={this.state.order}
                    onClick={createSortHandler("newmovie", this)}
                    style={styles.tableCollumnLabel}
                  >
                    New Movie ID
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "newname"}
                    direction={this.state.order}
                    onClick={createSortHandler("newname", this)}
                    style={styles.tableCollumnLabel}
                  >
                    New Movie Title
                  </TableSortLabel>
                </TableCell>

                <TableCell align="right" style={styles.tableCollumnLabel}>
                  House Id
                </TableCell>
                <TableCell style={styles.tableCollumnLabel}>
                  House Name
                </TableCell>
                <TableCell align="right" style={styles.tableCollumnLabel}>
                  Chain Id
                </TableCell>
                <TableCell style={styles.tableCollumnLabel}>
                  Chain Name
                </TableCell>
                <TableCell style={{ ...styles.tableCollumnLabel, padding: 0 }}>
                  <TableSortLabel
                    active={this.state.orderBy === "countryname"}
                    direction={this.state.order}
                    onClick={createSortHandler("countryname", this)}
                    style={{ ...styles.tableCollumnLabel, padding: 0 }}
                  >
                    Country
                  </TableSortLabel>
                </TableCell>

                <TableCell style={styles.tableCollumnLabel}>
                  Start Date
                </TableCell>
                <TableCell style={styles.tableCollumnLabel}>End Date</TableCell>
                <TableCell style={styles.tableCollumnLabel}>Notes</TableCell>
                <TableCell style={styles.tableCollumnLabel}>
                  Duplicate
                </TableCell>
                <TableCell style={styles.tableCollumnLabel}>Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.dataSource
                .slice(
                  this.state.page * this.state.rowsPerPage,
                  this.state.page * this.state.rowsPerPage +
                  this.state.rowsPerPage
                )
                .map((row) => {
                  const isSelected = this.isSelected(row.remap_id);
                  return (
                    <TableRow
                      key={row.remap_id}
                      selected={isSelected}
                      style={styles.tableCollumnCell}
                    >
                      <TableCell
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.remap_id}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          this.handleClickOpen(row.oldmovie, row.country);
                        }}
                        align="right"
                      >
                        {row.oldmovie}
                      </TableCell>
                      <TableCell
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.oldname}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          this.handleClickOpen(row.newmovie, row.country);
                        }}
                        align="right"
                      >
                        {row.newmovie}
                      </TableCell>
                      <TableCell
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.newname}
                      </TableCell>
                      <TableCell
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                        align="right"
                      >
                        {row.houseid}
                      </TableCell>
                      <TableCell
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.housename}
                      </TableCell>
                      <TableCell align="right">{row.chainid}</TableCell>
                      <TableCell
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.chainname}
                      </TableCell>
                      <TableCell
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.countryname}
                      </TableCell>
                      <TableCell
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.showdate}
                      </TableCell>
                      <TableCell
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.showends}
                      </TableCell>
                      <TableCell
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.notes}
                      </TableCell>

                      <TableCell>
                        <IconButton onClick={() => this.handleAdd(row)}>
                          <FileCopyIcon />
                        </IconButton>
                      </TableCell>

                      <TableCell>
                        <IconButton
                          onClick={(event) =>
                            window.confirm("Are you sure?") &&
                            this.handleDelete(event, row.remap_id)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[50, 100, 200]}
                  colSpan={3}
                  count={this.state.dataSource.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      );
    } // not isLoading
  } // render
} // RemapTable.js
