import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { styles } from "../components/CsStyles";
import Token from "../components/Token";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    // Title
    if (this.props.setTitle != null) this.props.setTitle("Home");
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Token />
        <CssBaseline />
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Typography variant="h4" gutterBottom>
              Employees
            </Typography>
            <div style={{ height: "80%" }}>
              <iframe
                className="airtable-embed"
                title="Employees"
                src="https://airtable.com/embed/shrxB24H1m2Li85PR?backgroundColor=purple"
                frameBorder="0"
                width="100%"
                height="100%"
              />
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
