import React, { useEffect } from "react";
import Token from "./Token";

const StillsManagement = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Stills management");
    const script = document.createElement("script");

    script.src =
      "https://bocmsimporter-widget.cinema-source.com/assets/index.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div style={{ width: "100%", padding: "1rem" }}>
      <Token />
      <link
        rel="stylesheet"
        crossorigin
        href="https://bocmsimporter-widget.cinema-source.com/assets/index.css"
      ></link>
      <div id="bocmsimporter-widget"></div>
    </div>
  );
};

export default StillsManagement;
