import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { styles } from "../components/CsStyles";
import Token from "../components/Token";

class FastLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    // Title
    if (this.props.setTitle != null) this.props.setTitle("FastLoader");
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Token />
        <CssBaseline />
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <div style={{ height: "90%" }}>
              <iframe
                className="airtable-embed"
                title="FastLoader"
                src="https://amcautomonitor.cinema-source.com/"
                frameBorder="0"
                width="100%"
                height="100%"
              />
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

FastLoader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FastLoader);
