import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { configState } from "../../Config";
import Wrapper from "./Wrapper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import querystring from "querystring";
import DeleteIcon from "@material-ui/icons/Delete";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { CountryCodes } from "../../Constants";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

const Movies = ({ setTitle, match }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [userMovies, setUserMovies] = useState([]);
  const [error, setError] = useState();
  const [updatingIds, setUpdatingIds] = useState([]);
  const [addingMovie, setAddingMovie] = useState(false);
  const [newIdMovie, setNewIdMovie] = useState(undefined);
  const [deleteId, setDeleteId] = useState(undefined);
  
  useEffect(() => {
    fetch(`${configState.apiBase}/api_user?${querystring.encode({ 
      function :"search", 
      id: match.params.idUser 
    })}`)
      .then((results) => results.json())
      .then((results) => {
        if (results.length) {
          setName(results[0].name);
        }
      })

    setLoading(true);
    fetch(`${configState.apiBase}api_user_movie?${querystring.encode({
      function: "search",
      idUser: match.params.idUser,
      countryCode: match.params.countryCode ? match.params.countryCode : "0",
    })}`)
      .then((results) => results.json())
      .then((results) => {
        setUserMovies(results);
      }).catch(() => {
        setUserMovies([]);
        setError("Error loading movies");
      }).finally(() => { 
        setLoading(false);
      })
  }, [match]);

  useEffect(() => {
    setTitle("API User Movies");
  }, [setTitle]);
  
  const addMovie = () => {
    setAddingMovie(true);
    setError(undefined);
    fetch(`${configState.apiBase}/api_user_movie?${querystring.encode({
      function: "insert",
      idUser: match.params.idUser,
      countryCode: match.params.countryCode ? match.params.countryCode : "0",
      idMovie: newIdMovie
    })}`)
      .then((results) => results.json())
      .then((results) => {
        if (results.error) {
          setError(results.error);
        } else {
          setUserMovies([
            ...userMovies,
            results,
          ]);
          setNewIdMovie(undefined);
        }
      }).catch(() => {
        setError("Error adding user movie");
      }).finally(() => { 
        setAddingMovie(false);
      })
  }

  const updateUserMovie = (nextMovie) => {
    setUpdatingIds([...updatingIds, nextMovie.id]);
    setError(undefined);
    fetch(`${configState.apiBase}/api_user_movie?${querystring.encode({
      function: "update",
      ...nextMovie,
    })}`)
      .then((results) => results.json())
      .then((results) => {
        if (results.error) {
          setError(results.error);
        } else {
          const nextData = userMovies;
          const index = nextData.findIndex((userMovie) => userMovie.id === nextMovie.id);
          nextData[index] = results;
          setUserMovies(nextData);
        }
      }).catch(() => {
        setError("Error updating user movie");
      }).finally(() => { 
        setUpdatingIds(updatingIds.filter((id) => id !== nextMovie.id));
      })
  }

  const deleteUserMovie = (id) => {
    setUpdatingIds([...updatingIds, id]);
    setError(undefined);
    fetch(`${configState.apiBase}/api_user_movie?${querystring.encode({
      function: "delete",
      id,
    })}`)
      .then((results) => results.json())
      .then((results) => {
        if (results.error) {
          setError(results.error);
        } else {
          setUserMovies(userMovies.filter((userMovie) => userMovie.id !== id));
        }
      }).catch(() => {
        setError("Error deleting user movie");
      }).finally(() => { 
        setUpdatingIds(updatingIds.filter((upId) => upId !== id));
      })
  }
  
  const dateFns = new DateFnsUtils();
  const deleteMovie = deleteId ? userMovies.find((userMovie) => userMovie.id === deleteId) : {};

  return (

    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Wrapper 
        title={`${name} movie restrictions (${match.params.countryCode ? `${CountryCodes[match.params.countryCode]}` : "All countries"})`} 
        loading={loading}
      >
        {error && <h3>{error}</h3>}
        <div style={{ display: "grid", gap: "1em" }}>
          {userMovies.map((userMovie) => (
            <div 
              key={userMovie.id} 
              style={{ 
                alignItems: "center",
                borderBottom: "1px solid gray",
                display: "grid", 
                gap: "0.5em",
                gridTemplateColumns: "1fr auto auto auto auto",
                opacity: updatingIds.includes(userMovie.id) ? "0.5" : "1",
                padding: "1em 0",
              }}
            >
              <div><span style={{ fontSize: "1.25em" }}>{userMovie.name}</span> ({userMovie.idMovie})</div>
              <DatePicker
                label="From"
                value={userMovie.startsAt}
                onChange={(from) => {
                  updateUserMovie({
                    ...userMovie,
                    startsAt: dateFns.startOfDay(from).toISOString(),
                  });
                }}
                format="yyyy-MM-dd"
                disabled={updatingIds.includes(userMovie.id)}
              />
              <DatePicker
                label="To"
                value={userMovie.endsAt}
                onChange={(to) => {
                  updateUserMovie({
                    ...userMovie,
                    endsAt: dateFns.startOfDay(to).toISOString(),
                  });
                }}
                format="yyyy-MM-dd"
                disabled={updatingIds.includes(userMovie.id)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={"includeChildMovies"}
                    checked={userMovie.includeChildMovies}
                    onChange={(_, checked) => {
                      updateUserMovie({
                        ...userMovie,
                        includeChildMovies: checked ? "1" : "0",
                      });
                    }}
                    disabled={updatingIds.includes(userMovie.id)}
                  />
                }
                label="Include child movies"
                labelPlacement="left"
                disabled={updatingIds.includes(userMovie.id)}
              />
              <div style={{ cursor: "pointer" }}>
                <DeleteIcon 
                  onClick={() => {
                    setDeleteId(userMovie.id)
                  }}
                />
              </div>
            </div>
          ))}
        </div>

        <div style={{alignItems: "center", display: "flex", gap: "1em"}}>
          <Button 
            onClick={() => {
              if (newIdMovie !== undefined) {
                addMovie();
              } else {
                setNewIdMovie("");
              }
            }} 
            color="primary"
            disabled={addingMovie}
          >
            {newIdMovie ? "Save" : "Add new movie"}
          </Button>
        
          {newIdMovie !== undefined && (
            <TextField
              label="Movie ID"
              name="idMovie"
              type="text"
              value={newIdMovie}
              onChange={(e) => {
                setNewIdMovie(e.target.value);
              }}
              margin="normal"
            />
          )}
        </div>
        <Dialog 
          open={!!deleteId}
          onClose={() => setDeleteId(undefined)}
        >
          <DialogTitle>Delete {deleteMovie.name}?</DialogTitle>
          <DialogActions>
            <Button onClick={() => setDeleteId(undefined)}>Cancel</Button>
            <Button
              onClick={() => {
                deleteUserMovie(deleteId);
                setDeleteId(undefined);
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    </MuiPickersUtilsProvider>
  )
};

export default Movies;