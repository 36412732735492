import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Token from "../components/Token";
import BotAppBar from "../components/BotAppBar";
import { styles } from "../components/CsStyles";
import MovieWidgetTable from "../components/MovieWidgetTable";
import RemapDetail from "../components/RemapDetail";
import PageTitle from "../components/PageTitle";
class MovieWidgets extends React.Component {
  /*
   *
   * constructor
   *
   */
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      search_house_id: 0,
      search_name: "",
      remap_id: 0 /* selected remap for details */,
    };

    // Title
    PageTitle("MovieWidgets", props);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
  }

  /*
   *
   * handleChange
   *
   */
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  /*
   *
   * handleSelection
   *
   */
  handleSelection(remapId) {
    console.log("Got new remap:" + remapId);
    this.setState({
      remap_id: remapId,
    });
  }

  /*
   *
   * handleAdd
   *
   */
  handleAdd() {
    console.log("Add new");
    this.setState({
      remap_id: -1,
    });
  }

  /*
   ** handleSave - when detail saves, then set the selected to nothing. Not sure why.
   */
  handleSave = () => {
    this.setState({ remap_id: 0 });
  };

  /*
   ** render
   **
   */
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Token />
        <CssBaseline />
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />

            {/* Form begins here */}
            <form className={classes.csMenu} noValidate autoComplete="off">
              <Grid container spacing={24}>
                <TextField
                  id="name"
                  label="Name"
                  className={classes.textField}
                  value={this.state.name}
                  onChange={this.handleChange("search_name")}
                  margin="normal"
                />
                <TextField
                  id="house_id"
                  label="House ID"
                  className={classes.textField}
                  value={this.state.house_id}
                  onChange={this.handleChange("search_house_id")}
                  margin="normal"
                />
              </Grid>
            </form>

            <div>
              <MovieWidgetTable
                remap_id={this.state.remap_id}
                house_id={this.state.search_house_id}
                search={this.state.search_name}
                onSelect={this.handleSelection}
              />
            </div>

            <div>
              <div className="FORM HERE">
                <RemapDetail
                  remap_id={this.state.remap_id}
                  onClose={this.handleCloseModal}
                  handleSave={this.handleSave}
                />
              </div>
            </div>
          </main>

          <BotAppBar onAdd={this.handleAdd} />
        </div>
      </React.Fragment>
    );
  }
}

MovieWidgets.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MovieWidgets);
