import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Token from "../Token";

const Wrapper = ({ children, loading }) => (
  <div
    style={{
      margin: "0 auto",
      maxWidth: "40rem",
      padding: "0",
      width: "100%",
    }}
  >
    <Token />
    {loading ? <CircularProgress /> : children}
  </div>
);

export default Wrapper;
