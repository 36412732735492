import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Token from "../components/Token";
import { styles } from "../components/CsStyles";
import { OpeningTable } from "../components/OpeningTable";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

/*
 ** Opening - a list of vendors based on search criteria passed-in
 */
class Opening extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      house_id: "",
      name: "",
      chain_id: "",
      chain_name: "",
      city: "",
      state: "",
      country: "",
      startdate: new Date(),
      search_house_id: "",
      search_name: "",
      search_chain_id: "",
      search_chain_name: "",
      search_city: "",
      search_state: "",
      search_country: "",
      search_startdate: "",
      open: false,
    };

    // Title
    if (this.props.setTitle != null) {
      this.props.setTitle("Opening");
    }

    this.fetchData = this.fetchData.bind(this);
    this.clearData = this.clearData.bind(this);
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  handleDateChange = (date) => {
    this.setState({ startdate: date });
  };

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      // 13 is the enter keycode
      this.fetchData();
    }
  }

  /*
   **
   ** fetchData
   **
   */
  fetchData() {
    console.log("Search now");
    this.setState({
      search_house_id: this.state.house_id,
      search_name: this.state.name,
      search_chain_name: this.state.chain_name,
      search_chain_id: this.state.chain_id,
      search_city: this.state.city,
      search_state: this.state.state,
      search_country: this.state.country,
      search_startdate: format(this.state.startdate, "yyyy-MM-dd"),
    });
  }

  /*
   **
   ** clearData
   **
   */
  clearData() {
    this.setState({
      search_house_id: "",
      search_name: "",
      search_chain_name: "",
      search_chain_id: "",
      search_city: "",
      search_state: "",
      search_country: "",
      search_startdate: "",
      house_id: "",
      name: "",
      chain_id: "",
      chain_name: "",
      city: "",
      state: "",
      country: "",
      startdate: new Date(),
    });
  }

  /*
   ** render
   **
   */
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Token />
        <CssBaseline />
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />

            {/* Form begins here */}
            <form className={classes.csMenu} noValidate autoComplete="off">
              <Grid container>
                {/*
                <TextField
                  id="name"
                  label="Name"
                  autoFocus
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.name}
                  onChange={this.handleChange("name")}
                  margin="normal"
                />
                <TextField
                  id="house_id"
                  label="House ID"
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.house_id}
                  onChange={this.handleChange("house_id")}
                  margin="normal"
                />
                <TextField
                  id="chain_name"
                  label="Chain Name"
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.chain_name}
                  onChange={this.handleChange("chain_name")}
                  margin="normal"
                />
                <TextField
                  id="chain_id"
                  label="Chain ID"
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.chain_id}
                  onChange={this.handleChange("chain_id")}
                  margin="normal"
                />
                <TextField
                  id="city"
                  label="City"
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.city}
                  onChange={this.handleChange("city")}
                  margin="normal"
                />
                <TextField
                  id="state"
                  label="State"
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.state}
                  onChange={this.handleChange("state")}
                  margin="normal"
                />
                */}
                <TextField
                  id="country"
                  label="Country"
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.country}
                  onChange={this.handleChange("country")}
                  margin="normal"
                  required={true}
                />

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    value={this.state.startdate}
                    onChange={this.handleDateChange}
                    format="yyyy-MM-dd"
                    margin="normal"
                    className={classes.datePicker}
                  />
                </MuiPickersUtilsProvider>

                <Button
                  ref="autocomplete"
                  onClick={this.fetchData}
                  className={classes.button}
                >
                  Search
                </Button>
                <Button onClick={this.clearData} className={classes.button}>
                  Clear
                </Button>
              </Grid>
            </form>

            <div className={classes.tableContainer}>
              <OpeningTable
                country={this.state.search_country}
                chain_id={this.state.search_chain_id}
                showdate={this.state.search_startdate}
              ></OpeningTable>
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Opening.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Opening);
