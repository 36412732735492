import React from "react";

const MetaEmbed = (props) => {
  const getMetabaseURL = (props) => {
    var jwt = require("jsonwebtoken");
    var METABASE_SITE_URL = "https://metabase.allocine.net";
    var METABASE_SECRET_KEY = props.secretkey;
    var payload = {
      resource: { [props.dashboardorquestion]: Number(props.dashboardid) },
      params: {},
      exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
    };
    console.log("Debug Payload: " + JSON.stringify(payload));
    var token = jwt.sign(payload, METABASE_SECRET_KEY);
    var iframeUrl =
      METABASE_SITE_URL + "/embed/" + props.dashboardorquestion + "/" + token;
    if (!!props.refresh) {
      iframeUrl += '#refresh=' + props.refresh;
    }
    return iframeUrl;
  };

  return (
    <iframe
      title="Metabase"
      width="100%"
      height="100%"
      src={getMetabaseURL(props)}
    ></iframe>
  );
};

export default MetaEmbed;
