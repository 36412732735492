import React from "react";
import Chip from "@material-ui/core/Chip";

export default function SearchTermChips(row, searchData, handleDelete) {
  return (
    <React.Fragment>
      {Object.keys(row.searchData).map((dataName) => (
        <React.Fragment key={dataName}>
          {(dataName !== "country" || row.searchData[dataName].length > 0)&&
          (dataName !== "main" && dataName !== "mainField" && row.searchData[dataName]) ? (
            <Chip
              label={dataName + ": " + row.searchData[dataName]}
              onDelete={row.handleDelete(dataName)}
            />
          ) : null}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
