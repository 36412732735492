import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import Token from "../components/Token";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../components/CsStyles";
import { MovieTable } from "../components/MovieTable";
import { CountryCodes } from "../Constants";
class Movies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      movie_id: "", // User entered field
      name: "", // User entered field
      startrelease: "", // User entered field
      endrelease: "",
      director: "",
      country: "",
      fsearch: "domestic",
      dbzid: "0",
      countryDisplay: "none",
      search_movie_id: "", // Search data
      search_name: "", // Search data
      search_startrelease: "",
      search_endrelease: "",
      search_director: "",
      search_country: "",
      search_dbzid: "",
      search_fsearch: "domestic",
      open: false,
    };

    // Title
    if (this.props.setTitle != null) this.props.setTitle("Movies");

    this.fetchData = this.fetchData.bind(this);
  }

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      // 13 is the enter keycode
      this.fetchData();
    }
  }
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });

    if (name === "movie_id") {
      this.setState({ name: "" });
      this.setState({ director: "" });
      this.setState({ startrelease: "" });
      this.setState({ endrelease: "" });

      var letters = /^[A-Za-z]+$/;

      if (this.state.movie_id.match(letters)) {
        alert("Please Enter Numerical Digits Only");
        this.setState({ movie_id: "" });
      }
    }
    if (name === "name") this.setState({ movie_id: "" });
    if (name === "director") this.setState({ movie_id: "" });

    if (name === "country") {
      this.setState({ country: event.target.value });
    }
    if (name === "startrelease" || name === "endrelease")
      this.setState({ movie_id: "" });
    if (name === "clear") {
      window.location = "/movies";
      this.setState({ name: "" });
      this.setState({ director: "" });
      this.setState({ startrelease: "" });
      this.setState({ endrelease: "" });
      this.setState({ country: "" });
      this.setState({ movie_id: "" });
    }

    if (name === "international") {
      if (this.state.countryDisplay === "none")
        this.setState({ countryDisplay: "" });
      else this.setState({ countryDisplay: "none" });
      if (this.state.fsearch === "domestic") {
        this.setState({ fsearch: "foreign" });
      } else this.setState({ fsearch: "domestic" });
    }
    if (name === "dbzid") {
      if (this.state.dbzid === "1") {
        this.setState({ dbzid: "0" })
      }
      else {
        this.setState({ dbzid: "1" })
      }
    }
  };

  /*
   **
   ** fetchData
   **
   */
  fetchData() {
    console.log("Search now");
    this.setState({
      search_movie_id: this.state.movie_id,
      search_name: this.state.name,
      search_startrelease: this.state.startrelease,
      search_endrelease: this.state.endrelease,
      search_director: this.state.director,
      search_country: this.state.country,
      search_fsearch: this.state.fsearch,
      search_dbzid: this.state.dbzid
    });
  }

  render() {
    const { classes } = this.props;
    var nameTitle =
      'Enter Movie Title\nFor exact movie title search the movie in "Movie Name" format';
    //var dateTitle = "Enter date in yyyy-mm-dd format";

    var countryAble = true;
    if (this.state.fsearch !== "domestic") {
      countryAble = false;
    }

    return (
      <React.Fragment>
        <Token />
        <CssBaseline />
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />

            {/* Form begins here */}
            <form className={classes.container} noValidate autoComplete="off">
              <Grid container>
                <TextField
                  id="name"
                  autoFocus
                  label="Name"
                  className={classes.textField}
                  value={this.state.name}
                  onChange={this.handleChange("name")}
                  onKeyPress={this.enterPressed.bind(this)}
                  margin="normal"
                  title={nameTitle}
                />

                <TextField
                  id="movie_id"
                  label="Movie ID"
                  className={classes.textField}
                  value={this.state.movie_id}
                  onChange={this.handleChange("movie_id")}
                  onKeyPress={this.enterPressed.bind(this)}
                  margin="normal"
                />
                <TextField
                  id="director"
                  label="Director"
                  className={classes.textField}
                  value={this.state.director}
                  onChange={this.handleChange("director")}
                  onKeyPress={this.enterPressed.bind(this)}
                  margin="normal"
                />
                <TextField
                  id="date"
                  label="Start Release"
                  className={classes.textField}
                  value={this.state.startrelease}
                  type="date"
                  onChange={this.handleChange("startrelease")}
                  onKeyPress={this.enterPressed.bind(this)}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  id="endrelease"
                  label="End Release"
                  className={classes.textField}
                  value={this.state.endrelease}
                  type="date"
                  onChange={this.handleChange("endrelease")}
                  onKeyPress={this.enterPressed.bind(this)}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid>
                <FormControl className={classes.foreignCheck}>
                  <FormHelperText>DBZID</FormHelperText>
                  <Switch
                    value={"dbzid"}
                    label="DBZID"
                    onChange={this.handleChange("dbzid")}
                    color="primary"
                  />
                </FormControl>
                <FormControl className={classes.foreignCheck}>
                  <FormHelperText>International</FormHelperText>
                  <Switch
                    value={"foreign"}
                    label="International"
                    onChange={this.handleChange("international")}
                    color="primary"
                  />
                </FormControl>
                <FormControl
                  style={{ display: this.state.countryDisplay }}
                  className={classes.foreignCheck}
                >
                  <FormHelperText>Select Country</FormHelperText>
                  <Select
                    labelId="country-label"
                    id="country"
                    label="Select Country"
                    disabled={countryAble}
                    value={this.state.country}
                    onChange={this.handleChange("country")}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {Object.keys(CountryCodes).map((option, index) => (
                      <MenuItem
                        key={option}
                        title={CountryCodes[option]}
                        value={option}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Button
                  variant="outlined"
                  onClick={this.fetchData}
                  className={classes.button}
                  style={{ padding: 10, marginLeft: 10, marginTop: 5 }}
                >
                  Search
                </Button>
                <Button
                  variant="outlined"
                  title="Clear all search fields"
                  onClick={this.handleChange("clear")}
                  className={classes.button}
                  style={{ padding: 10, marginLeft: 10, marginTop: 5 }}
                >
                  Clear
                </Button>
              </Grid>
            </form>
            <div className={classes.sectionTitle} />
            <div className={classes.tableContainer}>
              <MovieTable
                movie_id={this.state.search_movie_id}
                search={this.state.search_name}
                startrelease={this.state.search_startrelease}
                endrelease={this.state.search_endrelease}
                director={this.state.search_director}
                fsearch={this.state.search_fsearch}
                dbzid={this.state.search_dbzid}
                country={this.state.search_country}
              />
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Movies.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Movies);
