import React, { useState, useEffect, useCallback } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { debounce } from "lodash";
import Tooltip from "@material-ui/core/Tooltip";

import { fetchData } from "../utils/fetchData";

const AutocompleteMovies = ({ defaultSearchString = "", handleSetId }) => {
  const [movies, setMovies] = useState([]);
  const [id, setId] = useState();
  const [movieSearch, setMovieSearch] = useState(defaultSearchString);
  const [moviesLoading, setMoviesLoading] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(false);

  useEffect(() => {
    if (shouldSearch && movieSearch !== "") {
      debouncedSearch(movieSearch);
    }
  }, [movieSearch, shouldSearch]);

  useEffect(() => {
    handleSetId(id);
  }, [id]);

  const debouncedSearch = useCallback(
    debounce((search) => {
      setMoviesLoading(true);
      fetchData({
        endpoint: "mapcodes/searchMovies",
        body: {
          search,
        },
        handleComplete: (response) => {
          if (response.data) {
            setMovies(response.data);
          }
        },
        handleFinally: () => {
          setMoviesLoading(false);
        },
      });
    }, 500),
    [],
  );

  return (
    <Tooltip
      disableHoverListener={!movieSearch}
      placement="top"
      title={movieSearch || ""}
    >
      <Autocomplete
        options={movies}
        filterSelectedOptions
        inputValue={movieSearch}
        onInput={(e) => {
          setMovieSearch(e.target.value);
        }}
        loading={moviesLoading}
        onChange={(_, data) => {
          setId(data.movie_id);
          setMovieSearch(
            `${data.name} (${
              data.release ? new Date(data.release).getFullYear() : "N/A"
            })` || "",
          );
        }}
        onFocus={() => {
          if (!shouldSearch) {
            setShouldSearch(true);
          }
        }}
        getOptionLabel={(option) =>
          `${option.name} (${
            option.release ? new Date(option.release).getFullYear() : "N/A"
          })` || ""
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            margin="normal"
            label="Movie"
            placeholder="Pick movie"
          />
        )}
      />
    </Tooltip>
  );
};

export default AutocompleteMovies;
