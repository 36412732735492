import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Token from "../components/Token";
import { MavenSummary } from "../components/MavenSummary";
import { styles } from "../components/CsStyles";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

class Mavenstats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startdate: new Date(), // User data
      enddate: new Date(), // User data
      initials: "", // User data

      search_startdate: "", // Search data
      search_enddate: "", // Search data
      search_initials: "", // Search data

      open: false,
    };

    // Title
    if (this.props.setTitle != null) this.props.setTitle("Maven Stats");

    this.fetchData = this.fetchData.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
  }

  /*
   **
   ** clearSearch
   **
   */
  clearSearch() {
    this.setState({
      search_startdate: "",
      search_enddate: "",
      search_initials: "",
      startdate: new Date(),
      enddate: new Date(),
      initials: "",
    });
  }

  /*
   ** handleChage - handle a key entered in a text form field
   */
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDateChange = (name) => (date) => {
    console.log(date);
    this.setState({ [name]: date });
  };

  /*
   ** enterPressed - enter or return key pressed
   */
  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      // 13 is the enter keycode
      this.fetchData();
    }
  }

  /*
   **
   ** fetchData
   **
   */
  fetchData() {
    this.setState({
      search_startdate: format(this.state.startdate, "yyyy-MM-dd"),
      search_enddate: format(this.state.enddate, "yyyy-MM-dd"),
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Token />
        <CssBaseline />
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />

            <form className={classes.container} noValidate autoComplete="off">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={this.state.startdate}
                  onChange={this.handleDateChange("startdate")}
                  format="yyyy-MM-dd"
                  maxDate={this.state.enddate}
                  margin="normal"
                  className={classes.datePicker}
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={this.state.enddate}
                  onChange={this.handleDateChange("enddate")}
                  format="yyyy-MM-dd"
                  minDate={this.state.startdate}
                  margin="normal"
                  className={classes.datePicker}
                />
              </MuiPickersUtilsProvider>

              <Button
                onClick={this.fetchData}
                className={(classes.button, classes.datePicker)}
              >
                Search
              </Button>
              <Button
                onClick={this.clearSearch}
                className={(classes.button, classes.datePicker)}
              >
                Clear
              </Button>
            </form>

            <Typography variant="h4">Summary</Typography>
            <div className={classes.tableContainer}>
              <MavenSummary
                country={this.state.search_country}
                startdate={this.state.search_startdate}
                enddate={this.state.search_enddate}
              />
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Mavenstats.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Mavenstats);
