import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchDetailSection from "./SearchDetailSection";
import { configState } from "../Config";
import { styles, BoxofficeRed } from "./CsStyles";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import PersonIcon from "@material-ui/icons/Person";
import Collapse from "@material-ui/core/Collapse";

import Masonry from "react-responsive-masonry";

class MovieDetail extends React.Component {
  state = {
    open: this.props.open,
    movie_id: this.props.movie_id,
    poster: this.props.poster,
    country: this.props.country,
    dataSource: [],
    onClose: this.props.onClose,
    expanded: false,
    images: [],
    imageLoadError: true,
  };

  /*
   ** executeQuery - get data from API
   */
  async executeQuery(movie_id, country) {
    var url = configState.apiBase + "movies?function=search";
    if (movie_id !== "") url += "&movie_id=" + movie_id;
    if (country !== "" && country !== undefined) url += "&country=" + country;
    console.log("Loading: " + url);
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows,
        },
        function () {
          // do something with new state
          if (this.state.dataSource.length > 0)
            if (
              (this.state.poster === "" ||
                this.state.poster ===
                "https://www.movienewsletters.net/photos/000000H1.jpg") &&
              this.state.dataSource[0].poster !== "" &&
              this.state.dataSource[0].poster !== null
            ) {
              this.setState({ poster: this.state.dataSource[0].poster });
            }
        }
      );
    } catch (error) {
      console.error(error);
    }

    url = configState.apiBase + "photos?function=search";
    if (movie_id !== "") url += "&movie_id=" + movie_id;
    if (country !== "" && country !== undefined) {
      url += "&country=" + country;
    } else {
      url += "&country=USA";
    }

    console.log("Loading: " + url);
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      this.setState(
        {
          isLoading: false,
          imageLoadError: true,
          images: responseJson.rows == null ? [] : responseJson.rows,
        },
        function () {
          // do something with new state
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    // If nothing important changed, we're done.
    if (
      prevState.movie_id === this.props.movie_id &&
      prevState.country === this.props.country &&
      prevState.poster === this.props.poster &&
      prevState.open === this.props.open
    )
      return;

    if (this.props.movie_id > 0 && this.props.open) {
      this.setState({ open: true });
      this.executeQuery(this.props.movie_id, this.props.country);
    } else {
      this.setState({ open: false });
    }

    var newPoster = this.props.poster;
    if (newPoster === "" || newPoster == null) {
      newPoster = "https://www.movienewsletters.net/photos/000000H1.jpg";
    }
    this.setState({
      movie_id: this.props.movie_id, // house_id parameter
      country: this.props.country,
      poster: newPoster,
    });
  }
  handleExpand = (panel) => (event, isExpanded) => {
    this.setState({ expanded: !this.state.expanded });
  };
  /*
   ** render
   */
  render() {
    const imgSrc = "https://www.movienewsletters.net/photos/";
    const defaultDisplayedImagesCount = 6;

    if (this.state.dataSource.length > 0) {
      var Movie = this.state.dataSource[0];
      return (
        <div>
          <Dialog
            open={this.state.open}
            onClose={this.state.onClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="sm"
          >
            <div
              style={{
                backgroundColor: BoxofficeRed,
                padding: "1em",
                display: "inline-block",
              }}
            >
              <div style={{ width: "48%", float: "left" }}>
                <img
                  style={{ display: "inline-block", maxWidth: "100%" }}
                  src={this.state.poster}
                  alt={"Movie Poster"}
                  onError={(e) => {
                    if (this.state.imageLoadError) {
                      this.setState({
                        imageLoadError: false,
                      });
                      e.target.src = imgSrc + "000000H1.jpg";
                    }
                  }}
                />
              </div>
              <div style={{ width: "48%", float: "right" }}>
                <h1
                  style={{
                    margin: "0",
                    color: "white",
                  }}
                >
                  {Movie.name}
                </h1>
                <p
                  style={{
                    margin: "0",
                    fontSize: "1.5em",
                    color: "white",
                  }}
                >
                  Movie ID : {Movie.movie_id}
                  <br />
                  {Movie.imdb ? (
                    <React.Fragment>
                      IMDB : {Movie.imdb} <br />
                    </React.Fragment>
                  ) : null}
                  {Movie.parent_id ? (
                    <React.Fragment>
                      Parent : {Movie.parent_id} <br />
                    </React.Fragment>
                  ) : null}
                  {Movie.dbzid ? "DBZ : " + Movie.dbzid : null}
                </p>
              </div>
            </div>

            <DialogContent>
              <div>
                {SearchDetailSection(
                  Movie,
                  {
                    director: null,
                  },
                  "Director",
                  <EventSeatIcon className={styles.toolbarIcon} />
                )}
                {SearchDetailSection(
                  Movie,
                  {
                    writer: null,
                  },
                  "Writer",
                  <CreateOutlinedIcon />
                )}
                {SearchDetailSection(
                  Movie,
                  {
                    actor1: null,
                    actor2: null,
                    actor3: null,
                    actor4: null,
                    actor5: null,
                    actor6: null,
                    actor7: null,
                    actor8: null,
                    actor9: null,
                    actor10: null,
                  },
                  "Actors",
                  <PersonIcon />
                )}

                <Collapse in={this.state.images.length > 0}>
                  <h2>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      <CameraAltIcon />
                      &nbsp;Images
                    </div>
                  </h2>

                  <Masonry columnsCount={2} gutter="0.5em">
                    {this.state.images
                      .slice(0, defaultDisplayedImagesCount)
                      .map((image) => (
                        <img
                          alt=""
                          style={{ width: "100%" }}
                          src={imgSrc + image.ip_filename}
                          key={image.ip_filename}
                        />
                      ))}
                  </Masonry>

                  <Collapse
                    in={this.state.images.length > defaultDisplayedImagesCount}
                  >
                    <Accordion
                      expanded={this.state.expanded}
                      onChange={this.handleExpand("panel1")}
                    >
                      <AccordionSummary
                        style={{ height: "1em" }}
                        expandIcon={<ExpandMoreIcon />}
                        id="panel1bh-header"
                      >
                        <h2>Click for more Images</h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Masonry columnsCount={2} gutter="0.5em">
                          {this.state.images
                            .slice(defaultDisplayedImagesCount)
                            .map((image) => (
                              <img
                                alt={""}
                                style={{ width: "100%" }}
                                src={imgSrc + image.ip_filename}
                                key={image.ip_filename}
                              />
                            ))}
                        </Masonry>
                      </AccordionDetails>
                    </Accordion>
                  </Collapse>
                  <hr />
                </Collapse>

                {SearchDetailSection(
                  Movie,
                  {
                    release: null,
                    release2: null,
                    release3: null,
                  },
                  "Releases",
                  <CalendarTodayIcon />
                )}
              </div>
            </DialogContent>
          </Dialog>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
} // end compoonent

export default MovieDetail;
