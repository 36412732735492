import React from "react";
import { Link } from "react-router-dom";

const Nav = () => (
  <nav style={{ display: "flex", gap: "1rem", padding: "1rem 0" }}>
    <Link to={`/map-codes/list`}>Map codes</Link>
    <Link to={`/map-codes/files`}>Manage codes files</Link>
    <Link to={`/map-codes/upload`}>Add codes from file</Link>
  </nav>
);

export default Nav;
