import React, { Fragment } from "react";
import Token from "./Token";

const FanWeb = () => (
  <Fragment>
    <Token />
    <iframe src="https://fanweb.cinema-source.com/" style={{ border: 0, padding: "1rem", width: "100vw", height: "calc(100vh - 5rem)" }}/>
  </Fragment>
);

export default FanWeb;
