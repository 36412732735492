import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import { configState } from "../Config";
import { MavenDetail } from "../components/MavenDetail";
var component = this;

/*
 **
 ** MavenSummary
 **
 */
export class MavenSummary extends React.Component {
  /*
   ** constructor
   **/
  constructor(props) {
    super(props);
    this.state = {
      startdate: this.props.startdate,
      enddate: this.props.enddate,
      detailOpen: false,
      initials: "",
      isLoading: true,
      dataSource: [],
    };

    this.redoQuery = this.redoQuery.bind(this);
    this.redoQuery(this.props.startdate, this.props.enddate);
    component = this;
  }

  /*
   ** redoQuery - query from AJAX
   */
  async redoQuery(startDate, endDate) {
    var url = "";
    url = configState.apiBase + "mavenstats?function=summary";
    if (startDate !== "" && startDate !== "undefined") {
      url += "&startdate=" + startDate;
    }
    if (endDate !== "" && endDate !== "undefined") {
      url += "&enddate=" + endDate;
    }
    console.log("Query URL: " + url);
    return fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState(
          {
            isLoading: false,
            dataSource: responseJson.rows == null ? [] : responseJson.rows,
          },
          function () {
            // do something with new state
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Button click
  handleClickOpen(initials) {
    if (initials !== this.state.initials) {
      console.log("MavenSummary: " + initials);
      this.setState({ initials: initials, detailOpen: true });
    }
  }

  // Return from modal
  handleCloseModal = () => {
    this.setState({ initials: "", detailOpen: false });
  };

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    // if nothing important changed, we're done.
    if (
      prevProps.startdate === this.props.startdate &&
      prevProps.enddate === this.props.enddate
    ) {
      return;
    }

    this.setState({
      startdate: this.props.startdate, // queue_id parameter
      enddate: this.props.enddate, // callback for selection
      isLoading: true,
      dataSource: [],
    });

    // New props, have to rerun query
    this.redoQuery(this.props.startdate, this.props.enddate);
  }

  rowColor = (queued) => {
    if (queued === 0) return "#000000";
    else return "#ff2020";
  };
  rowWidth = (queued) => {
    if (queued === 0) return "1px";
    else return queued.toString() + "px";
  };

  /*
   ** render
   */
  render() {
    // Still loading, show animation
    if (this.state.isLoading) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <span className="glyphicon-left glyphicon glyphicon-refresh spinning">
            <CircularProgress />
          </span>
        </div>
      );
    } else if (
      this.state.dataSource.length === 0 &&
      (this.state.startdate !== "" || this.state.enddate !== "")
    ) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <h4>No Results</h4>
        </div>
      );
    } else {
      /* Graph table */
      return (
        <div>
          <MavenDetail
            initials={this.state.initials}
            startdate={this.state.startdate}
            enddate={this.state.enddate}
            open={this.state.detailOpen}
            onClose={this.handleCloseModal}
          />

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Initials</TableCell>
                <TableCell>Details</TableCell>
                <TableCell>Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dataSource.map((row, index) => {
                return (
                  <TableRow key={row.initials} style={{ cursor: "pointer" }}>
                    <TableCell
                      onClick={() => {
                        component.handleClickOpen(row.initials);
                      }}
                    >
                      {row.initials}
                    </TableCell>

                    <TableCell style={{ cursor: "pointer" }}>
                      <ZoomInIcon
                        onClick={(event) => {
                          component.handleClickOpen(row.initials);
                        }}
                      />
                    </TableCell>

                    <TableCell>
                      {row.summarycount}{" "}
                      <Typography
                        component="div"
                        style={{
                          backgroundColor: this.rowColor(row.summarycount),
                          width: this.rowWidth(row.summarycount),
                          height: "20px",
                        }}
                      />{" "}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    } // not isLoading
  } // render
} // MavenSummary.js
