import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { configState } from "../Config";
import { createSortHandler } from "../components/TableSortHelperMethods";

/*
 **
 ** PerfsTable
 **
 */
export default class PerfsTable extends React.Component {
  /*
   ** constructor
   **/
  constructor(props) {
    super(props);
    this.state = {
      house_id: this.props.house_id, // search parameter, house_id
      movie_id: this.props.movie_id, // movie_id
      auditorium: this.props.auditorium,
      date: this.props.date, // movie_id
      latest: this.props.latest, // latest perfs
      isLoading: true,
      dataSource: [],
    };

    this.redoQuery = this.redoQuery.bind(this);
    this.redoQuery(
      this.props.movie_id,
      this.props.house_id,
      this.props.auditorium,
      this.props.date,
      this.props.latest
    );
  }

  /*
   ** componentDidMount - when first mounting, do query, which is probably empty
   */
  componentDidMount() {
    //this.redoQuery("", "");
  }

  /*
   ** redoQuery - query from AJAX
   */
  async redoQuery(movie_id, house_id, auditorium, date, latest) {
    var url = "";
    url = configState.apiBase + "perfs?function=search";
    if (house_id !== "") url += "&house_id=" + house_id;
    if (movie_id !== "") url += "&movie_id=" + movie_id;
    if (auditorium !== "") url += "&auditorium=" + auditorium;
    if (date !== "") url += "&date=" + date;
    if (latest !== "") url += "&latest=" + latest;
    console.log("Query URL: " + url);
    return fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.rows == null) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.rows == null ? [] : responseJson.rows,
          });
          return;
        }

        this.setState(
          {
            isLoading: false,
            dataSource: responseJson.rows == null ? [] : responseJson.rows,
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    // if nothing important changed, we're done.
    if (
      prevProps.house_id === this.props.house_id &&
      prevProps.movie_id === this.props.movie_id &&
      prevProps.auditorium === this.props.auditorium &&
      prevProps.date === this.props.date &&
      prevProps.latest === this.props.latest
    )
      return;

    this.setState({
      house_id: this.props.house_id, // search parameter
      movie_id: this.props.movie_id, // movie_id parameter
      date: this.props.date, // date parameter
      latest: this.props.latest, // Perfs parameter
      isLoading: true,
      dataSource: [],
    });

    // New props, have to rerun query
    this.redoQuery(
      this.props.movie_id,
      this.props.house_id,
      this.props.auditorium,
      this.props.date,
      this.props.latest
    );
  }

  /*
   ** render
   */
  render() {
    console.log("re-rendering perfs table");
    // Still loading, show animation
    if (this.state.isLoading) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <CircularProgress />
        </div>
      );
    } else if (
      this.state.dataSource.length === 0 &&
      (this.state.house_id !== "" || this.state.movie_id !== "")
    ) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <h4>No Results</h4>
        </div>
      );
    } else {
      /* Perfs table */
      return (
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "movie_id"}
                    direction={this.state.order}
                    onClick={createSortHandler("movie_id", this)}
                  >
                    MovieId
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "title"}
                    direction={this.state.order}
                    onClick={createSortHandler("title", this)}
                  >
                    Title
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "house_id"}
                    direction={this.state.order}
                    onClick={createSortHandler("house_id", this)}
                  >
                    HouseId
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "house"}
                    direction={this.state.order}
                    onClick={createSortHandler("house", this)}
                  >
                    House
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "showdate"}
                    direction={this.state.order}
                    onClick={createSortHandler("showdate", this)}
                  >
                    Showdate
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "showtime"}
                    direction={this.state.order}
                    onClick={createSortHandler("showtime", this)}
                  >
                    Showtime
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "soldout"}
                    direction={this.state.order}
                    onClick={createSortHandler("soldout", this)}
                  >
                    Soldout
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "auditorium"}
                    direction={this.state.order}
                    onClick={createSortHandler("auditorium", this)}
                  >
                    Auditorium
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "pupdated"}
                    direction={this.state.order}
                    onClick={createSortHandler("pupdated", this)}
                  >
                    Update
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "showtext"}
                    direction={this.state.order}
                    onClick={createSortHandler("showtext", this)}
                  >
                    Link
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dataSource.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{row.movie_id}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.house_id}</TableCell>
                    <TableCell>{row.house}</TableCell>
                    <TableCell>{row.showdate}</TableCell>
                    <TableCell>{row.showtime}</TableCell>
                    <TableCell>{row.soldout ? <CheckIcon /> : <CloseIcon />}</TableCell>
                    <TableCell>{row.auditorium}</TableCell>
                    <TableCell align="right">{row.pupdated}</TableCell>
                    <TableCell>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={row.showtext}
                      >
                        <DashboardIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    } // not isLoading
  } // render
} // MovieTable.js
