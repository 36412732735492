import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import MovieDetail from "./MovieDetail";
import HouseDetail from "./HouseDetail";

import { configState } from "../Config";

import ShowtimesHouseCard from "./ShowtimesHouseCard.js";
import ShowtimesTimesGrid from "./ShowtimesTimesGrid.js";
import ShowtimeMovieCards from "./ShowtimesMovieCards.js";

var workingData = {
  oldMovie: 0,
  oldHouse: 0,
  movieCounter: 0,
  showtimeMovieCounter: 0,
  fixedComment: "",
  houseCounter: 0,
  showExtra: "none",
};
//var component = this;

/* Class definition */
class Showtimes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      house_id: this.props.house_id,
      name: this.props.name,
      title: this.props.title,
      showdate: this.props.showdate,
      enddate: this.props.enddate,
      city: this.props.city,
      state: this.props.state,
      country: this.props.country,
      chain_id: this.props.chain_id,
      chain_name: this.props.chain_name,
      military: this.props.military,
      selectedMovie: 0,
      selectedHouse: 0,
      detailOpen: false,
      houseOpen: false,
      isLoading: false,
      dataSource: [],
      perfsSource: [],
    };

    this.executeQuery = this.executeQuery.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
  }

  /*
   ** componentDidMount - when first mounting, do query, which is probably empty
   */
  componentDidMount() {
    this.executeQuery(
      this.state.house_id,
      this.state.name,
      this.state.title,
      this.state.city,
      this.state.state,
      this.state.country,
      this.state.chain_id,
      this.state.chain_name,
      this.state.military,
      this.state.showdate,
      this.state.enddate
    );
  }

  /*
   ** executeQuery - get data from API
   */
  async executeQuery(
    house_id,
    houseName,
    title,
    city,
    state,
    country,
    chain_id,
    chain_name,
    showdate,
    enddate
  ) {
    var url = configState.apiBase + "showtimes?function=search";
    var perfsurl = configState.apiBase + "perfs?function=search";

    var baseUrl = url;
    if (house_id) {
      url += "&house_id=" + house_id;
      perfsurl += "&house_id=" + house_id;
    }
    if (houseName) {
      url += "&housename=" + houseName;
      perfsurl += "&house_name=" + houseName;
    }
    if (title) url += "&title=" + title;
    if (city) url += "&city=" + city;
    if (state) url += "&state=" + state;
    if (country) url += "&country=" + country;
    if (chain_id) {
      url += "&chain_id=" + chain_id;
      perfsurl += "&chain_id=" + chain_id;
    }
    if (chain_name) {
      url += "&chain_name=" + chain_name;
      perfsurl += "&chain_name=" + chain_name;
    }
    if (showdate) {
      url += "&showdate=" + showdate;
    }
    if (enddate) {
      url += "&enddate=" + enddate;
      perfsurl += "&date=" + enddate;
    }

    // Make sure we have at least one param
    if (url === baseUrl) {
      this.setState({ isLoading: false, dataSource: [] });
      return;
    }

    console.log("Showtimes: Loading URL: " + url);
    console.log("Perfs: Loading Perfs URL: " + perfsurl);
    this.setState({ isLoading: true });
    this.clearContext();
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      const response1 = await fetch(perfsurl);
      const responseJson1 = await response1.json();
      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows, // Full dataset
          perfsSource: responseJson1.rows == null ? [] : responseJson1.rows,
        },
        function () {
          // do something with new state
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  //House Button
  handleClickHouseOpen(houseId) {
    this.setState({
      selectedHouse: houseId,
      houseOpen: true,
    });
  }
  handleCloseHouseModal = () => {
    this.setState({
      houseOpen: false,
    });
  };

  /*
   ** Button click
   */
  handleClickOpen(movieId) {
    this.setState({
      selectedMovie: movieId,
      detailOpen: true,
    });
  }

  // Return from modal
  handleCloseModal = () => {
    this.setState({
      detailOpen: false,
      selectedHouse: 0,
    });
  };

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    // Execute the query only if the parameters have changed
    if (
      this.props.house_id !== prevProps.house_id ||
      this.props.name !== prevProps.name ||
      this.props.movie_id !== prevProps.movie_id ||
      this.props.title !== prevProps.title ||
      this.props.city !== prevProps.city ||
      this.props.state !== prevProps.state ||
      this.props.country !== prevProps.country ||
      this.props.chain_id !== prevProps.chain_id ||
      this.props.chain_name !== prevProps.chain_name ||
      this.props.showdate !== prevProps.showdate ||
      this.props.enddate !== prevProps.enddate ||
      this.props.military !== prevProps.military
    ) {
      // Update the state and get the new data
      this.setState({
        house_id: this.props.house_id,
        movie_id: this.props.movie_id,
        name: this.props.name,
        title: this.props.title,
        city: this.props.city,
        state: this.props.state,
        country: this.props.country,
        chain_id: this.props.chain_id,
        chain_name: this.props.chain_name,
        showdate: this.props.showdate,
        enddate: this.props.enddate,
        military: this.props.military,
        isLoading: true,
        dataSource: [],
        perfsSource: [],
      });

      // Actually execute a new data fetch
      this.executeQuery(
        this.props.house_id,
        this.props.name,
        this.props.title,
        this.props.city,
        this.props.state,
        this.props.country,
        this.props.chain_id,
        this.props.chain_name,
        this.props.showdate,
        this.props.enddate,
        this.props.military
      );
    }
  }

  /*
  formatDate() {
    var date = new Date();
    var weekArr = [];
    var todayDate = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var today = month + "/" + todayDate + "/" + year;

    weekArr.push(today);
    for (var x = 1; x < 6; x++) {
      todayDate = date.getDate() + x;
      if (date.getDate() + x === 1) {
        month += 1;
      }
      today = month + "/" + todayDate + "/" + year;
      weekArr.push(today);
    }

    var week = weekArr.map(function (weekdate) {
      return <Button>{weekdate}</Button>;
    });
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
          >
            {week}
            <Fab color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  }
  */
  clearContext() {
    workingData.oldMovie = 0;
    workingData.oldHouse = 0;
    return;
  }

  /*
   ** render
   */
  render() {
    workingData.movieCounter = 0;
    workingData.showtimeMovieCounter = 0;
    if (this.state.isLoading) {
      return <CircularProgress></CircularProgress>;
    }

    if (this.state.dataSource.length === 0) {
      return <div>No showtimes. Please choose a filter.</div>;
    } else {
      return (
        <div className="showtime-detail">
          <MovieDetail
            open={this.state.detailOpen}
            movie_id={this.state.selectedMovie}
            country={this.state.selectedCountry}
            onClose={this.handleCloseModal}
            fullScreen={false}
          />

          <HouseDetail
            open={this.state.houseOpen}
            house_id={this.state.selectedHouse}
            onClose={this.handleCloseModal}
            fullScreen={false}
          />
          {/*{this.formatDate()}*/}

          {this.state.dataSource.map((row, i) => {
            return (
              <div key={i}>
                <Container
                  className="showtime-container"
                  disableGutters
                  maxWidth={false}
                >
                  {ShowtimesHouseCard(row, workingData, this)}
                  {ShowtimeMovieCards(row, workingData, this)}
                  <ShowtimesTimesGrid
                    row={row}
                    prow={this.state.perfsSource}
                    presentDate={this.state.showdate}
                    endDate={this.state.enddate}
                    data={workingData}
                    military={this.state.military}
                  />
                </Container>
              </div>
            );
          })}

          {this.clearContext()}
        </div>
      );
    }
  }
}

export default Showtimes;
