import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Token from "../components/Token";
import Showtimes from "../components/Showtimes";
import { styles } from "../components/CsStyles";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import SearchBar from "../components/SearchBar/SearchBar";

class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      house_id: this.props.match.params["house_id"], // User entered field
      name: "", // User entered field
      title: "", // User entered field
      startdate: new Date(), // User data
      enddate: new Date(), // User data
      city: "", // User data
      state: "", // User data
      country: "", // User data
      chain_id: this.props.match.params["chain_id"], // User entered field
      chain_name: "", // User entered field
      military: false,
      search_house_id: this.props.match.params["house_id"], // Search data
      search_name: "", // Search data
      search_title: "", // Search data
      search_startdate: format(new Date(), "yyyy-MM-dd"), // Search data
      search_enddate: format(new Date(), "yyyy-MM-dd"), // Search data
      search_city: "", // Search data
      search_state: "", // Search data
      search_country: "", // Search data
      search_chain_id: this.props.match.params["chain_id"], // Search data
      search_chain_name: "", // Search data

      open: false,
    };

    // Title
    if (this.props.setTitle != null) this.props.setTitle("Schedules");

    this.fetchData = this.fetchData.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
  }

  /*
   ** clearSearch
   */
  clearSearch() {
    this.setState({
      search_house_id: "",
      search_name: "",
      search_title: "",
      search_startdate: format(new Date(), "yyyy-MM-dd"),
      search_enddate: format(new Date(), "yyyy-MM-dd"),
      search_city: "",
      search_state: "",
      search_country: "",
      search_chain_id: "",
      search_chain_name: "",
      military: false,
      house_id: "",
      name: "",
      chain_id: "",
      chain_name: "",
      title: "",
      startdate: new Date(),
      enddate: new Date(),
      city: "",
      state: "",
      country: "",
    });
  }

  /*
   ** handleChange - handle a key entered in a text form field
   */
  handleChange = (data) => (event) => {
    this.setState({
      [data]: event.target.value,
    });
  };

  /*
   ** handleSwitch - handle the change to the 24 hour switch
   */
  handleSwitch = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  /*
   ** handleStartDateChange - update start date but do not initiate search yet
   */
  handleStartDateChange = (date) => {
    if (date > this.state.enddate) {
      this.setState({
        startdate: date,
        enddate: date
      }, this.fetchData);
    }
    else this.setState({ startdate: date }, this.fetchData);
  };

  handleEndDateChange = (date) => {
    if (date < this.state.startdate) {
      this.setState({
        startdate: date,
        enddate: date
      }, this.fetchData);
    }
    else this.setState({ enddate: date }, this.fetchData);
  };

  /*
   ** enterPressed - enter or return key pressed
   */
  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      // 13 is the enter keycode
      this.fetchData();
    }
  }

  /*
   ** handleSearchCriteria - Callback routine from house filter
   */
  handleSearchCriteria = (data) => () => {
    // If data is returned from the seach box...
    if (data) {
      // Take any non-number from the main search field and assume it is a house name search
      if (data.main !== undefined && data.main !== "") {
        if (isNaN(data.main)) {
          if (data.mainField === "house_id") data.house_id = undefined;
          data.name = data.main;
          data.mainField = "name";
        } else {
          // But any number in the main search field is a house_id search
          if (data.mainField === "name") data.name = undefined;
          data.house_id = data.main;
          data.mainField = "house_id";
        }
      }

      this.setState(
        {
          house_id: data.house_id === undefined ? "" : data.house_id,
          name: data.name === undefined ? "" : data.name,
          chain_name: data.chain_name === undefined ? "" : data.chain_name,
          chain_id: data.chain_id === undefined ? "" : data.chain_id,
          altid: data.altid === undefined ? "" : data.altid,
          city: data.city === undefined ? "" : data.city,
          state: data.state === undefined ? "" : data.state,
          country: data.country === undefined ? "" : data.country,
        },
        this.fetchData
      );
    }
  };

  /*
   **
   ** fetchData - update the state search properties which are used by the showtimes widget
   **
   */
  fetchData() {
    this.setState({
      search_house_id: this.state.house_id,
      search_name: this.state.name,
      search_title: this.state.title,
      search_city: this.state.city,
      search_state: this.state.state,
      search_country: this.state.country,
      search_startdate: format(this.state.startdate, "yyyy-MM-dd"),
      search_enddate: format(this.state.enddate, "yyyy-MM-dd"),
      search_chain_id: this.state.chain_id,
      search_chain_name: this.state.chain_name,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Token />
        <CssBaseline />
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Typography variant="h4">Search</Typography>

            {/* Form begins here */}
            <div className={classes.appBarSpacer} />

            <div
              className={classes.advancedSearch}
              noValidate
              autoComplete="off"
            >
              <SearchBar
                {...this.props}
                onSave={this.handleSearchCriteria}
                searchCriteria={[
                  ["Name", "name"],
                  ["House Id", "house_id"],
                  ["Chain Name", "chain_name"],
                  ["Chain Id", "chain_id"],
                  ["Alt Id", "altid"],
                ]}
                show_country
                show_state_and_city
              />
            </div>
            <form>
              <TextField
                id="title"
                autoFocus
                label="Movie Title"
                className={classes.textField}
                value={this.state.title}
                onChange={this.handleChange("title")}
                onKeyPress={this.enterPressed.bind(this)}
                margin="normal"
                InputProps={{
                  style: {
                    padding: 2,
                  },
                }}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Start Date"
                  value={this.state.startdate}
                  onChange={this.handleStartDateChange}
                  format="yyyy-MM-dd"
                  margin="normal"
                  className={classes.datePicker}
                />
                <DatePicker
                  label="End Date"
                  value={this.state.enddate}
                  onChange={this.handleEndDateChange}
                  format="yyyy-MM-dd"
                  margin="normal"
                  className={classes.datePicker}
                  style={{ display: "none" }}
                />
              </MuiPickersUtilsProvider>

              <Button
                onClick={this.fetchData}
                className={(classes.button, classes.datePicker)}
              >
                Search
              </Button>
              <Button
                onClick={this.clearSearch}
                className={(classes.button, classes.datePicker)}
              >
                Clear
              </Button>
            </form>

            <FormControlLabel
              control={
                <Switch
                  checked={this.state.military}
                  onChange={this.handleSwitch}
                  name="military"
                  color="primary"
                />
              }
              label="24 Hour Format"
            />

            <Typography variant="h4" className={classes.sectionTitle}>
              Schedules
            </Typography>

            <div className={classes.contentflex}>
              <Showtimes
                house_id={this.state.search_house_id}
                name={this.state.search_name}
                chain_id={this.state.search_chain_id}
                chain_name={this.state.search_chain_name}
                title={this.state.search_title}
                city={this.state.search_city}
                state={this.state.search_state}
                country={this.state.search_country}
                showdate={this.state.search_startdate}
                enddate={this.state.search_enddate}
                military={this.state.military}
              />
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Schedule.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Schedule);
