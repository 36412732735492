import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Collapse from "@material-ui/core/Collapse";
import TextField from "@material-ui/core/TextField";
import { CountryCodes } from "../../Constants.js";



export default function LocationSearch(searchData, classes, container) {

    if (!container.props.show_country)
        return null;

    return <React.Fragment>
        <div className={classes.searchTextFieldBoxWide}>
            <h3>
                Country{" "}
                <span style={{ fontWeight: "normal" }}>
                    (3-Letter ISO Code)
                </span>
            </h3>
            <Autocomplete
                multiple
                id="countries"
                options={Object.keys(CountryCodes)}
                filterSelectedOptions
                onChange={container.handleChange("country")}
                onKeyPress={container.enterPressed.bind(container)}
                value={container.state.searchData.country}
                renderInput={(params) => (
                    <TextField
                        className={classes.searchTextField}
                        {...params}
                        variant="outlined"
                        margin="normal"
                        label="Country"
                        placeholder="Pick Country Name"
                    />
                )}
            />
        </div>
        <Collapse
            in={container.props.show_state_and_city && searchData.country.length > 0}
        >
            <div style={{ textAlign: "left" }}>
                <div className={classes.searchTextFieldBox}>
                    <h3>State</h3>
                    <TextField
                        id="state"
                        placeholder="Enter State Name"
                        onKeyPress={container.enterPressed.bind(container)}
                        className={classes.searchTextField}
                        value={searchData.state ? searchData.state : ""}
                        onChange={container.handleChange("state")}
                        margin="normal"
                        variant="outlined"
                    />
                </div>

                <br />

                <div className={classes.searchTextFieldBox}>
                    <h3>City</h3>
                    <TextField
                        id="city"
                        placeholder="Enter City Name"
                        onKeyPress={container.enterPressed.bind(container)}
                        className={classes.searchTextField}
                        value={searchData.city ? searchData.city : ""}
                        onChange={container.handleChange("city")}
                        margin="normal"
                        variant="outlined"
                    />
                </div>
            </div>
        </Collapse>
    </React.Fragment>
}