import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AutocompleteMovies from "../AutocompleteMovies";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";

import { configState } from "../../Config";
import { styles } from "../CsStyles";
import { CountryCodes } from "../../Constants";

import WhereIsTable from "./WhereIsTable";

/*
 ** Reports - a page for accessing reports
 */
class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            movie_id: "",
            startdate: "",
            enddate: "",
            country: "",
            imax: "",
            m3d: "",
            m2d: "",
            searchData: {},
            allMovies: [],
            queryUrl: "",
            search_name: "",
            search_startdate: "",
            search_enddate: "",
            search_country: "",
            search_imax: "",
            search_3d: "",
            search_2d: "",
            shouldSearch: false,
        };

        // Title
        if (this.props.setTitle != null) this.props.setTitle("Report");
        
        const localData = window.localStorage.getItem('CURR_SEARCH')
        var currData = JSON.parse(localData)
        if (currData !== null)
            this.state = currData
        this.fetchData = this.fetchData.bind(this);


    }

    enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) {
            // 13 is the enter keycode
            this.fetchData();
        }
    }
    
    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
        if (name === "imax") {
            if (this.state.imax === "imax") {
                this.setState({
                    imax: "",
                });
            }
            else {
                this.setState({
                    imax: event.target.value,
                });
            }
        }
        if (name === "m3d") {
            if (this.state.m3d === "m3d") {
                this.setState({
                    m3d: "",
                });
            }
            else {
                this.setState({
                    m3d: event.target.value,
                });
            }
        }

        if (name === "m2d") {
            if (this.state.m2d === "2d") {
                this.setState({
                    m2d: "",
                });
            }
            else {
                this.setState({
                    m2d: event.target.value,
                });
            }
        }
        if (name === "clear") {
            window.location = "/reports";
            this.setState({
                name: "",
                movie_id: "",
                startdate: "",
                enddate: "",
                country: "",
                imax: "",
                m3d: "",
                m2d: "",
            });
            window.localStorage.setItem("CURR_SEARCH", JSON.stringify(null))

        }
    }
    /*
     **
     ** fetchData
     **
     */
    fetchData() {
        console.log("Search now");
        this.setState({
            search_name: this.state.name,
            search_startdate: this.state.startdate,
            search_enddate: this.state.enddate,
            search_country: this.state.country,
            search_imax: this.state.imax,
            search_3d: this.state.m3d,
            search_2d: this.state.m2d,
            shouldSearch: true,
        });
        window.localStorage.setItem("CURR_SEARCH", JSON.stringify(this.state))

    }
   
    /*
     ** render
     **
     */
    render() {

        const { classes } = this.props;
        const localData = window.localStorage.getItem('CURR_SEARCH')
        var currData = JSON.parse(localData)
        // console.log("Local Movie ID " + currData.movie_id)
        return (
            <React.Fragment>
                <Box>
                    <Accordion defaultExpanded
                        style={{
                            width: "90%",
                            borderRadius: 0,
                            fontWeight: "bold",
                            marginLeft: "50px"
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id="filter-header"
                        >
                            <Typography>Filter</Typography>
                        </AccordionSummary>
                           
                            <FormControl variant="standard"
                                style={{
                                    width: "30%",
                                    marginTop: "16px",
                                    marginLeft: "16px"
                                }}>
                                <AutocompleteMovies handleSetId={(id) => this.setState({ movie_id: id })}/>
                            </FormControl>

                            <FormControl variant="standard"
                                style={{
                                    width: "10%",
                                    marginTop: "16px",
                                    marginLeft: "16px"
                                }}>
                                <InputLabel id="label_form">Country</InputLabel>
                                <Select
                                    labelId="country-label"
                                    id="country"
                                    label="Select Country"
                                    value={this.state.country}
                                    onChange={this.handleChange("country")}
                                >
                                    {Object.keys(CountryCodes).map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            title={CountryCodes[option]}
                                            value={option}
                                        >
                                        {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                id="startdate"
                                label="Start Date"
                                className={classes.textField}
                                value={this.state.startdate}
                                type="date"
                                onChange={this.handleChange("startdate")}
                                onKeyPress={this.enterPressed.bind(this)}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <TextField
                                id="enddate"
                                label="End Date"
                                className={classes.textField}
                                value={this.state.enddate}
                                type="date"
                                onChange={this.handleChange("enddate")}
                                onKeyPress={this.enterPressed.bind(this)}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <Button
                                variant="outlined"
                                onClick={this.fetchData}
                                className={classes.button}
                                style={{ padding: 10, marginTop: 15 }}
                            >
                                Search
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={this.handleChange("clear")}
                                className={classes.button}
                                style={{ padding: 10, marginTop: 15 }}
                            >
                                Clear
                            </Button>
                        <Grid
                            style={{ marginLeft: "5px" }}>
                                <FormControl className="Imax">
                                <FormHelperText
                                    style={{ marginLeft: "16px" }}>IMAX</FormHelperText>
                                    <Switch
                                        value={"imax"}
                                        label="imax"
                                        onChange={this.handleChange("imax")}
                                        color="primary"
                                    />
                                </FormControl>
                                <FormControl className="3D">
                                    <FormHelperText
                                    style={{ marginLeft: "25px" }}>3D</FormHelperText>
                                    <Switch
                                        value={"m3d"}
                                        label="3D"
                                        onChange={this.handleChange("m3d")}
                                        color="primary"
                                    />
                                </FormControl>
                            </Grid>
                        </Accordion>

                    <Box className={classes.appBarSpacer} />
                   
                        <WhereIsTable
                            name={this.state.search_name}
                            movie_id={this.state.movie_id}
                            startdate={this.state.search_startdate}
                            enddate={this.state.search_enddate}
                            country={this.state.search_country}
                            imax={this.state.search_imax}
                            m3d={this.state.search_3d}
                            m2d={this.state.search_2d}
                            queryUrl={this.state.queryUrl} 
                            shouldSearch={this.state.shouldSearch}
                            handleShouldSearch={(shouldSearch) => this.setState({ shouldSearch })}
                        />  
                    
                </Box >
            </React.Fragment>
        );
    }
}

Reports.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Reports);
