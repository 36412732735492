import { configState } from "../Config";

function ReportRequests(report) {
    let data = {
        searchCriteria: [],
        mainField: "",
        baseQueryUrl: "",
        state_amd_city: false,
    };
    data.baseQueryUrl = configState.apiBase + "tiktok?function=search";
    switch (report.toLowerCase()) {
        case "status":
            data.searchCriteria = [
                ["Movie Id", "movie_id"],
                ["Children", "children", "checkbox"],
                ["Start Date", "startdate", "date"],
                ["End Date", "enddate", "date"],
            ];
            data.mainField = "movie_id";
            break;
        case "configuration":
            data.searchCriteria = [
                ["House Id", "house_id"],
                ["Chain Id", "chain_id"],
                ["Start Date", "startdate", "date"],
                ["End Date", "enddate", "date"],
            ];
            data.mainField = "house_id";
            break;
        case "reporting":
            data.searchCriteria = [
                ["House Id", "house_id"],
                ["Chain Id", "chain_id"],
                ["Start Date", "startdate", "date"],
                ["End Date", "enddate", "date"],
            ];
            data.mainField = "house_id";
            break;
        default:
            console.log("there is no report " + report);

    }
    return data;
}

export default ReportRequests;
