import React from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
  mainListItems,
  secondaryListItems,
  reportListItems,
  tertiaryListItems,
} from "./ListItems";

export class SideBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.isOpen,
      onClick: props.onClick,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ open: this.props.isOpen });
    }
  }

  handleDrawerClose = (event) => {
    this.setState({ open: false });
    if (this.props.onClick != null) this.props.onClick(event.target.value); // callback
  };

  render() {
    const divStyle = {
      paddingBottom: "15px",
      zIndex: "0",
    };

    return (
      <div>
        <Drawer variant="persistent" open={this.state.open}>
          <div style={divStyle}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List onClick={this.handleDrawerClose}>{mainListItems}</List>
          <Divider />
          <List onClick={this.handleDrawerClose}>{reportListItems}</List>
          <Divider />
          <List onClick={this.handleDrawerClose}>{secondaryListItems}</List>
          <Divider />
          <List onClick={this.handleDrawerClose}>{tertiaryListItems}</List>
        </Drawer>
      </div>
    );
  }
}
