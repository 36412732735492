import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { configState } from "../Config";
import Loading from "../components/Loading";
import OpeningDetail from "./OpeningDetail";
import { createSortHandler } from "../components/TableSortHelperMethods";

/*
 **
 ** OpeningTable
 **
 */
export class OpeningTable extends React.Component {
  /*
   ** constructor
   **/
  constructor(props) {
    console.log("OpeningTable - Constructor");
    super(props);
    this.state = {
      selectedChain: 0, // selected chain for detail section
      selectedChainName: "",
      isLoading: false,
      dataSource: [],
    };

    this.redoQuery = this.redoQuery.bind(this);
  }

  /*
   ** componentDidMount - when first mounting, do query, which is probably empty
   */
  componentDidMount() {
    console.log("OpeningTable - ComponentDidMount");
    this.redoQuery(
      this.props.chain_id,
      this.props.country,
      this.props.showdate
    );
  }

  /*
   ** Button click
   */
  handleClickOpen(chainId, chainName) {
    if (chainId !== this.state.selectedChain)
      this.setState({
        selectedChain: chainId,
        selectedChainName: chainName,
      });
    console.log("OpeningTable: new chain to open detail - " + chainId);
  }

  // Return from modal
  handleCloseModal = () => {
    this.setState({ selectedChain: 0 });
  };

  /*
   ** redoQuery - query from AJAX
   */
  async redoQuery(chain_id, country, showdate) {
    if (country === "") return;

    var url = configState.apiBase + "opening?function=search";
    if (country !== "") url += "&country=" + country;
    if (chain_id !== "") url += "&chain_id=" + chain_id;
    if (showdate !== "") url += "&showdate=" + showdate;
    console.log("OpeningTable - Query URL: " + url);
    return fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /*
   ** shouldComponentUpdate - if props changed, requery. If state changed, rerender. If neither, do not rerender
   */
  shouldComponentUpdate(nextProps, nextState) {
    // Before we requery, let' just see if the popup needs to open or close
    /*
    if (this.state.selectedChain !== nextState.selectedChain) {
      return true;
    }
*/

    // If Props have changed, change state
    if (
      this.props.country !== nextProps.country ||
      this.props.chain_id !== nextProps.chain_id ||
      this.props.showdate !== nextProps.showdate
    ) {
      console.log("OpeningTable - shouldComponentUpdate? YES. New Props.");

      // Country is required
      if (nextProps.country === "") return false;

      // New props, have to rerun query but no reason to refresh yet. We'll refresh when state changes.
      this.setState({
        isLoading: true,
        dataSource: [],
      });
      this.redoQuery(nextProps.chain_id, nextProps.country, nextProps.showdate);
      return false;
    }

    // If state has changed, sure!
    if (
      this.state.dataSource !== nextState.dataSource ||
      this.state.isLoading !== nextState.isLoading ||
      this.state.selectedChain !== nextState.selectedChain ||
      this.state.orderBy !== nextState.orderBy ||
      this.state.order !== nextState.order
    ) {
      return true;
    }

    // Finally no changes at all, why are we here?
    console.log("OpeningTable - shouldComponentUpdate? No.");
    return false;
  }

  /*
   ** render
   */
  render() {
    console.log("OpeningTable - re-rendering");

    // Still loading, show animation
    if (this.state.isLoading) {
      return <Loading />;
    } else if (
      this.state.dataSource.length === 0 &&
      (this.props.country !== "" || this.props.chain_id !== "")
    ) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <h4>
            No Results. Please Enter Country or Chain ID to filter results.
          </h4>
        </div>
      );
    } else {
      /* Opening table */
      return (
        <div>
          <OpeningDetail
            chain_id={this.state.selectedChain}
            title={this.state.selectedChainName}
            onClose={this.handleCloseModal}
          />

          <Table>
            <TableHead>
              <TableRow>

                <TableCell align="right">
                  <TableSortLabel
                    active={this.state.orderBy === "chain_id"}
                    direction={this.state.order}
                    onClick={createSortHandler("chain_id", this)}
                  >
                    Chain ID
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "chainname"}
                    direction={this.state.order}
                    onClick={createSortHandler("chainname", this)}
                  >
                    Chain Name
                  </TableSortLabel>
                </TableCell>

                <TableCell align="right">
                  <TableSortLabel
                    active={this.state.orderBy === "chainhousecount"}
                    direction={this.state.order}
                    onClick={createSortHandler("chainhousecount", this)}
                  >
                    Houses in Chain
                  </TableSortLabel>
                </TableCell>

                <TableCell align="right">
                  <TableSortLabel
                    active={this.state.orderBy === "todaycount"}
                    direction={this.state.order}
                    onClick={createSortHandler("todaycount", this)}
                  >
                    Houses with Movies Today
                  </TableSortLabel>
                </TableCell>

                <TableCell align="right">
                  <TableSortLabel
                    active={this.state.orderBy === "futurecount"}
                    direction={this.state.order}
                    onClick={createSortHandler("futurecount", this)}
                  >
                    Houses with Movies Future
                  </TableSortLabel>
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dataSource.map((row) => {
                return (
                  <TableRow key={row.chain_id} style={{ cursor: "pointer" }}>
                    <TableCell align="right" component="th" scope="row">
                      {row.chain_id}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        this.handleClickOpen(row.chain_id, row.chainname);
                      }}
                    >
                      {row.chainname}
                    </TableCell>
                    <TableCell align="right">{row.chainhousecount}</TableCell>
                    <TableCell align="right">{row.todaycount}</TableCell>
                    <TableCell align="right">{row.futurecount}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    } // not isLoading
  } // render
} // OpeningTable.js
