import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { fetchData } from "./helpers";

import Row from "./Row";
import Wrapper from "./Wrapper";

const MovieDupes = ({ setTitle }) => {
  const [loading, setLoading] = useState(false);
  const [dupes, setDupes] = useState([]);
  
  useEffect(() => {
    setTitle("Movie dupes");
    setLoading(true);
    fetchData({
      endpoint: "dupes/list", 
      handleComplete: (response) => {
        setDupes(response.data);
        setLoading(false);
      },
    });
  }, []);
  
  return (
    <Wrapper loading={loading}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Current movie ID</TableCell>
            <TableCell>Next movie ID</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row addNewDupe={(nextDupe) => setDupes([nextDupe, ...dupes])} />
          {dupes
            .map((dupe) => (
              <Row key={dupe.id} dupe={dupe} />
            ))}
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default MovieDupes;
