import React from "react";

import AppBar from "@material-ui/core/AppBar";
import AddIcon from "@material-ui/icons/Add";
import Toolbar from "@material-ui/core/Toolbar";
import Fab from "@material-ui/core/Fab";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  text: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
});

class BotAppBar extends React.Component {
  state = {
    onAdd: this.props.onAdd,
  };

  handleAdd = () => {
    if (this.state.onAdd != null) this.state.onAdd();
  };

  render() {
    const { classes } = this.props;

    return (
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Fab
            color="secondary"
            aria-label="Add"
            className={classes.fabButton}
            onClick={this.handleAdd}
          >
            <AddIcon />
          </Fab>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(BotAppBar);
