import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Token from "../components/Token";
import BotAppBar from "../components/BotAppBar";
import { styles } from "../components/CsStyles";
import RemapTable from "../components/RemapTable";
import RemapDetail from "../components/RemapDetail";
import { CountryCodes } from "../Constants.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grow from "@material-ui/core/Grow";
import Button from "@material-ui/core/Button";

class Remaps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      lastPage: false,

      search_house_id: "",
      search_movie_id: "",
      search_chain_id: "",
      search_country: "",
      search_start_date: "",
      search_end_date: "",

      house_id: "",
      movie_id: "",
      chain_id: "",
      start_date: "",
      end_date: "",
      country: [],

      remap_id: 0 /* selected remap for details */,
      remap_row: null /* selected remap for duplication */,
      refresh: false,
    };

    // Title
    if (this.props.setTitle != null) this.props.setTitle("RemapOMatic");

    this.detailRef = React.createRef();

    this.handleSelection = this.handleSelection.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
  }

  tableRefreshed = (value) => {
    this.setState({ refresh: value });
  };

  handleChange = (name) => (event, data) => {
    if (name === "country") {
      this.setState({
        [name]: data,
      });
    } else
      this.setState({
        [name]: event.target.value,
      });
  };

  handleSelection(remapId) {
    console.log("Got new remap:" + remapId);
    this.setState({
      remap_id: remapId,
    });
  }

  handleAdd(row) {
    this.setState({
      remap_id: -1,
      remap_row: row || null,
      lastPage: true,
    });
  }

  scrollToBottom() {
    this.detailRef.current.scrollIntoView({ behavior: "smooth" });
  }

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      // 13 is the enter keycode
      this.fetchData();
    }
  }

  handleDateChange = (name) => (date) => {
    this.setState({ [name]: date });
  };

  clearSearch = () => {
    this.setState({
      search_house_id: "",
      search_movie_id: "",
      search_chain_id: "",
      search_start_date: "",
      search_end_date: "",
      search_country: "",
      house_id: "",
      movie_id: "",
      chain_id: "",
      start_date: "",
      end_date: "",
      country: [],
    });
  };

  fetchData = () => {
    this.setState({
      search_house_id: this.state.house_id,
      search_movie_id: this.state.movie_id,
      search_chain_id: this.state.chain_id,
      search_start_date: this.state.start_date,
      search_end_date: this.state.end_date,
      search_country: this.state.country.join(","),
      lastPage: false
    });
  };

  /*
   ** handleSave - when detail saves, then set the selected to nothing. Not sure why.
   */
  handleSave = () => {
    this.setState({ remap_id: 0, refresh: true });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Token />
        <CssBaseline />
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />

            {/* Form begins here */}
            <form className={classes.container} noValidate autoComplete="off">
              <Grid container>
                <TextField
                  id="movie_id"
                  label="Movie ID"
                  className={classes.textField}
                  value={this.state.movie_id}
                  onChange={this.handleChange("movie_id")}
                  onKeyPress={this.enterPressed.bind(this)}
                  margin="normal"
                />
                <TextField
                  id="house_id"
                  label="House ID"
                  className={classes.textField}
                  value={this.state.house_id}
                  onChange={this.handleChange("house_id")}
                  onKeyPress={this.enterPressed.bind(this)}
                  margin="normal"
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                />
                <TextField
                  id="chain_id"
                  label="Chain ID"
                  className={classes.textField}
                  value={this.state.chain_id}
                  onChange={this.handleChange("chain_id")}
                  onKeyPress={this.enterPressed.bind(this)}
                  margin="normal"
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                />

                <TextField
                  id="start_date"
                  label="Start Date"
                  className={classes.textField}
                  value={this.state.start_date}
                  type="date"
                  onChange={this.handleChange("start_date")}
                  onKeyPress={this.enterPressed.bind(this)}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    format: "yyyy-MM-dd",
                    max: this.state.end_date,
                  }}
                />
                <TextField
                  id="end_date"
                  label="End Date"
                  className={classes.textField}
                  value={this.state.end_date}
                  type="date"
                  onChange={this.handleChange("end_date")}
                  onKeyPress={this.enterPressed.bind(this)}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    format: "yyyy-MM-dd",
                    min: this.state.start_date,
                  }}
                />
                <Autocomplete
                  multiple
                  className={classes.countrySelectAutoComplete}
                  id="country"
                  options={Object.keys(CountryCodes)}
                  filterSelectedOptions
                  onChange={this.handleChange("country")}
                  onKeyPress={this.enterPressed.bind(this)}
                  value={this.state.country}
                  renderInput={(params) => (
                    <TextField
                      className={classes.searchTextField}
                      {...params}
                      variant="outlined"
                      margin="normal"
                      label="Country"
                      placeholder="Pick Country Name"
                    />
                  )}
                />
              </Grid>

              <Button
                onClick={this.fetchData}
                className={(classes.button, classes.datePicker)}
              >
                Search
              </Button>
              <Button
                onClick={this.clearSearch}
                className={(classes.button, classes.datePicker)}
              >
                Clear
              </Button>
            </form>

            <div>
              <RemapTable
                remap_id={this.state.remap_id}
                house_id={this.state.search_house_id}
                chain_id={this.state.search_chain_id}
                search={this.state.search_movie_id}
                country={this.state.search_country}
                start_date={this.state.search_start_date}
                end_date={this.state.search_end_date}
                onAdd={this.handleAdd}
                onSelect={this.handleSelection}
                refresh={this.state.refresh}
                tableRefreshed={this.tableRefreshed}
                lastPage={this.state.lastPage}
              />
            </div>

            <Grow
              in={this.state.remap_id !== 0}
              style={{ transformOrigin: "0 0 0" }}
              {...(this.state.remap_id !== 0 ? { timeout: 1000 } : {})}
            >
              <div className="FORM HERE">
                <RemapDetail
                  remap_id={this.state.remap_id}
                  onClose={this.handleCloseModal}
                  handleSave={this.handleSave}
                  remap_row={this.state.remap_row}
                  tableRefreshed={this.tableRefreshed}
                  scrollToBottom={this.scrollToBottom}
                />
              </div>
            </Grow>

            <div ref={this.detailRef} />
          </main>

          <BotAppBar onAdd={this.handleAdd.bind(this)} />
        </div>
      </React.Fragment>
    );
  }
}

Remaps.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Remaps);
