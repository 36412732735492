import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { configState } from "../Config";
import { createSortHandler } from "../components/TableSortHelperMethods";
import Brightness1 from "@material-ui/icons/Brightness1";

/*
 **
 ** ScheduleStatusTable
 **
 */
export default class ScheduleStatusTable extends React.Component {
  /*
   ** constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      chain_id: this.props.chain_id, // search chain_id
      chain_name: this.props.chain_name,
      country: this.props.country, // search country
      onSelect: this.props.onSelect, // callback for selection, return code or house_id
      isLoading: true,
      selectedHouseId: 0, // house_id if we have a popup to display
      dataSource: [],
    };

    this.executeQuery = this.executeQuery.bind(this);
    //this.handleClickOpen = this.handleClickOpen.bind(this);
    this.executeQuery(
      this.props.chain_id,
      this.props.chain_name,
      this.props.country
    );
  }

  /*
   ** executeQuery - get data from API
   */
  async executeQuery(chain_id, chain_name, country) {
    var url = configState.apiBase + "schedulestatus?function=search";
    if (chain_name !== "") {
      url += "&wildcard=" + encodeURIComponent(chain_name);
    }
    if (chain_id !== "") {
      url += "&chain_id=" + encodeURIComponent(chain_id);
    }
    if (country !== "") {
      url += "&country=" + encodeURIComponent(country);
    }
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows,
        },
        function () {
          // do something with new state
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    // if nothing important changed, we're done.
    if (
      prevProps.chain_id === this.props.chain_id &&
      prevProps.chain_name === this.props.chain_name &&
      prevProps.country === this.props.country &&
      prevProps.selectedChainId === this.props.selectedChainId
    ) {
      return;
    }

    this.setState({
      chain_id: this.props.chain_id, // chain_id parameter
      chain_name: this.props.chain_name, // chain_name parameter
      country: this.props.country, // country parameter
      onSelect: this.props.onSelect, // callback for selection
      selectedChainId: this.props.selectedChainId,
      isLoading: true,
      dataSource: [],
    });

    // New props, have to rerun query
    this.executeQuery(
      this.props.chain_id,
      this.props.chain_name,
      this.props.country
    );
  }

  /*
   ** wasSearching - return true if there was a search in progress, false if all search params are blank
   */
  wasSearching() {
    if (
      this.state.chain_id === "" &&
      this.state.chain_name === "" &&
      this.state.country === ""
    ) {
      return "Please enter search parameters and click the SEARCH button";
    } else {
      return "No results found";
    }
  }

  /*
   ** shouldComponentUpdate
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.dataSource === this.state.dataSource &&
      nextState.order === this.state.order &&
      nextState.orderBy === this.state.orderBy
    )
      return false;
    else return true;
  }

  StatusIcon(color) {
    var rgb = "blue";

    if (color === "R") rgb = "red";
    if (color === "G") rgb = "green";
    if (color === "Y") rgb = "yellow";

    return <Brightness1 style={{ color: rgb }} />;
  }

  /*
   ** render
   */
  render() {
    // Still loading, show animation
    if (this.state.isLoading) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <span className="glyphicon-left glyphicon glyphicon-refresh spinning"></span>
        </div>
      );
    } else if (this.state.dataSource.length === 0) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>{this.wasSearching()}</div>
      );
    } else {
      /* Chain table */
      console.log("ScheduleStatusTable: Rerendering.");
      return (
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "house_id"}
                    direction={this.state.order}
                    onClick={createSortHandler("house_id", this)}
                  >
                    House ID
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "sta"}
                    direction={this.state.order}
                    onClick={createSortHandler("sta", this)}
                  >
                    STA
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "name"}
                    direction={this.state.order}
                    onClick={createSortHandler("name", this)}
                  >
                    House Name
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "country"}
                    direction={this.state.order}
                    onClick={createSortHandler("country", this)}
                  >
                    Country
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "mtd"}
                    direction={this.state.order}
                    onClick={createSortHandler("mtd", this)}
                  >
                    MTD
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "note"}
                    direction={this.state.order}
                    onClick={createSortHandler("note", this)}
                  >
                    Note
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "wip"}
                    direction={this.state.order}
                    onClick={createSortHandler("wip", this)}
                  >
                    WIP
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "wipdata"}
                    direction={this.state.order}
                    onClick={createSortHandler("wipdata", this)}
                  >
                    WIPDATA
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "priority"}
                    direction={this.state.order}
                    onClick={createSortHandler("priority", this)}
                  >
                    Priority
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "gap"}
                    direction={this.state.order}
                    onClick={createSortHandler("gap", this)}
                  >
                    Gap
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "refreshed"}
                    direction={this.state.order}
                    onClick={createSortHandler("refreshed", this)}
                  >
                    Refreshed
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.dataSource.map((row) => {
                return (
                  <TableRow padding="dense" key={row.house_id}>
                    <TableCell component="th" scope="row">
                      {row.house_id}
                    </TableCell>

                    <TableCell>{this.StatusIcon(row.sta)} </TableCell>

                    <TableCell>{row.name}</TableCell>

                    <TableCell>{row.country}</TableCell>

                    <TableCell>{row.mtd}</TableCell>

                    <TableCell>{row.note}</TableCell>

                    <TableCell>{row.wip}</TableCell>

                    <TableCell>{row.wipdata}</TableCell>

                    <TableCell>{row.priority}</TableCell>

                    <TableCell>{row.gap}</TableCell>

                    <TableCell>{row.refreshed}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    } // not isLoading
  } // render
}
