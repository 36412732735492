import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { styles } from "../../components/CsStyles";
import Token from "../../components/Token";
import MetaEmbed from "../../components/MetaEmbed";

class TikTokReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };

    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Token />
                <CssBaseline />
                <div className={classes.root}>
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <div style={{ height: "80%" }}>
                            <MetaEmbed
                                secretkey="4a9d6c2a8c77a093a4e9efdb5efbca001f280ded79f69c84524340ec071f6e37"
                                dashboardid="128"
                                dashboardorquestion="dashboard"
                                refresh="300"
                            />
                        </div>
                    </main>
                </div>
            </React.Fragment>
        );
    }
}

TikTokReports.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TikTokReports);
