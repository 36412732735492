import React, { useReducer, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import isEqual from "lodash/isEqual";

import { fetchData } from "../../utils/fetchData";

const tryGetValue = (data, key, defaultValue = "") => {
  if (data && key in data) {
    return data[key] || defaultValue;
  }
  return defaultValue;
};

const fillValues = (data, isNew = false) => ({
  vendor_id: !isNew ? tryGetValue(data, "vendor_id") : null,
  vendorname: tryGetValue(data, "vendorname", "New Vendor"),
  vendorid: tryGetValue(data, "vendorid"),
  vendorsite: tryGetValue(data, "vendorsite"),
  vendorcountries: tryGetValue(data, "vendorcountries"),
  chain_id: tryGetValue(data, "chain_id", 0),
  housepattern: tryGetValue(data, "housepattern"),
  linkpattern: tryGetValue(data, "linkpattern"),
  houseids: tryGetValue(data, "houseids"),
  vendorlogo: tryGetValue(data, "vendorlogo"),
  enabled: tryGetValue(data, "enabled", false),
  free_bucket: tryGetValue(data, "free_bucket", false),
});

const Form = ({ onClose, onComplete, data = null, isNew = false }) => {
  const [loading, setLoading] = useState(false);
  const [values, dispatchValues] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_VALUE":
          return {
            ...state,
            [action.key]: action.value,
          };
        default:
          throw Error("Unknown action.");
      }
    },
    fillValues(data, isNew),
  );

  const handleSave = () => {
    setLoading(true);
    fetchData({
      endpoint: isNew ? "vendors/create" : "vendors/update",
      body: values,
      handleComplete: (response) => {
        if (isNew) {
          setValue("vendor_id", tryGetValue(response.data, "vendor_id"));
        }
        onComplete(response.data);
      },
      handleFinally: () => setLoading(false),
    });
  };

  const setValue = (key, value) =>
    dispatchValues({ type: "SET_VALUE", key, value });

  return (
    <Dialog open onClose={onClose}>
      <div
        style={{
          alignItems: "center",
          display: "grid",
          gridTemplateColumns: `repeat(6,minmax(0, 1fr))`,
          gap: "1rem",
          padding: "1rem",
          width: "100%",
        }}
      >
        <FormControl style={{ gridColumn: "span 4" }}>
          <TextField
            label={"Name"}
            name="vendorname"
            type="text"
            value={values.vendorname}
            onChange={(e) => {
              setValue("vendorname", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl style={{ gridColumn: "span 2" }}>
          <TextField
            label={"ID"}
            name="vendorid"
            type="text"
            value={values.vendorid}
            onChange={(e) => {
              setValue("vendorid", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl style={{ gridColumn: "span 2" }}>
          <TextField
            label={"Countries"}
            name="vendorcountries"
            type="text"
            value={values.vendorcountries}
            onChange={(e) => {
              setValue("vendorcountries", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl style={{ gridColumn: "span 4" }}>
          <TextField
            label={"Website"}
            name="vendorsite"
            type="text"
            value={values.vendorsite}
            onChange={(e) => {
              setValue("vendorsite", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl style={{ gridColumn: "span 3" }}>
          <TextField
            label={"Chain ID"}
            name="chain_id"
            type="number"
            value={values.chain_id}
            onChange={(e) => {
              setValue("chain_id", Number(e.target.value));
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl style={{ gridColumn: "span 3" }}>
          <TextField
            label={"House IDs"}
            name="houseids"
            type="text"
            value={values.houseids}
            onChange={(e) => {
              setValue("houseids", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl style={{ gridColumn: `span 6` }}>
          <TextField
            label={"House pattern"}
            name="housepattern"
            type="text"
            value={values.housepattern}
            onChange={(e) => {
              setValue("housepattern", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl style={{ gridColumn: "span 6" }}>
          <TextField
            label={"Link pattern"}
            name="linkpattern"
            type="text"
            value={values.linkpattern}
            onChange={(e) => {
              setValue("linkpattern", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl
          style={{ gridColumn: `span ${!!values.vendorlogo ? "5" : "6"}` }}
        >
          <TextField
            label={"Logo"}
            name="vendorlogo"
            type="text"
            value={values.vendorlogo}
            onChange={(e) => {
              setValue("vendorlogo", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>
        {!!values.vendorlogo ? (
          <div style={{ gridColumn: "span 1" }}>
            <img src={values.vendorlogo} style={{ width: "100%" }} />
          </div>
        ) : null}

        <FormControl style={{ gridColumn: "span 1" }}>
          <FormLabel>
            Enabled
            <Checkbox
              checked={values.enabled}
              onChange={() => setValue("enabled", !values.enabled)}
              title="enabled"
            ></Checkbox>
          </FormLabel>
        </FormControl>

        <FormControl style={{ gridColumn: "span 1" }}>
          <FormLabel>
            Free Bucket
            <Checkbox
              checked={values.free_bucket}
              onChange={() => setValue("free_bucket", !values.run_once)}
              title="free_bucket"
            ></Checkbox>
          </FormLabel>
        </FormControl>

        <DialogActions style={{ gridColumn: "span 4" }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              disabled={isEqual(fillValues(data), values)}
            >
              Save
            </Button>
          )}
          <Button onClick={onClose} variant="contained" color="secondary">
            Close
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default Form;
