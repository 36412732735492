import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Fab from "@material-ui/core/Fab";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grow from '@material-ui/core/Grow';
import { withStyles } from "@material-ui/core/styles";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { BoxofficeRed } from "../CsStyles";
import { configState } from "../../Config";

/* Style */
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: 30,
    marginTop: 20,
    marginBottom: 100,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  toolbar: {
    margin: "auto",
    top: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
});

/* Class definition */
class TikTokConfigurationsAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAdd: this.props.openAdd,
      data: this.props.row,
    };

    this.handleInsert = this.handleInsert.bind(this);
  }

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps) {
    if (this.props.row !== prevProps.row) {
      // Update the state and get the new data
      this.setState({
        data: this.props.row,
      });
    }
  }

  /*
   ** handleChange - handle the change in a text field, to make it permanent
   */
  handleChange = (name) => (event) => {
    this.setState({ data: { ...this.state.data, [name]: event.target.value } });
  };

  // clears the data
  clearForm = () => {
    this.setState({ data: {} });
  };


  /*
   **
   ** handleInsert
   **
   */
  async handleInsert() {

    var url =
      configState.apiBase;

    if (this.state.data.id)
      url += "imports?function=update";
    else
      url += "imports?function=insert";
    Object.keys(this.state.data).forEach(key => {
      url += "&" + key + '=' + this.state.data[key];
    });
    try {
      await fetch(url);
      this.props.executeQuery();
    } catch (error) {
      console.error(error);
    }
  };


  /*
   ** render
   */
  render() {
    const { classes } = this.props;
    return (
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Fab
            color="secondary"
            aria-label="Add"
            className={classes.fabButton}
            onClick={this.state.openAdd}
          >
            {this.props.addOpen ?
              <ExpandMoreIcon /> : <ExpandLessIcon />}
          </Fab>
          <Accordion color="primary" expanded={this.props.addOpen}>
            <AccordionSummary>
              <Typography variant="h5">
                Add/Update A Config
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ marginTop: "0px", paddingTop: "0px" }}>

              <form
                className={classes.container}
                noValidate
                autoComplete="off"
                onSubmit={this.handleInsert}
              >
                <Grid container>

                  <TextField
                    id="crawler"
                    label="Crawler"
                    name="crawler"
                    className={classes.textField}
                    value={this.state.data.crawler || ''}
                    onChange={this.handleChange("crawler")}
                    margin="normal"
                  />

                  <TextField
                    id="alt_prefix"
                    label="Alt Prefix"
                    name="alt_prefix"
                    className={classes.textField}
                    value={this.state.data.alt_prefix || ''}
                    onChange={this.handleChange("alt_prefix")}
                    margin="normal"
                  />

                  <TextField
                    id="codes_source"
                    label="Codes Source"
                    name="codes_source"
                    className={classes.textField}
                    value={this.state.data.codes_source || ''}
                    onChange={this.handleChange("codes_source")}
                    margin="normal"
                  />

                  <TextField
                    id="urls_file"
                    label="Urls File"
                    name="urls_file"
                    className={classes.textField}
                    value={this.state.data.urls_file || ''}
                    onChange={this.handleChange("urls_file")}
                    margin="normal"
                  />

                  <TextField
                    id="day_range"
                    label="Day Range"
                    name="day_range"
                    className={classes.textField}
                    value={this.state.data.day_range || ''}
                    onChange={this.handleChange("day_range")}
                    margin="normal"
                    type="Number"
                  />

                  <TextField
                    id="run_interval_minutes"
                    label="Interval"
                    name="run_interval_minutes"
                    className={classes.textField}
                    value={this.state.data.run_interval_minutes || ''}
                    onChange={this.handleChange("run_interval_minutes")}
                    margin="normal"
                    type="Number"
                  />

                  <Grow in={this.state.data.id !== undefined}><TextField
                    id="id"
                    label="ID"
                    name="id"
                    className={classes.textField}
                    value={this.state.data.id || ''}
                    onChange={this.handleChange("id")}
                    margin="normal"
                    type="Number"
                    disabled
                  /></Grow>

                  <Divider width="100%" />
                  <br />
                  <Button
                    variant="contained"
                    style={{ "backgroundColor": BoxofficeRed, "color": "white" }}
                    onClick={this.handleInsert}
                    className={classes.button}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={this.clearForm}
                    className={classes.button}
                  >
                    Clear
                  </Button>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>
        </Toolbar>
      </AppBar >
    );
  }
}

export default withStyles(styles)(TikTokConfigurationsAdd);
