import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import Token from "../Token";
import SearchBar from "../SearchBar/SearchBar";
import TikTokConfigurationsTable from "./TikTokConfigurationsTable";
import { styles } from "..//CsStyles";

/*
 ** TikTokConfigurations - a list of TikTokConfigurations based on search criteria passed-in
 */
class TikTokConfigurations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchData: {},
            ancholEl: null,
            open: false,
        };
    }

    /*
     ** componentDidMount - when first mounting, do query, which is probably empty
     */
    componentDidMount() {
        this.setState(this.props.match.params);
        this.handleSearchCriteria();
    }

    /*
     **
     ** fetchData
     **
     */
    handleSearchCriteria = (data) => () => {
        if (data) {
            if (data.main !== undefined && data.main !== "") {
                if (isNaN(data.main)) {
                    if (data.mainField === "crawler") delete data.id;

                    data.crawler = data.main;
                    data.mainField = "crawler";
                } else {
                    if (data.mainField === "name") delete data.crawler;

                    data.id = data.main;
                    data.mainField = "id";
                }
            }
            this.setState({
                searchData: data
            });
        }
    };

    /*
     ** render
     **
     */
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Token />
                <CssBaseline />

                <div className={classes.root}>
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />

                        <div
                            className={classes.advancedSearch}
                            noValidate
                            autoComplete="off"
                        >
                            <SearchBar
                                {...this.props}
                                onSave={this.handleSearchCriteria}
                                searchCriteria={[
                                    ["Configuration Id", "id"],
                                    ["Crawler", "crawler"],
                                    ["Run Status", "run_status"]
                                ]}
                            />
                        </div>

                        <div className={classes.tableContainer}>
                            <TikTokConfigurationsTable
                                searchData={this.state.searchData}
                                id={this.state.searchData.id}
                                crawler={this.state.searchData.crawler}
                                status={this.state.searchData.run_status}
                            />
                        </div>
                    </main>
                </div>
            </React.Fragment>
        );
    }
}

TikTokConfigurations.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TikTokConfigurations);
