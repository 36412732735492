import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { configState } from "../Config";
import { withStyles } from "@material-ui/core/styles";
import { CountryCodes } from "../Constants.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
var serialize = require("form-serialize");

/* Style */
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: 30,
    marginTop: 20,
    marginBottom: 100,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

/* Class definition */
class RemapDetail extends React.Component {
  state = {
    remap_id: 0,
    handleSave: this.props.handleSave,
    remap_row: this.props.remap_row,
    dataSource: [],
    record: null,
    tableRefreshed: this.props.tableRefreshed,
    scrollToBottom: this.props.scrollToBottom
  };

  /*
   **
   ** handleSubmit
   **
   */
  handleSubmit = (country) => (event) => {
    event.preventDefault();
    var form = event.target;
    var data = serialize(form, { empty: true, hash: false });

    if (country !== [])
      data += "&countryname=" + country;
    console.log(data);
    this.setState({
      status: "SAVING...",
    });

    var url =
      configState.apiBase +
      "remaps?function=update&remap_id=" +
      this.state.remap_id;
    console.log("Saving:" + url);
    try {
      fetch(url, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
        body: data,
      }).then((response) => {
        response.text().then((responseText) => {
          this.setState({
            status: "Saved.",
            remap_id: 0,
          });

          // Call the parent save routine in case it needs to refresh
          if (this.state.handleSave != null) {
            console.log("Calling handle save");
            this.state.handleSave();
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  /*
   ** executeQuery - get data from API
   */
  async executeQuery(remap_id) {
    var url = configState.apiBase + "remaps?function=search";
    if (remap_id !== "") url += "&remap_id=" + remap_id;
    console.log("Loading: " + url);
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows ? responseJson.rows : [], // Full dataset
          record: responseJson.rows ? responseJson.rows[0] : null, // But only one record in this context
        },
        function () {
          // do something with new state
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  /*
   ** executeAdd - get data from API
   */
  async executeAdd() {
    var url = configState.apiBase + "remaps?function=insert";
    console.log("Add: " + url);
    try {
      const response = await fetch(url);
      const responseJson = await response.json();

      const newRecord = this.state.remap_row;
      if (newRecord && responseJson.rows) {
        newRecord.remap_id = responseJson.rows[0].remap_id;
        newRecord.created = responseJson.rows[0].created;
        newRecord.updated = responseJson.rows[0].updated;
      }

      var data = "";
      for (const key in newRecord) {
        if (!newRecord[key].trim || newRecord[key].trim() !== "")
          data += key + "=" + newRecord[key] + "&";
      }
      data = data.trim("&");

      this.setState({
        status: "SAVING...",
      });

      url =
        configState.apiBase +
        "remaps?function=update&remap_id=" +
        this.state.remap_id;
      console.log("Saving:" + url);
      fetch(url, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
        body: data,
      });

      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows, // Full dataset
          record:
            newRecord || (responseJson.rows ? responseJson.rows[0] : null), // But only one record in this context
          remap_id: responseJson.rows[0].remap_id,
        },
        function () {
          this.props.tableRefreshed(true);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }


  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps) {
    // If nothing important changed, we're done.
    if (prevProps.remap_id === this.props.remap_id) return;

    console.log("Got new props remap_id:" + this.props.remap_id);

    if (this.props.remap_id > 0) {
      this.executeQuery(this.props.remap_id);
    } else if (this.props.remap_id === -1) {
      // Add a new record
      this.executeAdd();
    }

    this.setState({
      remap_id: this.props.remap_id, // remap_id parameter
      remap_row: this.props.remap_row, // remap_id parameter
    });

    if (!this.state.remap_id || !this.state.record)
      setTimeout(this.state.scrollToBottom, 500);
  }

  /*
   ** handleChange - handle the change in a text field, to make it permanent
   */
  handleChange = (name) => (event, data) => {
    let newRec = this.state.record;
    if (name === "countryname") newRec.countryname = data.join(",");
    else newRec[name] = event.target.value;
    this.setState({ record: newRec });
  };

  cancelForm = () => {
    this.setState({
      remap_id: 0,
    });

    if (this.state.handleSave != null) {
      console.log("Calling handle save");
      this.state.handleSave();
    }
  };

  /*
   ** render
   */
  render() {
    const { classes } = this.props;

    if (!this.state.remap_id || !this.state.record) return <div />;
    var row = this.state.record;

    const countryArray = row.countryname
      ? row.countryname.split(",").filter((e) => e.trim())
      : [];
    return (
      <div key="div{row.remap_id}">
        <Divider variant="middle" />
        <Typography variant="h4">Details</Typography>

        <div key="{row.remap_id}">
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
            onSubmit={this.handleSubmit(countryArray)}
          >
            <Grid container>
              <TextField
                id="remap_id"
                label="remap_id"
                name="remap_id"
                type="Number"
                className={classes.textField}
                value={row.remap_id}
                onChange={this.handleChange("remap_id")}
                margin="normal"
                disabled
              />

              <TextField
                id="oldmovie"
                name="oldmovie"
                label="Old Movie ID"
                value={row.oldmovie}
                onChange={this.handleChange("oldmovie")}
                margin="normal"
                className={classes.textField}
              />

              <TextField
                id="oldname"
                label="Old Movie Name"
                className={classes.textField}
                value={row.oldname}
                onChange={this.handleChange("oldname")}
                margin="normal"
                disabled
              />

              <TextField
                id="newmovie"
                name="newmovie"
                label="New Movie ID"
                className={classes.textField}
                value={row.newmovie}
                onChange={this.handleChange("newmovie")}
                margin="normal"
                type="Number"
                fullWidth
              />

              <TextField
                id="newname"
                label="New Movie Name"
                className={classes.textField}
                value={row.newname}
                onChange={this.handleChange("newname")}
                margin="normal"
                disabled
              />

              <TextField
                id="houseid"
                name="houseid"
                label="House ID"
                className={classes.textField}
                value={row.houseid}
                onChange={this.handleChange("houseid")}
                margin="normal"
              />

              <TextField
                id="housename"
                label="House Name"
                className={classes.textField}
                value={row.housename}
                onChange={this.handleChange("housename")}
                margin="normal"
                disabled
              />

              <TextField
                id="chainid"
                label="Chain ID"
                name="chainid"
                className={classes.textField}
                value={row.chainid}
                onChange={this.handleChange("chainid")}
                margin="normal"
                type="Number"
              />

              <TextField
                id="chainname"
                label="Chain Name"
                className={classes.textField}
                value={row.chainname}
                onChange={this.handleChange("chainname")}
                margin="normal"
                disabled
              />

              <Autocomplete
                multiple
                className={classes.textField}
                id="countryname"
                options={Object.keys(CountryCodes)}
                filterSelectedOptions
                onChange={this.handleChange("countryname")}
                value={countryArray}
                renderInput={(params) => (
                  <TextField
                    className={classes.searchTextField}
                    {...params}
                    variant="outlined"
                    margin="normal"
                    label="Country"
                    placeholder="Pick Country Name"
                  />
                )}
              />

              <TextField
                id="showdate"
                name="showdate"
                label="Start Date"
                className={classes.textField}
                value={row.showdate}
                onChange={this.handleChange("showdate")}
                margin="normal"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  max: row.showends,
                }}
              />
              <TextField
                id="showends"
                name="showends"
                label="End Date"
                className={classes.textField}
                value={row.showends}
                onChange={this.handleChange("showends")}
                margin="normal"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: row.showdate,
                }}
              />
              <TextField
                id="notes"
                name="notes"
                label="Notes"
                fullWidth
                value={row.notes}
                onChange={this.handleChange("notes")}
                margin="normal"
              />

              <TextField
                id="created"
                label="Created"
                className={classes.textField}
                value={row.created ? row.created.substring(0, 16) : undefined}
                margin="normal"
                disabled
              />

              <TextField
                id="updated"
                label="Updated"
                className={classes.textField}
                value={row.updated ? row.updated.substring(0, 16) : undefined}
                margin="normal"
                disabled
              />

              <Divider width="100%" />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="default"
                onClick={this.cancelForm}
                className={classes.button}
              >
                Cancel
              </Button>
            </Grid>
          </form>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(RemapDetail);
