import React from "react";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";

//data is an array of arrays, nested arrays should have two values the first being the display name, the second the data name
//classes should just be the classes of he parent object used for css
//container should be a refrence to the parent object
export default function AdvancedSearchTextFields(data, classes, container) {


  var dataFormatted = data.map((datum) => (
    datum[2] === "checkbox" ?

      <div key={datum[1]} className={classes.searchTextFieldBox}>
        <h3>{datum[0]}</h3>
        <Checkbox
          id={datum[1]}
          placeholder={"Enter " + datum[0]}
          onKeyPress={container.enterPressed.bind(container)}
          className={classes.searchCheckBox}
          checked={Boolean(container.state.searchData[datum[1]]) || false}
          onChange={container.handleChange(datum[1])}
          margin="normal"
          variant="outlined"
        />
      </div>
      :
      <div key={datum[1]} className={classes.searchTextFieldBox}>
        <h3>{datum[0]}</h3>
        <TextField
          id={datum[1]}
          placeholder={"Enter " + datum[0]}
          onKeyPress={container.enterPressed.bind(container)}
          className={classes.searchTextField}
          value={container.state.searchData[datum[1]] || ""}
          onChange={container.handleChange(datum[1])}
          margin="normal"
          variant="outlined"
          type={datum[2] ? datum[2] : "text"}
        />
      </div>
  ));

  return <React.Fragment>{dataFormatted}</React.Fragment>;
}
