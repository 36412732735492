import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import { configState } from "../Config";
var component = this;

/*
 **
 ** QueueTable
 **
 */
export class QueueTable extends React.Component {
  /*
   ** constructor
   **/
  constructor(props) {
    super(props);
    this.state = {
      search: this.props.search, // search parameter, text
      queue_id: this.props.queue_id, // queue_id
      selectedQueue: 0, // selected queue for detail section
      onSelect: this.props.onSelect, // callback for selection, return code or queue_id
      isLoading: true,
      dataSource: [],
      filterEmptyQueues: true,
    };

    this.redoQuery = this.redoQuery.bind(this);
    this.redoQuery(this.props.queue_id, this.props.search);
    component = this;
  }

  /*
   ** Button click
   */
  handleClickOpen(queueId) {
    if (queueId !== this.state.selectedQueue) {
      this.setState({ selectedQueue: queueId });
    }
  }

  // Return from modal
  handleCloseModal = () => {
    this.setState({ selectedQueue: 0 });
  };

  /*
   ** redoQuery - query from AJAX
   */
  async redoQuery(queue_id, search) {
    var url = "";
    url = configState.apiBase + "incoming?function=search";
    if (search !== "" && search !== "undefined") {
      url += "&wildcard=" + search;
    }
    if (queue_id !== "" && queue_id !== "undefined") {
      url += "&incoming_id=" + queue_id;
    }
    console.log("Query URL: " + url);
    return fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState(
          {
            isLoading: false,
            dataSource: responseJson.rows == null ? [] : responseJson.rows,
          },
          function () {
            // do something with new state
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    // if nothing important changed, we're done.
    if (
      prevProps.search === this.props.search &&
      prevProps.queue_id === this.props.queue_id
    ) {
      return;
    }

    this.setState({
      search: this.props.search, // search parameter
      queue_id: this.props.queue_id, // queue_id parameter
      onSelect: this.props.onSelect, // callback for selection
      isLoading: true,
      dataSource: [],
    });

    // New props, have to rerun query
    this.redoQuery(this.props.queue_id, this.props.search);
  }

  /*
   ** componentDidMount - when first mounting, do query, which is probably empty
   */
  componentDidMount() {
    this.redoQuery("", "");
    this.interval = setInterval(
      () => this.redoQuery(this.state.queue_id, this.state.search),
      5000
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  /*
   ** executeDelete
   */
  async executeDelete(rowId) {
    var url = configState.apiBase + "incoming?function=delete";
    url += "&incoming_id=" + rowId;
    console.log("QueueTable: Deleting: " + url);
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows,
        },
        function () {
          // do something with new state
        }
      );
    } catch (error) {
      console.error("QueueTable: " + error);
    }
  }

  /*
   ** handleDelete
   */
  handleDelete = (event, rowId) => {
    console.log("QueueTable: Delete: " + rowId);
    this.executeDelete(rowId);
  };

  filterEmptyQueues = (event) => {
    this.setState({
      filterEmptyQueues: !this.state.filterEmptyQueues,
    });
  };

  rowColor = (queued) => {
    if (queued === 0) return "#000000";
    else return "#ff2020";
  };

  /*
   ** render
   */
  render() {
    // sort by value
    this.state.dataSource.sort(function (a, b) {
      return b.queued - a.queued;
    });

    // Still loading, show animation
    if (this.state.isLoading) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <span className="glyphicon-left glyphicon glyphicon-refresh spinning"></span>
        </div>
      );
    } else if (
      this.state.dataSource.length === 0 &&
      (this.state.search !== "" || this.state.queue_id !== "")
    ) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <h4>No Results</h4>
        </div>
      );
    } else {
      /* Queue table */
      return (
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right">ID</TableCell>
                <TableCell>Directory</TableCell>
                <TableCell>Delete</TableCell>
                <TableCell>
                  Queued
                  <Checkbox
                    checked={this.state.filterEmptyQueues}
                    onChange={this.filterEmptyQueues}
                    title="Hide Empty Queues"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dataSource
                .filter((a) => !this.state.filterEmptyQueues || a.queued !== 0)
                .map((row) => {
                  return (
                    <TableRow
                      key={row.incoming_id}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell align="right" component="th" scope="row">
                        {row.incoming_id}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          component.handleClickOpen(row.incoming_id);
                        }}
                      >
                        {row.directory}
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        <DeleteIcon
                          onClick={(event) =>
                            window.confirm("Are you sure?") &&
                            this.handleDelete(event, row.incoming_id)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {row.queued}{" "}
                        <Typography
                          component="div"
                          style={{
                            backgroundColor: this.rowColor(row.queued),
                            width: row.queued + 1,
                            height: "20px",
                          }}
                        />{" "}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      );
    } // not isLoading
  } // render
} // QueueTable.js
