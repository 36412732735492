import React from "react";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";

import { configState } from "../../Config";
import { styles } from "../CsStyles";
import SearchBar from "../SearchBar/SearchBar";
import TheatersWithShowtimesTable from "./TheatersWithShowtimesTable";

/*
 ** TheatersWithShowtimes - a page for accessing TheatersWithShowtimes
 */
class TheatersWithShowtimes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchData: {},
            queryUrl: "",
        };

    }

    /*
     **
     ** fetchData
     **
     */
    handleSearchCriteria = (data) => () => {
        if (data) {
            if (data.main !== undefined && data.main !== "") {
                data["house_id"] = data.main;
            }

            let newData = { ...data };

            if (newData.country !== undefined)
                newData.country = newData.country.join(",");

            let url = configState.apiBase + "report?function=search";

            for (let key in newData) {
                if ((key === "country" && newData.country === "")
                    || key === "main") continue;

                if (!url.endsWith("?")) url += "&";

                url += key + "=" + newData[key];
            }
            this.setState({
                queryUrl: url,
            });
        }
    };

    /*
     ** render
     **
     */
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Box>
                    <Box className={classes.advancedSearch} noValidate autoComplete="off">
                        <SearchBar
                            {...this.props}
                            onSave={this.handleSearchCriteria}
                            searchCriteria={[
                                ["House Id", "house_id"],
                                ["Chain Id", "chain_id"],
                                ["Start Date", "startdate", "date"],
                                ["End Date", "enddate", "date"],
                            ]}
                            searchData={this.state.searchData}
                            mainField={"house_id"}
                            show_country
                        />
                    </Box>

                    <Box className={classes.appBarSpacer} />

                    <TheatersWithShowtimesTable
                        queryUrl={this.state.queryUrl} />
                </Box >
            </React.Fragment>
        );
    }
}

TheatersWithShowtimes.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TheatersWithShowtimes);
