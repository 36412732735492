import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

class Messages extends React.Component {
  state = {
    open: false,
    dataSource: []
  };

  // handleClose
  handleClose = () => {
    this.setState({
      open: false
    });
    this.props.onClose();
  };

  /*
   ** executeQuery - get data from API
   */
  async executeQuery() { }

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    // If nothing important changed, we're done.
    if (this.props.open !== prevProps.open)
      this.setState({ open: this.props.open });
  }

  /*
   ** render
   */
  render() {

    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Notifications"}
          </DialogTitle>
          <DialogContent>
            <b>No new messages.</b>
            <br></br>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Messages;
