import React from "react";
import Checkmark from "@material-ui/icons/Check";

function Check(bVal) {
  if (bVal) return <Checkmark style={{ verticalAlign: "middle" }}></Checkmark>;
  else return " ";
}

export default function SearchDetailSection(row, labels, title, icon = null) {
  let keys = Object.keys(labels);
  var i;
  for (i = 0; i < keys.length; i++) {
    if (row[keys[i]]) {
      return (
        <React.Fragment>
          <h2>
            <div>
              {icon} {icon ? "\u00A0" : null} {title}
            </div>
          </h2>
          {keys.map((dataName) =>
            row[dataName] ? (
              labels[dataName] != null ? (
                <div key={dataName} style={{display:"block", clear: "both"}}>
                  <span style={{ float: "left", width:"30%" }}>
                    {labels[dataName]}
                  </span>
                  <span style={{float:"right", textAlign:"right", width:"70%", wordWrap: "break-word"}}>
                    {typeof row[dataName] === "boolean"
                      ? Check(row[dataName])
                      : row[dataName]}
                  </span>
                  <br />
                </div>
              ) : (
                row[dataName]
                  .toString()
                  .split(", ")
                  .map((data) => (
                    <React.Fragment key={data}>
                      <span style={{ width: 400, display: "inline-block", clear: "both" }}>
                        {data}
                      </span>
                      <br />
                    </React.Fragment>
                  ))
              )
            ) : null
          )}
          <hr style={{clear: "both"}}/>
        </React.Fragment>
      );
    }
  }

  return "";
}
