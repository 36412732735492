import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import SearchDetailSection from "./SearchDetailSection";
import { configState } from "../Config";
import { BoxofficeRed } from "./CsStyles";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

class MiniMovieDetails extends React.Component {
  state = {
    open: this.props.open,
    movie_id: this.props.movie_id,
    poster: "",
    country: this.props.country,
    dataSource: [],
    onClose: this.props.onClose,
    expanded: false,
    images: [],
    imageLoadError: true,
  };

  /*
   ** executeQuery - get data from API
   */
  async executeQuery(movie_id, country) {
    var url = configState.apiBase + "movies?function=search";
    if (movie_id !== "") url += "&movie_id=" + movie_id;
    if (country !== "" && country !== undefined) url += "&country=" + country;
    console.log("Loading: " + url);
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows,
        },
        function () {
          console.log(this.state.dataSource);
          // do something with new state
          if (this.state.dataSource.length > 0)
            if (
              this.state.dataSource[0].poster !== null
            ) {
              this.setState({ poster: this.state.dataSource[0].poster });
            }
        }
      );
    } catch (error) {
      console.error(error);
    }

    url = configState.apiBase + "photos?function=search";
    if (movie_id !== "") url += "&movie_id=" + movie_id;
    if (country !== "" && country !== undefined) {
      url += "&country=" + country;
    } else {
      url += "&country=USA";
    }

    console.log("Loading: " + url);
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      this.setState(
        {
          isLoading: false,
          imageLoadError: true,
          images: responseJson.rows == null ? [] : responseJson.rows,
        },
        function () {
          // do something with new state
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    // If nothing important changed, we're done.
    if (
      prevProps.movie_id === this.props.movie_id &&
      prevProps.country === this.props.country &&
      prevProps.open === this.props.open
    )
      return;

    if (this.props.movie_id > 0 && this.props.open) {
      this.setState({ open: true });
      this.executeQuery(this.props.movie_id, this.props.country);
    } else {
      this.setState({ open: false });
    }
  }
  /*
   ** render
   */
  render() {
    const imgSrc = "https://www.movienewsletters.net/photos/";

    if (this.state.dataSource.length > 0) {
      var Movie = this.state.dataSource[0];
      return (
        <Dialog
          open={this.state.open}
          onClose={this.state.onClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth="sm"
        >
          <div
            style={{
              backgroundColor: BoxofficeRed,
              padding: "1em",
              display: "inline-block",
            }}
          >
            <div style={{ width: "48%", float: "left" }}>
              <img
                style={{ display: "inline-block", maxWidth: "100%" }}
                src={this.state.poster}
                alt={"Movie Poster"}
                onError={(e) => {
                  if (this.state.imageLoadError) {
                    this.setState({
                      imageLoadError: false,
                    });
                    e.target.src = imgSrc + "000000H1.jpg";
                  }
                }}
              />
            </div>
          </div>

          <DialogContent>
            <div>
              {SearchDetailSection(
                Movie,
                {
                  release: null,
                  release2: null,
                  release3: null,
                },
                "Releases",
                <CalendarTodayIcon />
              )}
            </div>
          </DialogContent>
        </Dialog>
      );
    } else {
      return <div></div>;
    }
  }
} // end compoonent

export default MiniMovieDetails;
