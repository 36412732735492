export const CountryCodes = {
"USA":"United States of America",
"FRA":"France",
"GBR":"Great Britain",
"SCO":"Scotland",
"CAN":"Canada",
"ENG":"England",
"ABW":"Aruba",
"AGO":"Angola",
"AND":"Andorra",
"ARG":"Argentina",
"ATG":"Antigua",
"AUS":"Australia",
"AUT":"Austria",
"AZE":"Azerbaijan",
"BEL":"Belgium",
"BES":"Bonaire",
"BGD":"Bangladesh",
"BGR":"Bulgaria",
"BHR":"Bahrain",
"BHS":"The Bahamas",
"BIH":"Bosnia and Herzegovina",
"BMU":"Bermuda",
"BOL":"Bolivia",
"BRA":"Brazil",
"CHE":"Switzerland",
"CHL":"Chile",
"CHN":"China",
"CIV": "Ivory Coast",
"COL":"Columbia",
"CRI":"Costa Rica",
"CUW":"Curaco",
"CYM":"Cayman Islands",
"CYP":"Cyprus",
"CZE":"Czechia",
"DEU":"Germany",
"DNK":"Denmark",
"DOM":"Dominican Republic",
"DZA":"Algier",
"ECU":"Ecuador",
"EGY":"Egypt",
"ESP":"Spain",
"EST":"Estonia",
"FIN":"Finland",
"FJI":"Fiji",
"GEO":"Georgia",
"GIB":"Gibraltar",
"GRC":"Greece",
"GTM":"Guatemala",
"GUF":"French Guiana",
"HKG":"Hong Kong",
"HND":"Honduras",
"HRV":"Croatia",
"HUN":"Hungary",
"IDN":"Indonesia",
"IND":"India",
"IRE":"Southern Ireland",
"IRQ":"Iraq",
"ISL":"Iceland",
"ISR":"Israel",
"ITA":"Italy",
"JOR":"Jordan",
"JPN":"Japan",
"KAZ":"Kazakhstan",
"KEN":"Kenya",
"KGZ":"Kyrgyzstan",
"KHM":"Cambodia",
"KOR":"Korea",
"KWT":"Kuwait",
"KZ" :"Kazakhstan",
"LBN":"Lebanon",
"LCA":"Saint Lucia",
"LUX":"Luxembourg",
"LVA":"Latvia",
"MAC":"Macau",
"MAR":"Marrakesh",
"MAX":"China",
"MCO":"Monaco",
"MEX":"Mexico",
"MKD":"North Macedonia",
"MLT":"Malta",
"MNE":"Montenegro",
"MNG":"Mongolia",
"MYS":"Malaysia",
"NGA":"Nigeria",
"NIC":"Nicaragua",
"NIR":"Northern Ireland",
"NLD":"Netherlands",
"NOR":"Norway",
"NZL":"New Zeland",
"OMN":"Oman",
"OOO":"Test",
"PAK":"Pakistan",
"PAN":"Panama",
"PER":"Peru",
"PHL":"Philipines",
"PNG":"Papau New Guinea",
"POL":"Poland",
"POR":"Portugal",
"PRY":"Paraguay",
"QAT":"Qatar",
"ROU":"Romania",
"RUS":"Russia",
"SAU":"Saudi Arabia",
"SGP":"Singapore",
"SHN":"Shanghai",
"SLV":"El Salvador",
"SRB":"Serbia",
"SVK":"Slovakia",
"SVN":"Slovenia",
"SWE":"Sweden",
"SXM":"Sint Maarten",
"THA":"Thailand",
"TTO":"Trinidad and Tobago",
"TUN":"Tunisia",
"TUR":"Turkey",
"TWN":"Taiwan",
"UAE":"United Arab Emirates",
"UKR":"Ukraine",
"URY":"Uruguay",
"VEN":"Venezuela",
"VNM":"Vietnam",
"WAL":"Wales",
"ZAF":"South Africa",
};