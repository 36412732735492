import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { styles } from './CsStyles';


class NlConversions extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            open: false,
        };

        if (this.props.setTitle != null)
            this.props.setTitle("Newsletter Conversions");

    }


  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
  
       
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Typography variant="display1" gutterBottom>
              Newsletter Conversions
            </Typography>
            <Typography component="div" className={classes.chartContainer}>
               
            </Typography>
            <Typography variant="display1" gutterBottom>
            Newsletter Conversions
            </Typography>
            <div className={classes.tableContainer}>
               
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

NlConversions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NlConversions);