import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { configState } from "../Config";
import { createSortHandler } from "../components/TableSortHelperMethods";

/*
 **
 ** MovieWidgetTable
 **
 */
export default class MovieWidgetTable extends React.Component {
  state = {
    search: this.props.search, // search parameter, text
    house_id: this.props.house_id, // search parameter, house_id
    onSelect: this.props.onSelect, // callback for selection, return code or house_id
    isLoading: true,
    remap_id: 0, // remap_id if an edit is in process
    orderBy: "remap_id",
    order: "asc",
    page: 0,
    rowsPerPage: 10,
    dataSource: [],
  };

  /*
   ** componentDidMount
   */
  async componentDidMount() {
    // Run the initial query
    this.executeQuery(this.props.house_id, this.props.search);
  }

  /*
   ** Paginators
   */
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  /*
   ** executeQuery - get data from API
   */
  executeQuery = async (houseId, search) => {
    var url = configState.apiBase + "remaps?function=search";
    if (search !== "") url += "&wildcard=" + search;
    if (houseId !== "" && houseId > 0) url += "&house_id=" + houseId;
    console.log("MovieWidgetTable: Loading: " + url);
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows,
        },
        function () {
          // do something with new state
        }
      );
    } catch (error) {
      console.error("MovieWidgetTable: " + error);
    }
  };

  /*
   ** executeDelete
   */
  async executeDelete(remapId) {
    var url = configState.apiBase + "remaps?function=delete";
    url += "&remap_id=" + remapId;
    console.log("MovieWidgetTable: Deleting: " + url);
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows,
        },
        function () {
          // do something with new state
        }
      );
    } catch (error) {
      console.error("MovieWidgetTable: " + error);
    }
  }

  /*
   ** handleRowSelected
   */
  handleRowSelected = (event, remapId) => {
    if (remapId !== this.state.remap_id) {
      this.setState({ remap_id: remapId });

      // Call the callback
      if (this.state.onSelect != null) {
        this.state.onSelect(remapId);
      }
    }
  };

  /*
   ** handleDelete
   */
  handleDelete = (event, remapId) => {
    console.log("RemapTable: Delete: " + remapId);
    this.executeDelete(remapId);
  };

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    console.log("MovieWidgetTable: got new props");

    // New remap id, save that
    if (prevProps.remap_id !== this.props.remap_id) {
      this.setState({ remap_id: this.props.remap_id });
    }

    // If nothing important changed, we're done.
    if (
      prevProps.search === this.props.search &&
      prevProps.house_id === this.props.house_id &&
      prevProps.remap_id === this.props.remap_id
    ) {
      return;
    }

    // Jump to last page
    if (this.props.remap_id === -1) {
      console.log("MovieWidgetTable: Add new record, jump to last page");
      var newPage = prevState.dataSource.length / prevState.rowsPerPage + 1; // Number of pages.
      this.setState({
        page: newPage, // Page number
      });
    }

    // If the remap_id went to zero but was NOT zero before, we'll need to requery
    if (
      !(this.props.remap_id === 0 && prevProps.remap_id !== 0) &&
      prevProps.search === this.props.search &&
      prevProps.house_id === this.props.house_id
    ) {
      return;
    }

    // Something important changed in search params. Kick it off.
    this.setState({
      search: this.props.search, // search parameter
      house_id: this.props.house_id, // house_id parameter
      onSelect: this.props.onSelect, // callback for selection
      isLoading: true,
      dataSource: [],
    });

    // Requery
    this.executeQuery(this.props.house_id, this.props.search);
  }

  isSelected = (id) => {
    if (id === this.state.remap_id) {
      return true;
    } else {
      return false;
    }
  };

  /*
   ** render
   */
  render() {
    // Still loading, show animation
    if (this.state.isLoading) {
      return <CircularProgress />;
    } else if (this.state.dataSource.length === 0) {
      return <div style={{ flex: 1, paddingTop: 20 }}></div>;
    } else {
      /* Remap table */
      return (
        <div>
          <Table style={{ flex: 1, marginLeft: 20 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "remap_id"}
                    direction={this.state.order}
                    onClick={createSortHandler("remap_id", this)}
                  >
                    RemapId
                  </TableSortLabel>
                </TableCell>

                <TableCell align="right">
                  <TableSortLabel
                    active={this.state.orderBy === "oldmovie"}
                    direction={this.state.order}
                    onClick={createSortHandler("oldmovie", this)}
                  >
                    OldMovieID
                  </TableSortLabel>
                </TableCell>

                <TableCell>OldMovieTitle</TableCell>

                <TableCell align="right">
                  <TableSortLabel
                    active={this.state.orderBy === "newmovie"}
                    direction={this.state.order}
                    onClick={createSortHandler("newmovie", this)}
                  >
                    NewMovieID
                  </TableSortLabel>
                </TableCell>

                <TableCell>NewMovieTitle</TableCell>
                <TableCell align="right">HouseId</TableCell>
                <TableCell>HouseName</TableCell>
                <TableCell align="right">ChainId</TableCell>
                <TableCell>Chainame</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Showdate</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.dataSource
                .slice(
                  this.state.page * this.state.rowsPerPage,
                  this.state.page * this.state.rowsPerPage +
                  this.state.rowsPerPage
                )
                .map((row) => {
                  const isSelected = this.isSelected(row.remap_id);
                  return (
                    <TableRow key={row.remap_id} selected={isSelected}>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.remap_id}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                        align="right"
                      >
                        {row.oldmovie}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.oldname}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                        align="right"
                      >
                        {row.newmovie}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.newname}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                        align="right"
                      >
                        {row.houseid}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.housename}
                      </TableCell>
                      <TableCell align="right">{row.chainid}</TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.chainname}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.countryname}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.showdate}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          this.handleRowSelected(event, row.remap_id)
                        }
                      >
                        {row.notes}
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        <DeleteIcon
                          onClick={(event) =>
                            window.confirm("Are you sure?") &&
                            this.handleDelete(event, row.remap_id)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5]}
                  colSpan={3}
                  count={this.state.dataSource.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      );
    } // not isLoading
  } // render
} // MovieWidgetTable.js
