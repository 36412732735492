import React, { Fragment, useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { configState } from "../../Config";
import Wrapper from "./Wrapper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import querystring from "querystring";

const Users = ({ setTitle }) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState();
  const [updatingIds, setUpdatingIds] = useState([]);
  const [addingUser, setAddingUser] = useState(false);
  const [newUserName, setNewUserName] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    fetch(`${configState.apiBase}/api_user?${querystring.encode({
      function: "search",
    })}`)
      .then((results) => results.json())
      .then((results) => {
        setUsers(results);
      }).catch(() => {
        setUsers([]);
        setError("Error loading users");
      }).finally(() => { 
        setLoading(false);
      })
  }, []);

  useEffect(() => {
    setTitle("API Users");
  }, [setTitle]);

  const addUser = () => {
    setAddingUser(true);
    setError(undefined);
    fetch(`${configState.apiBase}/api_user?${querystring.encode({
      function: "insert",
      name: newUserName,
    })}`)
      .then((results) => results.json())
      .then((results) => {
        if (results.error) {
          setError(results.error);
        } else {
          setUsers([
            ...users,
            results
          ]);
          setNewUserName(undefined);
        }
      }).catch(() => {
        setError("Error adding user");
      }).finally(() => { 
        setAddingUser(false);
      })
  }

  const updateUser = (id, hasRestrictions) => {
    setUpdatingIds([...updatingIds, id]);
    setError(undefined);
    fetch(`${configState.apiBase}/api_user?${querystring.encode({
      function: "update",
      id,
      hasRestrictions: hasRestrictions ? "1" : "0",
    })}`)
      .then((results) => results.json())
      .then((results) => {
        if (results.error) {
          setError(results.error);
        } else {
          const nextData = users;
          const index = nextData.findIndex((user) => user.id === id);
          nextData[index] = results;
          setUsers(nextData);
        }
      }).catch(() => {
        setError("Error updating user");
      }).finally(() => { 
        setUpdatingIds(updatingIds.filter((upId) => upId !== id));
      })
  }
  
  return (
    <Wrapper title="API Users" loading={loading}>
      {error && <h3>{error}</h3>}
      <div style={{ display: "grid", gap: "1em" }}>
        {users.map((user) => (
          <div 
            key={user.id} 
            style={{ 
              alignItems: "center",
              borderBottom: "1px solid gray",
              display: "grid", 
              gap: "0.5em",
              gridTemplateAreas: "'name token checkbox' 'links links links'",
              gridTemplateColumns: "6em 1fr auto",
              opacity: updatingIds.includes(user.id) ? "0.5" : "1",
              padding: "1em 0",
              wordBreak: "break-word",
            }}
          >
            <div style={{ fontSize: "1.125em", fontStyle: "italic", gridArea: "name" }}>{user.name}</div>
            <div
              style={{
                backgroundColor: "#212529",
                borderRadius: "3px",
                color: "#DD8B2C",
                fontSize: "0.875em",
                gridArea: "token",
                padding: "0.25em",
              }}
            >
              {user.token}
            </div>
            <div style={{ gridArea: "checkbox"}}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={"hasRestrictions"}
                    checked={user.hasRestrictions}
                    onChange={(_, checked) => {
                      updateUser(user.id, checked);
                    }}
                    disabled={updatingIds.includes(user.id)}
                  />
                }
                label="Restricted"
                labelPlacement="left"
              />
            </div>
            <div 
              style={{ 
                display: "flex",
                gap: "1em",
                gridArea: "links",
              }}
            >
              {user.hasRestrictions && (
                <Fragment>
                  Manage restrictions
              <Link
                to={`/api/countries/${user.id}`}
              >
                Countries
              </Link>
              <Link
                to={`/api/movies/${user.id}`}
              >
                Movies
              </Link>
                </Fragment>
              )}
            </div>
          </div>
        ))}
      </div>

      <div style={{alignItems: "center", display: "flex", gap: "1em"}}>
        <Button 
          onClick={() => {
            if (newUserName !== undefined) {
              addUser();
            } else {
              setNewUserName("");
            }
          }} 
          color="primary"
          disabled={addingUser}
        >
          {newUserName ? "Save" : "Add new user"}
        </Button>
      
        {newUserName !== undefined && (
          <TextField
            label="Name"
            name="name"
            type="text"
            value={newUserName}
            onChange={(e) => {
              setNewUserName(e.target.value);
            }}
            margin="normal"
          />
        )}
      </div>
    </Wrapper>
  )
};

export default Users;