import React, { Fragment, useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { configState } from "../../Config";
import Wrapper from "./Wrapper";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { CountryCodes } from "../../Constants";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DeleteIcon from "@material-ui/icons/Delete";
import querystring from "querystring";

const Countries = ({ setTitle, match }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [userCountries, setUserCountries] = useState([]);
  const [error, setError] = useState();
  const [updatingIds, setUpdatingIds] = useState([]);
  const [addingUser, setAddingUser] = useState(false);
  const [newCountryCode, setNewCountryCode] = useState(undefined);
  const [deleteId, setDeleteId] = useState(undefined);

  useEffect(() => {
    fetch(`${configState.apiBase}/api_user?${querystring.encode({
      function: "search",
      id: match.params.idUser,
    })}`)
      .then((results) => results.json())
      .then((results) => {
        if (results.length) {
          setName(results[0].name);
        }
      })

    setLoading(true);
    fetch(`${configState.apiBase}/api_user_country?${querystring.encode({
      function: "search",
      idUser: match.params.idUser,
    })}`)
      .then((results) => results.json())
      .then((results) => {
        setUserCountries(results);
      }).catch(() => {
        setUserCountries([]);
        setError("Error loading userCountries");
      }).finally(() => { 
        setLoading(false);
      })
  }, [match]);

  useEffect(() => {
    setTitle("API User Countries");
  }, [setTitle]);

  
  const addCountry = () => {
    setAddingUser(true);
    setError(undefined);
    fetch(`${configState.apiBase}/api_user_country?${querystring.encode({
      function: "insert",
      idUser: match.params.idUser,
      countryCode: newCountryCode,
    })}`)
      .then((results) => results.json())
      .then((results) => {
        if (results.error) {
          setError(results.error);
        } else {
          setUserCountries([
            ...userCountries,
            results
          ]);
          setNewCountryCode(undefined);
        }
      }).catch(() => {
        setError("Error adding user country");
      }).finally(() => { 
        setAddingUser(false);
      })
  }

  const updateUserCountry = (id, hasRestrictions) => {
    setUpdatingIds([...updatingIds, id]);
    setError(undefined);
    fetch(`${configState.apiBase}/api_user_country?${querystring.encode({
      function: "update",
      id,
      hasMovieRestrictions: hasRestrictions ? "1" : "0",
    })}`)
      .then((results) => results.json())
      .then((results) => {
        if (results.error) {
          setError(results.error);
        } else {
          let nextData = userCountries;
          const index = nextData.findIndex((userCountry) => userCountry.id === id);
          nextData[index] = results;
          setUserCountries(nextData);
        }
      }).catch(() => {
        setError("Error updating user country");
      }).finally(() => { 
        setUpdatingIds(updatingIds.filter((upId) => upId !== id));
      })
  }

  const deleteUserCountry = (id) => {
    setUpdatingIds([...updatingIds, id]);
    setError(undefined);
    fetch(`${configState.apiBase}/api_user_country?${querystring.encode({
      function: "delete",
      id,
    })}`)
      .then((results) => results.json())
      .then((results) => {
        if (results.error) {
          setError(results.error);
        } else {
          setUserCountries(userCountries.filter((userCountry) => userCountry.id !== id));
        }
      }).catch(() => {
        setError("Error deleting user country");
      }).finally(() => { 
        setUpdatingIds(updatingIds.filter((upId) => upId !== id));
      })
  }

  const deleteCountry = deleteId ? userCountries.find((userCountry) => userCountry.id === deleteId) : {};
  
  return (
    <Wrapper title={`${name} country restrictions`} loading={loading}>
      {error && <h3>{error}</h3>}
      <div style={{ display: "grid", gap: "1em" }}>
        {userCountries.map((userCountry) => (
          <div 
            key={userCountry.id} 
            style={{ 
              alignItems: "center",
              borderBottom: "1px solid gray",
              display: "grid", 
              gap: "0.5em",
              gridTemplateAreas: "'name checkbox delete' 'links links links'",
              gridTemplateColumns: "1fr auto",
              opacity: updatingIds.includes(userCountry.id) ? "0.5" : "1",
              padding: "1em 0",
              wordBreak: "break-word",
            }}
          >
            <div style={{ fontSize: "1.125em", gridArea: "name" }}>
              {CountryCodes[userCountry.countryCode]}
            </div>
            <div style={{ gridArea: "checkbox"}}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={"hasMovieRestrictions"}
                    checked={userCountry.hasMovieRestrictions}
                    onChange={(_, checked) => {
                      updateUserCountry(userCountry.id, checked);
                    }}
                    disabled={updatingIds.includes(userCountry.id)}
                  />
                }
                label="Restricted by movies"
                labelPlacement="left"
              />
            </div>
            <div style={{ cursor: "pointer", gridArea: "delete" }}>
              <DeleteIcon 
                onClick={() => {
                  setDeleteId(userCountry.id)
                }}
              />
            </div>
            <div 
              style={{ 
                display: "flex",
                gap: "1em",
                gridArea: "links",
              }}
            >
              {userCountry.hasMovieRestrictions && (
                <Fragment>
                  Manage restrictions
                  <Link
                    to={`/api/movies/${match.params.idUser}/${userCountry.countryCode}`}
                  >
                    Movies
                  </Link>
                </Fragment>
              )}
            </div>
          </div>
        ))}
      </div>

      <div style={{alignItems: "center", display: "flex", gap: "1em"}}>
        <Button 
          onClick={() => {
            if (newCountryCode !== undefined) {
              addCountry();
            } else {
              setNewCountryCode("");
            }
          }} 
          color="primary"
          disabled={addingUser}
        >
          {newCountryCode ? "Save" : "Add new country"}
        </Button>
      
        {newCountryCode !== undefined && (
          <FormControl>
            <FormLabel>Country</FormLabel>
            <Select
              value={newCountryCode}
              onChange={(e) => {
                setNewCountryCode(e.target.value)
              }}
              style={{
                width: "6.25em",
              }}
              disabled={loading}
            >
              <MenuItem value="" disabled />
              {Object.keys(CountryCodes).map((key) => (
                <MenuItem
                  key={`country-option-${key}`}
                  value={key}
                >
                  {CountryCodes[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <Dialog 
        open={!!deleteId}
        onClose={() => setDeleteId(undefined)}
      >
        <DialogTitle>Delete {CountryCodes[deleteCountry.countryCode]}?</DialogTitle>
        <DialogContent>
          <span style={{ fontSize: "1.25em", fontStyle: "italic" }}>Warning! </span>
          This will delete any {CountryCodes[deleteCountry.countryCode]} movie restrictions for {name}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteId(undefined)}>Cancel</Button>
          <Button
            onClick={() => {
              deleteUserCountry(deleteId);
              setDeleteId(undefined);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  )
};

export default Countries;