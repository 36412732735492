import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import uniqueId from "lodash/uniqueId";

import Code from "./Code";

const CodesTable = ({
  codes,
  handleUpdateData,
  statuses = [],
  hasPagination = false,
  filterCode,
}) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const filteredCodes = codes.filter(
    (code) =>
      statuses.includes(code.status) &&
      (!filterCode ||
        (code.code &&
          code.code.toLowerCase().includes(filterCode.toLowerCase())) ||
        (code.external_title &&
          code.external_title
            .toLowerCase()
            .includes(filterCode.toLowerCase())) ||
        (code.internal_title &&
          code.internal_title
            .toLowerCase()
            .includes(filterCode.toLowerCase())) ||
        (code.movie_id && `${code.movie_id}` === filterCode)),
  );
  const offset = page * limit;

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>Source</TableCell>
          <TableCell>Code</TableCell>
          <TableCell>External title</TableCell>
          <TableCell>Movie ID</TableCell>
          <TableCell>Search movie</TableCell>
          <TableCell>Attributes</TableCell>
          <TableCell>Ignore</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredCodes
          .slice(
            offset,
            offset + limit > filteredCodes.length
              ? filteredCodes.length
              : offset + limit,
          )
          .map((code) => (
            <Code
              key={uniqueId("code")}
              code={code}
              handleUpdateData={handleUpdateData}
            />
          ))}
      </TableBody>
      {hasPagination && (
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              colSpan={7}
              count={filteredCodes.length}
              rowsPerPage={limit}
              page={page}
              onPageChange={(_, nextPage) => setPage(nextPage)}
              onRowsPerPageChange={(e) => setLimit(Number(e.target.value))}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

export default CodesTable;
