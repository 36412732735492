import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import { configState } from "../Config";

var component = this;

/*
 **
 ** AutoTable
 **
 */
export class AutoTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: this.props.search, // search parameter, text
      isLoading: true,
      selectedId: "",
      time: Date.now(),
      dataSource: [],
    };

    this.executeQuery = this.executeQuery.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    component = this;
  }

  /*
   ** componentDidMount - when first mounting, do query, which is probably empty
   */
  componentDidMount() {
    this.executeQuery("", "");
    this.interval = setInterval(
      () => this.executeQuery(this.state.id, this.state.search),
      5000
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  /*
   ** executeQuery - get data from API
   */
  async executeQuery(house_id, search) {
    var url = configState.apiBase + "automaters?function=search";
    if (search !== "") {
      url += "&wildcard=" + search;
    }
    if (house_id !== "") {
      url += "&id=" + house_id;
    }
    console.log("Loading: " + url);
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows,
        },
        function () {
          // do something with new state
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  /*
   ** executeDelete
   */
  async executeDelete(rowId) {
    var url = configState.apiBase + "automaters?function=delete";
    url += "&id=" + rowId;
    console.log("AutoTable: Deleting: " + url);
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows,
        },
        function () {
          // do something with new state
        }
      );
    } catch (error) {
      console.error("AutoTable: " + error);
    }
  }

  /*
   ** handleDelete
   */
  handleDelete = (event, rowId) => {
    console.log("AutoTable: Delete: " + rowId);
    this.executeDelete(rowId);
  };

  // Button click
  handleClickOpen(id) {
    if (id !== this.state.selectedId) {
      this.setState({ selectedId: id });
    }
  }

  // Return from modal
  handleCloseModal = () => {
    this.setState({ selectedId: "" });
  };

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    // if nothing important changed, we're done.
    if (
      prevProps.search === this.props.search &&
      prevProps.id === this.props.id &&
      prevProps.selectedId === this.props.selectedId
    ) {
      return;
    }

    this.setState({
      search: this.props.search, // search parameter
      id: this.props.id, // house_id parameter
      onSelect: this.props.onSelect, // callback for selection
      selectedId: this.props.selectedId,
      isLoading: true,
      dataSource: [],
    });

    // New props, have to rerun query
    this.executeQuery(this.props.id, this.props.search);
  }

  /*
   ** getTableStyle
   */
  getTableStyle(timestamp) {
    var tstyles = { backgroundColor: "white", color: "black" };
    var timeformat = timestamp.replace(" ", "T") + "Z";
    var datTime = new Date(timeformat);
    var datNow = new Date();
    datNow.setHours(datNow.getHours() - 5); // Subtract 4 hours to convert UTC to US/Eastern.

    datNow.setHours(datNow.getHours() - 1);
    if (datTime < datNow) {
      tstyles = { backgroundColor: "yellow", color: "black" };
    }

    datNow.setHours(datNow.getHours() - 1);
    if (datTime < datNow) {
      tstyles = { backgroundColor: "red", color: "white" };
    }

    return tstyles;
  }
  /*
   ** render
   */
  render() {
    // Still loading, show animation
    if (this.state.isLoading) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <span className="glyphicon-left glyphicon glyphicon-refresh spinning"></span>
        </div>
      );
    } else if (this.state.dataSource.length === 0) {
      return <div style={{ flex: 1, paddingTop: 20 }}></div>;
    } else {
      var d = new Date();
      d.setHours(d.getHours()); // Subtract 4 hours to convert UTC to US/Eastern.
      var hr = d.getHours();
      var min = d.getMinutes();
      if (min < 10) {
        min = "0" + min;
      }

      /* Database table */
      return (
        <div>
          Time: {hr}:{min}
          <br />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>AutoId</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>IP Address</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Activity</TableCell>
                <TableCell>Last Update</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dataSource.map((row) => {
                return (
                  <TableRow key={row.autoid}>
                    <TableCell padding="dense" component="th" scope="row">
                      {row.autoid}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        component.handleClickOpen(row.autoid);
                      }}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      {row.autoname}
                    </TableCell>
                    <TableCell>
                      <a href={"vnc://" + row.ipaddress}>{row.ipaddress}</a>
                    </TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.currentactivity}</TableCell>
                    <TableCell style={this.getTableStyle(row.lastupdate)}>
                      {row.lastupdate}
                    </TableCell>
                    <TableCell style={{ cursor: "pointer" }}>
                      <DeleteIcon
                        onClick={(event) =>
                          window.confirm("Are you sure?") &&
                          this.handleDelete(event, row.autoid)
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    } // not isLoading
  } // render
} // AutoTable.js
