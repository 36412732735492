import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import SearchDetailSection from "./SearchDetailSection";
import { configState } from "../Config";
import { BoxofficeRed } from "./CsStyles";

class ChainDetail extends React.Component {
  state = {
    open: this.props.open,
    chain_id: this.props.chain_id,
    chain: null,
    onClose: this.props.onClose,
    expanded: false,
    ChainData: {},
  };

  /*
   ** executeQuery - get data from API
   */
  async executeQuery(chain_id) {
    var url = configState.apiBase + "chains?function=search";
    if (chain_id !== "") url += "&chain_id=" + chain_id;
    console.log("Loading: " + url);
    try {
      const chainResponse = await fetch(url);
      const chainJSON = await chainResponse.json();

      url = configState.apiBase + "chains?function=stats";
      if (chain_id !== "") url += "&chain_id=" + chain_id;
      if (chainJSON.rows[0]["exhib_ctry"] !== "")
        url += "&country=" + chainJSON.rows[0]["exhib_ctry"];
      console.log("Loading: " + url);
      const statsResponse = await fetch(url);
      const statsJSON = await statsResponse.json();

      statsJSON.rows[0]["percenthouses"] = (parseFloat(statsJSON.rows[0]["percenthouses"]) * 100).toFixed(2);
      statsJSON.rows[0]["percentscreens"] = (parseFloat(statsJSON.rows[0]["percentscreens"]) * 100).toFixed(2);

      this.setState(
        {
          isLoading: false,
          chain: chainJSON.rows == null ? null : chainJSON.rows[0],
          ChainData: statsJSON.rows[0],
        },
        function () {
          // do something with new state
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    // If nothing important changed, we're done.
    if (
      prevProps.chain_id === this.props.chain_id &&
      prevProps.open === this.props.open
    )
      return;

    if (this.props.chain_id > 0 && this.props.open) {
      this.setState({ open: true });
      this.executeQuery(this.props.chain_id);
    } else {
      this.setState({ open: false });
    }

    this.setState({
      chain_id: this.props.chain_id, // house_id parameter
    });
  }
  handleExpand = (panel) => (event, isExpanded) => {
    this.setState({ expanded: !this.state.expanded });
  };
  /*
   ** render
   */
  render() {
    if (this.state.chain) {
      return (
        <div>
          <Dialog
            open={this.state.open}
            onClose={this.state.onClose}
            aria-labelledby="responsive-dialog-title"
            fullWidth={true}
            maxWidth="sm"
          >
            <div
              style={{
                backgroundColor: BoxofficeRed,
                padding: "1em",
                display: "inline-block",
              }}
            >
              <h1
                style={{
                  margin: "0",
                  color: "white",
                }}
              >
                {this.state.chain.name}
              </h1>
              <p
                style={{
                  margin: "0",
                  fontSize: "1.5em",
                  color: "white",
                }}
              >
                Chain ID : {this.state.chain.chain_id}
                <br />
              </p>
            </div>

            <DialogContent>
              {SearchDetailSection(
                this.state.ChainData,
                {
                  totalhouses: "House Count ",
                  totalscreens: "Screen Count ",
                  percenthouses: "House Ratio in Country ",
                  percentscreens: "Screen Ratio in Country ",
                },
                "Counts"
              )}
            </DialogContent>
          </Dialog>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
} // end compoonent

export default ChainDetail;
