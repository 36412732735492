import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import HouseDetail from "./HouseDetail";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import { createSortHandler } from "../components/TableSortHelperMethods";
import { configState } from "../Config";
import SendToWeb from "./SendToWeb";
var component = this;

/*
 **
 ** HouseTable
 **
 */
export default class HouseTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: this.props.search, // search parameter, text
      house_id: this.props.house_id, // search house_id
      chain_id: this.props.chain_id, // search chain_id
      chain_name: this.props.chain_name, // search chain_name
      altid: this.props.altid, // search chain_name
      dbzid: this.props.dbzid, // search dbz id
      city: this.props.country, // search city
      state: this.props.country, // search state
      country: this.props.country, // search country
      onSelect: this.props.onSelect, // callback for selection, return code or house_id
      isLoading: true,
      selectedHouse: 0, // house_id if we have a popup to display
      dataSource: [],
    };

    this.executeQuery = this.executeQuery.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.executeQuery(
      this.props.house_id,
      this.props.search,
      this.props.chain_id,
      this.props.chain_name,
      this.props.altid,
      this.props.dbzid,
      this.props.city,
      this.props.state,
      this.props.country
    );
    component = this;
  }

  /*
   ** componentDidMount - when first mounting, do query, which is probably empty
   */
  componentDidMount() {
    this.executeQuery("", "", "", "", "", "", "", "");
  }

  /*
   ** executeQuery - get data from API
   */
  async executeQuery(
    house_id,
    search,
    chain_id,
    chain_name,
    altid,
    dbzid,
    city,
    state,
    country
  ) {
    var url = configState.apiBase + "houses?function=search";
    if (search !== "") {
      url += "&wildcard=" + encodeURIComponent(search);
    }
    if (house_id !== "") {
      url += "&house_id=" + encodeURIComponent(house_id);
    }
    if (chain_id !== "") {
      url += "&chain_id=" + encodeURIComponent(chain_id);
    }
    if (chain_name !== "") {
      url += "&chain_name=" + encodeURIComponent(chain_name);
    }
    if (altid !== "") {
      url += "&altid=" + encodeURIComponent(altid);
    }
    if (dbzid !== "") {
      url += "&dbzid=" + encodeURIComponent(dbzid);
    }
    if (city !== "") {
      url += "&city=" + encodeURIComponent(city);
    }
    if (state !== "") {
      url += "&state=" + encodeURIComponent(state);
    }
    if (country !== "") {
      url += "&country=" + encodeURIComponent(country);
    }
    console.log("Loading: " + url);
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows,
        },
        function () {
          // do something with new state
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  // Button click
  handleClickOpen(houseId) {
    if (houseId !== this.state.selectedHouse) {
      console.log("HouseTable: " + houseId);
      this.setState({ selectedHouse: houseId });
    }
  }

  // Return from modal
  handleCloseModal = () => {
    this.setState({ selectedHouse: 0 });
  };

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    // if nothing important changed, we're done.
    if (
      prevProps.search === this.props.search &&
      prevProps.house_id === this.props.house_id &&
      prevProps.chain_id === this.props.chain_id &&
      prevProps.chain_name === this.props.chain_name &&
      prevProps.altid === this.props.altid &&
      prevProps.dbzid === this.props.dbzid &&
      prevProps.city === this.props.city &&
      prevProps.state === this.props.state &&
      prevProps.country === this.props.country &&
      prevProps.selectedHouse === this.props.selectedHouse
    ) {
      return;
    }

    this.setState({
      search: this.props.search, // search parameter
      house_id: this.props.house_id, // house_id parameter
      chain_id: this.props.chain_id, // chain_id parameter
      chain_name: this.props.chain_name, // chain_name parameter
      altid: this.props.altid, // altid parameter
      dbzid: this.props.dbzid, // altid parameter
      city: this.props.city, // city parameter
      state: this.props.state, // state parameter
      country: this.props.country, // country parameter
      onSelect: this.props.onSelect, // callback for selection
      selectedHouse: this.props.selectedHouse,
      isLoading: true,
      dataSource: [],
    });

    // New props, have to rerun query
    this.executeQuery(
      this.props.house_id,
      this.props.search,
      this.props.chain_id,
      this.props.chain_name,
      this.props.altid,
      this.props.dbzid,
      this.props.city,
      this.props.state,
      this.props.country
    );
  }

  /*
   ** wasSearching - return true if there was a search in progress, false if all search params are blank
   */
  wasSearching() {
    if (
      this.state.search === "" &&
      this.state.house_id === undefined &&
      this.state.chain_id === "" &&
      this.state.chain_name === "" &&
      this.state.altid === "" &&
      this.state.dbzid === "" &&
      this.state.city === "" &&
      this.state.state === "" &&
      this.state.country === ""
    ) {
      return;
    } else {
      return "No results found";
    }
  }

  /*
   ** chaininfo - display chainid and name
   */
  chaininfo(chain_id, chain_name) {
    if (chain_id === 0) return "";
    else return chain_name + "-" + chain_id;
  }

  /*
   ** render
   */
  render() {
    console.log("HouseTable: render");
    // Still loading, show animation
    if (this.state.isLoading) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <span className="glyphicon-left glyphicon glyphicon-refresh spinning">
            <CircularProgress />
          </span>
        </div>
      );
    } else if (this.state.dataSource.length === 0) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>{this.wasSearching()}</div>
      );
    } else {
      let houseCount = this.state.dataSource.length;

      let screenCount = 0;
      for (let i = 0; i < houseCount; i++)
        screenCount += this.state.dataSource[i].numscreens;

      /* House table */
      return (
        <div>
          <p
            style={{
              fontWeight: "bold",
              textAlign: "right",
              marginTop: "-3em",
            }}
          >
            Total Screens: {screenCount} <br />
            Total Houses: {houseCount}
          </p>

          <HouseDetail
            house_id={this.state.selectedHouse}
            onClose={this.handleCloseModal}
          />

          <Table style={{ whiteSpace: "nowrap" }}>
            <colgroup>
              <col width="1%" />
              <col width="5%" />
              <col width="5%" />
              <col width="25%" />
              <col width="15%" />
              <col width="25%" />
              <col width="5%" />
              <col width="10%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "house_id"}
                    direction={this.state.order}
                    onClick={createSortHandler("house_id", this)}
                  >
                    <span>House ID</span>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={this.state.orderBy === "dbz_id"}
                        direction={this.state.order}
                        onClick={createSortHandler("dbz_id", this)}
                    >
                        <span>DBZ ID</span>
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "name"}
                    direction={this.state.order}
                    onClick={createSortHandler("name", this)}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "altid"}
                    direction={this.state.order}
                    onClick={createSortHandler("altid", this)}
                  >
                    Alt Id
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "address1"}
                    direction={this.state.order}
                    onClick={createSortHandler("address1", this)}
                  >
                    Address
                  </TableSortLabel>
                  <TableSortLabel
                    active={this.state.orderBy === "city"}
                    direction={this.state.order}
                    onClick={createSortHandler("city", this)}
                  >
                    City
                  </TableSortLabel>
                  <TableSortLabel
                    active={this.state.orderBy === "state"}
                    direction={this.state.order}
                    onClick={createSortHandler("state", this)}
                  >
                    State
                  </TableSortLabel>
                  <TableSortLabel
                    active={this.state.orderBy === "zip"}
                    direction={this.state.order}
                    onClick={createSortHandler("zip", this)}
                  >
                    Zip
                  </TableSortLabel>
                  <TableSortLabel
                    active={this.state.orderBy === "country"}
                    direction={this.state.order}
                    onClick={createSortHandler("country", this)}
                  >
                    Country
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={this.state.orderBy === "numscreens"}
                    direction={this.state.order}
                    onClick={createSortHandler("numscreens", this)}
                  >
                    Screens
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "chain_name"}
                    direction={this.state.order}
                    onClick={createSortHandler("chain_name", this)}
                  >
                    Chain
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dataSource.map((row) => {
                var address = row.address1;
                if (row.city !== "" && row.city != null) {
                  address += ", " + row.city;
                }
                if (row.state !== "" && row.state != null) {
                  address += ", " + row.state;
                }
                if (row.zip !== "" && row.zip != null) {
                  address += " " + row.zip;
                }
                if (row.country !== "" && row.country != null) {
                  address += "  " + row.country;
                }
                return (
                  <TableRow key={row.house_id}>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          component.handleClickOpen(row.house_id);
                        }}
                        align="right"
                      >
                        <InfoIcon />
                      </IconButton>
                      <IconButton
                        href={"/schedule/" + row.chain_id + "/" + row.house_id}
                      >
                        <CalendarTodayIcon />
                      </IconButton>
                      <IconButton
                        href={"/perfs/" + row.house_id}
                      >
                        <ConfirmationNumberIcon />
                      </IconButton>
                      <SendToWeb houseId={row.house_id} />
                    </TableCell>
                    <TableCell>{row.house_id}</TableCell>
                    <TableCell>{row.dbzid}</TableCell>
                    <TableCell><b>{row.name}</b></TableCell>
                    <TableCell>{row.altid}</TableCell>
                    <TableCell>{address}</TableCell>
                    <TableCell align="right">{row.numscreens}</TableCell>
                    <TableCell>
                      {this.chaininfo(row.chain_id, row.chain_name)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    } // not isLoading
  } // render
} // HouseTable.js
