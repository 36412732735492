import React from "react";
import { Redirect } from "react-router-dom";
function Token(props) {
  // Check login
  var token = localStorage.getItem("token");
  if (!token) {
    alert("Please login first.");
    return <Redirect to="/" />;
  }

  return <div></div>;
}
export default Token;
