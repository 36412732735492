import React from "react";
import PropTypes from "prop-types";
import Token from "../components/Token";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { CodesTable } from "../components/CodesTable";
import { withStyles } from "@material-ui/core/styles";
import { SourceCode } from "../Source.js";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { styles } from "../components/CsStyles";

class Codes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      source: "", // User entered field
      movie_id: "", // User entered field
      code: "",
      desc: "",
      search_source: "",
      search_movie_id: "",
      search_code: "",
      search_desc: "",
    };

    // Title
    if (this.props.setTitle != null) this.props.setTitle("Codes");

    this.fetchData = this.fetchData.bind(this);
  }

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      // 13 is the enter keycode
      this.fetchData();
    }
  }
  handleChange = (name) => (event, data) => {
    if (name === "source") {
      this.setState({
        [name]: data,
      });
    }
    else
      this.setState({
        [name]: event.target.value,
      });

  };

  /*
   **
   ** fetchData
   **
   */
  fetchData() {
    console.log("Search now");
    console.log(this.state.source)
    this.setState({
      search_source: this.state.source,
      search_movie_id: this.state.movie_id,
      search_code: this.state.code,
      search_desc: this.state.desc
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Token />
        <CssBaseline />
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <div className={classes.sectionTitle} />
            <form className={classes.container} noValidate autoComplete="off">
              <Autocomplete
                className={classes.sourceSelectAutoComplete}
                id="source-autocomplete"
                options={SourceCode}
                onChange={this.handleChange("source")}
                value={this.state.source}
                style={{
                  width: "30%",
                  display: "inline-block",
                  marginTop: 10,
                  marginRight: 10,
                }}
                renderInput={(params) => (
                  <TextField
                    className={classes.searchTextField}
                    {...params}
                    variant="outlined"
                    margin="normal"
                    label="Source"
                    placeholder="Pick Source"
                  />
                )}
              />

              <TextField
                id="movie_id"
                label="Movie ID"
                className={classes.textField}
                value={this.state.movie_id}
                onChange={this.handleChange("movie_id")}
                onKeyPress={this.enterPressed.bind(this)}
                margin="normal"
              />
              <TextField
                id="code"
                label="Code"
                className={classes.textField}
                value={this.state.code}
                onChange={this.handleChange("code")}
                onKeyPress={this.enterPressed.bind(this)}
                margin="normal"
              />
              <TextField
                id="desc"
                label="Description"
                className={classes.textField}
                value={this.state.desc}
                onChange={this.handleChange("desc")}
                onKeyPress={this.enterPressed.bind(this)}
                margin="normal"
              />
              <Button
                variant="outlined"
                onClick={this.fetchData}
                className={classes.button}
                style={{ padding: 10, marginLeft: 10, marginTop: 5 }}
              >
                Search
              </Button>
            </form>
            <div className={classes.tableContainer}>
              <CodesTable
                source={this.state.search_source}
                movie_id={this.state.search_movie_id}
                code={this.state.search_code}
                desc={this.state.search_desc}
              />
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Codes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Codes);
