import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Token from "../components/Token";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../components/CsStyles";

class Generic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            house_id: "", // User entered field
            type: "",
            altid: "",
            dbzid: "",
            url1: "",
            config: "",
            uri: "",
            movie_name: "",
            time: "",
            date: "",
            booking: "",
            attribute: "",
            poster: "",
            summary: "",
            free_text: "",
        };

        // Title
        if (this.props.setTitle != null) this.props.setTitle("Generic");

        this.fetchData = this.fetchData.bind(this);
    }

    enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) {
          // 13 is the enter keycode
          this.fetchData();
        }
    }
    handleChange = (name) => (event) => {
        this.setState({
          [name]: event.target.value,
        });

        console.log(event.target.value)
  
    };

  /*
   **
   ** fetchData
   **
   */
    fetchData() {
        this.setState({
          search_house_id: this.state.house_id,
     
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Token />
                <CssBaseline />
                <div className={classes.root}>
                    <main className={classes.content}>
                    <div className={classes.appBarSpacer} />

                    {/* Form begins here */}
                    <form className={classes.container} noValidate autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            {/*Top Right Box:
                             * DBZID
                             * Type
                             * URI*/}
                            <Box
                                border={2}
                                borderRadius={'10px'}
                                borderColor={"black"}
                                padding={"10px"}>
                                <TextField
                                    id="dbzid"
                                    variant="outlined"
                                    autoFocus
                                    label="DBZID"
                                    size="small"
                                    style={{
                                        width: "20%",
                                        borderRadius: 0,
                                        fontWeight: "bold"
                                    }}
                                    className={classes.textField}
                                    value={this.state.dbzid}
                                    onChange={this.handleChange("dbzid")}
                                    onKeyPress={this.enterPressed.bind(this)}
                                    title="DBZID"/>

                                <Button
                                    variant="contained"
                                    onClick={this.fetchData}
                                    style={{
                                        width: "20%",
                                        borderRadius: 0,
                                        backgroundColor: "Black",
                                        color: "White"
                                    }}
                                    className={classes.button}>Submit</Button>

                                <TextField
                                    id="type"
                                    autoFocus
                                    label="Type"
                                    className={classes.textField}
                                    value={this.state.type}
                                    onChange={this.handleChange("type")}
                                    onKeyPress={this.enterPressed.bind(this)}
                                    size="small"
                                    style={{
                                        width: "25%",
                                        marginLeft: "15%",
                                        backgroundColor: "#E5E8E8"
                                    }}
                                    variant="outlined"
                                    title="type" />

                                <br></br>
                                <TextField
                                    id="URI"
                                    autoFocus
                                    label="URI"
                                    className={classes.textField}
                                    value={this.state.uri}
                                    onChange={this.handleChange("uri")}
                                    onKeyPress={this.enterPressed.bind(this)}
                                    margin="normal"
                                    size="small"
                                    style={{
                                        width: "95%",
                                        backgroundColor: "#E5E8E8"
                                    }}
                                    variant="outlined"
                                    title="uri" />
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <Box
                                border={2}
                                borderColor={"black"}
                                borderRadius={'10px'}
                                padding={"10px"}>
                            <TextField
                                id="movie_name"
                                autoFocus
                                label="Name"
                                className={classes.textField}
                                value={this.state.name}
                                onChange={this.handleChange("movie_name")}
                                onKeyPress={this.enterPressed.bind(this)}
                                size="small"
                                variant="outlined"
                                style={{
                                    width: "22%",
                                    backgroundColor: "#E5E8E8"
                                }}
                                title="Name" />

                            <TextField
                                id="date"
                                autoFocus
                                label="Date"
                                className={classes.textField}
                                value={this.state.date}
                                onChange={this.handleChange("date")}
                                onKeyPress={this.enterPressed.bind(this)}
                                size="small"
                                variant="outlined"
                                style={{
                                    width: "22%",
                                    backgroundColor: "#E5E8E8"
                                }}
                                title="Date" />


                            <TextField
                                id="attribute"
                                autoFocus
                                label="Attribute"
                                className={classes.textField}
                                value={this.state.name}
                                onChange={this.handleChange("attribute")}
                                onKeyPress={this.enterPressed.bind(this)}
                                size="small"
                                variant="outlined"
                                style={{
                                    width: "22%",
                                    backgroundColor: "#E5E8E8"
                                }}
                                title="Attribute" />

                            <TextField
                                id="summary"
                                autoFocus
                                label="Summary"
                                className={classes.textField}
                                value={this.state.summary}
                                onChange={this.handleChange("summary")}
                                onKeyPress={this.enterPressed.bind(this)}
                                size="small"
                                variant="outlined"
                                style={{
                                    width: "22%",
                                    backgroundColor: "#E5E8E8"
                                }}
                                title="Summary" />

                            <br></br>

                            <TextField
                                id="time"
                                autoFocus
                                label="Time"
                                className={classes.textField}
                                value={this.state.time}
                                onChange={this.handleChange("time")}
                                onKeyPress={this.enterPressed.bind(this)}
                                size="small"
                                variant="outlined"
                                style={{
                                    width: "22%",
                                    marginTop: "23px",
                                    backgroundColor: "#E5E8E8"
                                }}
                                title="Time" />


                            <TextField
                                id="booking"
                                autoFocus
                                label="Booking"
                                className={classes.textField}
                                value={this.state.booking}
                                onChange={this.handleChange("booking")}
                                onKeyPress={this.enterPressed.bind(this)}
                                size="small"
                                variant="outlined"
                                style={{
                                    width: "22%",
                                    marginTop: "23px",
                                    backgroundColor: "#E5E8E8"
                                }}
                                title="Booking" />

                            <TextField
                                id="poster"
                                autoFocus
                                label="Poster"
                                className={classes.textField}
                                value={this.state.name}
                                onChange={this.handleChange("poster")}
                                onKeyPress={this.enterPressed.bind(this)}
                                size="small"
                                variant="outlined"
                                style={{
                                    width: "22%",
                                    marginTop: "23px",
                                    backgroundColor: "#E5E8E8"
                                }}
                                title="Poster" />

                            <TextField
                                id="screen"
                                autoFocus
                                label="Screen"
                                className={classes.textField}
                                value={this.state.name}
                                onChange={this.handleChange("poster")}
                                onKeyPress={this.enterPressed.bind(this)}
                                size="small"
                                variant="outlined"
                                style={{
                                    width: "22%",
                                    marginTop: "23px",
                                    backgroundColor: "#E5E8E8"
                                }}
                                title="Screen" />
                            </Box>
                        </Grid>
                    </Grid>
                    
                   
                        

                        <Grid container spacing={4} padding="10px">
                            <Grid item xs={6}>
                                <Box
                                    border={2}
                                    borderColor={"black"}
                                    borderRadius={'10px'}
                                    padding={"10px"}
                                >
                                        <Box
                                            border={2}
                                            style={{ width: '40%' }}

                                            borderColor={"black"}
                                        >
                                        <TextField
                                            id="free_text"
                                            label="Free Text"
                                            className={classes.textField}
                                            value={this.state.free_text}
                                            onChange={this.handleChange("free_text")}
                                            onKeyPress={this.enterPressed.bind(this)}
                                            multiline
                                            rows={10}
                                            maxRows={10}
                                            style={{ width: '40%'}}
                                            variant="outlined"
                                            title="Free Text" />
                                        </Box>
                                        <Box
                                            border={2}
                                            style={{ width: '40%' }}

                                            borderColor={"black"}
                                        >
                                            <TextField
                                                id="free_text"
                                                label="Free Text"
                                                className={classes.textField}
                                                value={this.state.free_text}
                                                onChange={this.handleChange("free_text")}
                                                onKeyPress={this.enterPressed.bind(this)}
                                                multiline
                                                rows={10}
                                                maxRows={10}
                                                style={{ width: '40%' }}
                                                variant="outlined"
                                                title="Free Text" />
                                        </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    border={2}
                                    borderColor={"black"}
                                    borderRadius={'10px'}
                                    padding={"10px"}
                                >
                                <h2>Container 2</h2>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                    
                    </main>
                </div>
          </React.Fragment>
        );
      }
    }

Generic.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Generic);
