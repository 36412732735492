import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import Token from "../components/Token";
import { styles } from "../components/CsStyles";
import WhereIs from "../components/Reports/WhereIs";
import TheatersWithTixx from "../components/Reports/TheatersWithTixx";
import TheatersWithShowtimes from "../components/Reports/TheatersWithShowtimes";

import Grow from "@material-ui/core/Grow";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

/*
 ** Reports - a page for accessing reports
 */
class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportIndex: false,
      reportFolderIndex: false
    };

    // Title
    if (this.props.setTitle != null) {
      this.props.setTitle("Reports");
      }
    const localData = window.localStorage.getItem('CURR_REPORT')
    var currData = JSON.parse(localData)
    if( currData !== null)
      this.state = currData
  }


  /*
   ** render
   **
   */
  render() {
    const { classes } = this.props;

    
    const handleReportChange = (event, newValue) => {
      this.setState({
        reportIndex: newValue,
      });

    };
    const handleFolderChange = (event, newValue) => {
      this.setState({
        reportIndex: false,
        reportFolderIndex: newValue,
      });
    };
    const pickTable = (reportType, queryUrl) => {
      window.localStorage.setItem("CURR_REPORT", JSON.stringify(this.state))
      switch (reportType) {
        case "whereis":
          return <WhereIs {...this.props} />
        case "theaterswithtixx":
          return <TheatersWithTixx {...this.props} />
        case "theaterswithshowtimes":
          return <TheatersWithShowtimes {...this.props} />
        default:
          return <React.Fragment />
      }
      }

    return (
      <React.Fragment>
        <Token />
        <CssBaseline />
            <Box className={classes.appBarSpacer}/>

        <Tabs
          value={this.state.reportFolderIndex}
                onChange={handleFolderChange}
                variant="fullWidth"
        >
          <Tab label="Showtimes" />
          <Tab label="Theaters" />
        </Tabs>
            <TabPanel value={this.state.reportFolderIndex} index={0} style={{
                marginLeft: "40%"
            }}>
          <Box>
            <Tabs value={this.state.reportIndex} onChange={handleReportChange}>
              <Tab value="whereis" label="Where Is?" />
            </Tabs>
          </Box>
        </TabPanel>
            <TabPanel value={this.state.reportFolderIndex} index={1} style={{
                marginLeft: "30%"
            }}>
          <Box>
            <Tabs value={this.state.reportIndex} onChange={handleReportChange}>
              <Tab
                value="theaterswithshowtimes"
                label="Theaters w/ Showtimes"
              />
              <Tab value="theaterswithtixx" label="Theaters w/ Tixx" />
            </Tabs>
          </Box>
        </TabPanel>

        <Grow in={this.state.reportIndex !== false}>
          <Box>
            {pickTable(this.state.reportIndex, this.state.queryUrl)}
          </Box>
        </Grow>
      </React.Fragment>
    );
  }
}

Reports.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Reports);
