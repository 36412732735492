import React, { useState, useEffect, useMemo } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import uniqueId from 'lodash/uniqueId';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import orderBy from 'lodash/orderBy';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from "@material-ui/core/CircularProgress";
import { configState, configVars } from "../../Config";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";

const API_URL = (configState.siteState === configVars.live.siteState) ? 'https://lfvn2nq42iqqginpdbjxbtg5cq0obzab.lambda-url.us-east-1.on.aws' : 'https://x574diggkbatqjjhs6vddffzdm0xypoo.lambda-url.us-east-1.on.aws';
const TOKEN = 'ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnBaRlZ6WlhJaU9qRjkuTldoSmRsdk5VOEZRcWxiNmo5dHk3ZEFfWEstRlpfUGRoeklpcjFSN05MYw==';

const PERIODS = {
  DAY: '1 Day',
  WEEK: '1 Week',
  CUSTOM: 'custom',
}

const AWSRequests = () => {
  const [countryFilter, setCountryFilter] = useState('');
  const [movieFilter, setMovieFilter] = useState('');
  const [error, setError] = useState(undefined);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [times, setTimes] = useState([null,null]);
  const [period, setPeriod] = useState(PERIODS.DAY);
  
  useEffect(() => {
    if (times.some((time) => !time)) {
      return;
    }
    setLoading(true);
    setError(undefined);
    fetch(`${API_URL}/?from=${times[0].toISOString()}&to=${times[1].toISOString()}`, {
      timeout: 1000 * 60 * 60,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${TOKEN}`
      }
    })
      .then((results) => results.json())
      .then((results) => {
        if ('statusCode' in results && results.statusCode === "200") {
          setData(results.data || []);
        } else {
          setData([]);
          setError(results.statusMessage || results.errorMessage || 'Unknown Error');
        }
      }).catch((e) => {
        setData([]);
        setError(e.message);
      }).finally(() => { 
        setLoading(false);
      })
  }, [times]);

  useEffect(() => {
    const end = new Date();
    const start = new Date();
    switch (period) {
      case PERIODS.DAY:
        start.setHours(start.getHours() - 24);
        setTimes([start, end]);
        break;
      case PERIODS.WEEK:
        start.setHours(start.getHours() - (24 * 7));
        setTimes([start, end]);
        break;
    }
  }, [period])
  
  return (
    <div style={{
      display: 'grid',
      gap: '1em',
      margin: '0 auto',
      maxWidth: '62.5em',
      width: '100%',
    }}>
      <div
        style={{
          display: 'flex',
        }}
      >
        <FormControl>
          <FormLabel>Country</FormLabel>
          <Select
            value={countryFilter}
            onChange={(e) => {
              setCountryFilter(e.target.value)
            }}
            style={{
              width: '6.25em',
            }}
            disabled={loading}
          >
            <MenuItem value="">All</MenuItem>
            {data.map((row) => (
              <MenuItem
                key={uniqueId('country-option')}
                value={row.country}
              >
                {row.country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ flex: 1, marginLeft: '1em' }}>
          <FormLabel>Movie</FormLabel>
          <Select
            value={movieFilter}
            onChange={(e) => {
              setMovieFilter(e.target.value)
            }}
            style={{
              width: '6.25em',
            }}

            disabled={loading}
          >
            <MenuItem value="">All</MenuItem>
            {orderBy(data
              .reduce((movies, row) => [
                ...movies,
                ...row.movies
                  .filter((movie) => !movies.find(({ id }) => id === movie.id))
                  .map((movie) => ({ 
                    id: movie.id, 
                    title: movie.title 
                  }))
              ], []), 'title')
              .map((movie) => (
                <MenuItem
                  key={uniqueId('movie-option')}
                  value={movie.id}
                >
                  {movie.title}
                </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Range</FormLabel>
          <RadioGroup
            row
            onChange={(e) => {
              setPeriod(e.target.value);
            }}
            value={period}
          >
            <FormControlLabel 
              value={PERIODS.DAY} 
              control={<Radio />} 
              label={PERIODS.DAY} 
              disabled={loading}
            />
            <FormControlLabel 
              value={PERIODS.WEEK} 
              control={<Radio />} 
              label={PERIODS.WEEK} 
              disabled={loading}
            />
            <FormControlLabel 
              value={PERIODS.CUSTOM} 
              control={<Radio />} 
              label={PERIODS.CUSTOM} 
              disabled={loading}
            />
          </RadioGroup>
        </FormControl>
        {period === PERIODS.CUSTOM && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              label="From"
              value={times[0]}
              onChange={(from) => {
                console.log(typeof from)
                setTimes([from, times[1]])
              }}
              format="yyyy-MM-dd h:mm a"
              margin="normal"
            />
            <DateTimePicker
              label="To"
              value={times[1]}
              onChange={(to) => {
                setTimes([times[0], to])
              }}
              format="yyyy-MM-dd h:mm a"
              margin="normal"
            />
          </MuiPickersUtilsProvider>
        )}
      </div>
      <div>
        {error && (<Typography variant="h6">{error}</Typography>)}
        {loading ? (
          <div style={{ display: "flex", alignItems: "center", gap: "1rem"}}>
            <strong>This may take some time</strong>
            <CircularProgress />
          </div>
        ) : data
          .filter((row) => {
            if (countryFilter && row.country !== countryFilter) {
              return false;
            }
            if (movieFilter && !row.movies.find((movie) => movie.id === movieFilter)) {
              return false;
            }
            return true;
          })
          .map((row) => (
            <Accordion 
              key={`aws-country-${row.country}`} 
              style={{
                padding: 0,
                marginBottom: '1rem'
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${row.country}-content`}
                id={`panel-${row.country}-header`}
              >
                <Typography variant="h5"><strong>{row.country}</strong></Typography>
                <Typography 
                  variant="h6" 
                  style={{
                    flex: 1,
                    marginLeft: '1rem'
                  }}
                >
                  Total requests: {row.movies.reduce((total, movie) => total + movie.requestCount, 0)}
                </Typography>
                <Typography>Show movies</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: "column", padding: 0, }}>
                {row.movies.filter((movie) => !movieFilter || movie.id === movieFilter).map((movie) => (
                  <Accordion 
                    key={uniqueId('movie')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-${row.country}-${movie.id}-content`}
                      id={`panel-${row.country}-${movie.id}-header`}
                    >
                      <Typography><strong>{movie.title}</strong></Typography>
                      <Typography 
                        style={{
                          marginLeft: '1rem',
                        }}
                      >
                        Requests: {movie.requestCount}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        flexDirection: 'column',
                      }}
                    >
                      {movie.family.map((child) => (
                        <div 
                          key={uniqueId('child')}
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            width: '100%',
                          }}
                        >
                          <Typography variant="body2"><strong>{child.title}</strong></Typography>
                          <Typography 
                            variant="body2"
                            style={{
                              marginLeft: '1rem'
                            }}
                          >
                            ID: {child.id}
                          </Typography>
                          {child.requestCount !== movie.requestCount && (
                            <Typography 
                              variant="body2"
                              style={{
                                marginLeft: '1rem'
                              }}
                            >
                              Included in {child.requestCount} of {movie.requestCount} requests
                            </Typography>
                          )}
                          {('houseIds' in child) && (
                            <Typography
                              variant="body2"
                              style={{
                                marginLeft: '1rem'
                              }}
                            >
                              House IDs: {child.houseIds.join(', ')}
                            </Typography>
                          )}
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
              ))}
              
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  )
};

export default AWSRequests;