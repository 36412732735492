import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import HouseDetail from "../HouseDetail";
import { createSortHandler } from "../TableSortHelperMethods";
import { BoxofficeRed } from "../CsStyles";
/*
 **
 ** TheaterWithTixxTable
 **
 */
export default class TheatersWithTixxTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            isLoading: false,
            queryUrl: this.props.queryUrl,
            selectedHouse: 0,
            detailOpen: false,
            houseOpen: false,
        };
    }

    componentDidUpdate(prevProps) {
        // if nothing important changed, we're done
        if (prevProps.queryUrl === this.props.queryUrl) return;

        this.setState({
            queryUrl: this.props.queryUrl, // search parameter
            isLoading: true,
        });

        // New props, have to rerun query
        this.executeQuery(this.props.queryUrl);
    }

    /*
     ** executeQuery - get data from API
     */
    async executeQuery(queryUrl) {
        console.log("Loading: " + queryUrl);
        console.log("Loading: " + queryUrl + "&screen=1");
        if (queryUrl === "") {
            this.setState({
                dataSource: [],
                isLoading: false,
            });
            return;
        }

        try {
            const response = await fetch(queryUrl);
            const responseJson = await response.json();
            this.setState(
                {
                    isLoading: false,
                    dataSource: responseJson.rows == null ? [] : responseJson.rows,
                },
                function () {
                    // do something with new state
                }
            );
        } catch (error) {
            this.setState({
                isLoading: false,
            });
            console.error(error);
        }
    }
    handleClickOpen(houseId) {
        this.setState({
            selectedHouse: houseId,
            houseOpen: true,
        });
    }
    handleCloseHouseModal = () => {
        this.setState({
            houseOpen: false,
        });
    };

    // Return from modal
    handleCloseModal = () => {
        this.setState({
            detailOpen: false,
            selectedHouse: 0,
        });
    };
    /*
     ** render
     */
    render() {
        console.log("TheaterWithTixxTable: render");
        console.log("URL: " + this.state.queryUrl);
        if (this.state.isLoading) {
            return (
                <div style={{ flex: 1, paddingTop: 20 }}>
                    <span className="glyphicon-left glyphicon glyphicon-refresh spinning">
                        <CircularProgress />
                    </span>
                </div>
            );
        } else if (this.state.dataSource.length === 0) {
            return <div style={{ flex: 1, paddingTop: 20 }}>{""}</div>;
        }
        //Variables used for with Showtimes report
        var PrevHouse = "";
        var PrevCircuit = "";
        var houseTable = [];
        var newChain = 0;
        var countHouse = 0;
        var numtimes = 0;
        var finalNum = 0;
        /* House table */
        return (
            <Grid container spacing={4}>
                <HouseDetail
                    open={this.state.houseOpen}
                    house_id={this.state.selectedHouse}
                    onClose={this.handleCloseModal}
                    fullScreen={false}
                />
                {this.state.dataSource.map((row, index) => {
                    countHouse++;
                    //Initial House
                    if (PrevHouse === "") {
                        PrevHouse = row.theater;
                        PrevCircuit = row.circuit;
                        console.log("Initial House: " + PrevHouse);
                    }
                    //Check time for the Prevrent house
                    if (PrevHouse === row.theater) {
                        //If a New Chain is Spotted, reset housetable
                        if (newChain === 1) {
                            newChain = 0;
                            houseTable = [];
                            finalNum = 0;
                        }

                        //Perf checking
                        if (row.perfcheck > 0) {
                            if (row.time1 !== "     ") numtimes++;
                            if (row.time2 !== "     ") numtimes++;
                            if (row.time3 !== "     ") numtimes++;
                            if (row.time4 !== "     ") numtimes++;
                            if (row.time5 !== "     ") numtimes++;
                            if (row.time6 !== "     ") numtimes++;
                            if (row.time7 !== "     ") numtimes++;
                            if (row.time8 !== "     ") numtimes++;
                            if (row.time9 !== "     ") numtimes++;
                            if (row.time10 !== "     ") numtimes++;
                            if (row.time11 !== "     ") numtimes++;
                            if (row.time12 !== "     ") numtimes++;
                            if (row.time13 !== "     ") numtimes++;
                            if (row.time14 !== "     ") numtimes++;
                            if (row.time15 !== "     ") numtimes++;
                            if (row.time16 !== "     ") numtimes++;
                            if (row.time17 !== "     ") numtimes++;
                            if (row.time18 !== "     ") numtimes++;
                            if (row.time19 !== "     ") numtimes++;
                            if (row.time20 !== "     ") numtimes++;
                            if (row.time21 !== "     ") numtimes++;
                            if (row.time22 !== "     ") numtimes++;
                            if (row.time23 !== "     ") numtimes++;
                            if (row.time24 !== "     ") numtimes++;
                        }

                    }
                    //Previous House and Current House are Different
                    else {
                        var curCircuit;
                        //Final Number Times in the house
                        finalNum += numtimes;
                        //Push the house to the house table of the chain
                        houseTable.push(
                            <TableRow key={row.house_id}>
                                <TableCell style={{ fontWeight: "bold" }}>
                                    {PrevHouse}
                                </TableCell>
                                <TableCell>{numtimes}</TableCell>
                            </TableRow>
                        );
                        //Set the Current House to the PrevHouse Variable
                        PrevHouse = row.theater;
                        if (PrevCircuit !== row.circuit) {
                            newChain = 1;
                            curCircuit = PrevCircuit;
                            PrevCircuit = row.circuit;
                        } else {
                            PrevCircuit = row.circuit;
                        }
                        numtimes = 0;

                        //Log the numbers
                        if (newChain === 1) {
                            return (
                                <Grid item key={index} xs={4}>
                                    <Box bgcolor={BoxofficeRed} color="white" p={3}>
                                        <React.Fragment>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography style={{ width: "33%", flexShrink: 0 }}>
                                                        Chain Name
                                                    </Typography>

                                                    <Typography
                                                        style={{ color: "black", fontWeight: "bold" }}
                                                    >
                                                        {curCircuit}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Table style={{ whiteSpace: "nowrap" }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <TableSortLabel
                                                                        active={this.state.orderBy === "theater1"}
                                                                        direction={this.state.order}
                                                                        onClick={createSortHandler(
                                                                            "theater1",
                                                                            this
                                                                        )}
                                                                    >
                                                                        Theater
                                                                    </TableSortLabel>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TableSortLabel
                                                                        active={
                                                                            this.state.orderBy === "numtimes1"
                                                                        }
                                                                        direction={this.state.order}
                                                                        onClick={createSortHandler(
                                                                            "numtimes1",
                                                                            this
                                                                        )}
                                                                    >
                                                                        NUMTIMES
                                                                    </TableSortLabel>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>{houseTable}</TableBody>
                                                    </Table>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion expanded>
                                                <AccordionSummary
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography style={{ width: "33%", flexShrink: 0 }}>
                                                        Total NUMTIMES
                                                    </Typography>
                                                    <Typography
                                                        style={{ color: "black", fontWeight: "bold" }}
                                                    >
                                                        {finalNum}
                                                    </Typography>
                                                </AccordionSummary>
                                            </Accordion>
                                        </React.Fragment>
                                    </Box>
                                    <Divider />
                                </Grid>
                            );

                        }
                    }
                    //Log the final entry in the data
                    if (countHouse === this.state.dataSource.length) {
                        finalNum += numtimes;
                        //Push the house to the house table of the chain
                        houseTable.push(
                            <TableRow key={row.house_id}>
                                <TableCell style={{ fontWeight: "bold" }}>
                                    {PrevHouse}
                                </TableCell>
                                <TableCell>{numtimes}</TableCell>
                            </TableRow>
                        );
                        if (numtimes > 0) {
                            return (
                                <Grid key={index} item xs={4}>
                                    <Box bgcolor={BoxofficeRed} color="white" p={3}>
                                        <React.Fragment>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography style={{ width: "33%", flexShrink: 0 }}>
                                                        Chain Name
                                                    </Typography>

                                                    <Typography
                                                        style={{ color: "black", fontWeight: "bold" }}
                                                    >
                                                        {PrevCircuit}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Table style={{ whiteSpace: "nowrap" }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <TableSortLabel
                                                                        active={this.state.orderBy === "theater1"}
                                                                        direction={this.state.order}
                                                                        onClick={createSortHandler(
                                                                            "theater1",
                                                                            this
                                                                        )}
                                                                    >
                                                                        Theater
                                                                    </TableSortLabel>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TableSortLabel
                                                                        active={
                                                                            this.state.orderBy === "numtimes1"
                                                                        }
                                                                        direction={this.state.order}
                                                                        onClick={createSortHandler(
                                                                            "numtimes1",
                                                                            this
                                                                        )}
                                                                    >
                                                                        NUMTIMES
                                                                    </TableSortLabel>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>{houseTable}</TableBody>
                                                    </Table>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion expanded>
                                                <AccordionSummary
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography style={{ width: "33%", flexShrink: 0 }}>
                                                        Total NUMTIMES
                                                    </Typography>
                                                    <Typography
                                                        style={{ color: "black", fontWeight: "bold" }}
                                                    >
                                                        {finalNum}
                                                    </Typography>
                                                </AccordionSummary>
                                            </Accordion>
                                        </React.Fragment>
                                    </Box>
                                </Grid>
                            );
                        }
                    }

                    return <span key={index} />;
                })}
            </Grid>
        );
    } // render
} // TheaterWithTixxTable.js
