import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { styles } from "../CsStyles";
import AdvancedSearchTextFields from "./AdvancedSearchTextFields";
import LocationSearch from "./LocationSearch";
import SearchTermChips from "../SearchTermChips";

/*
 ** Search Bar a highly reusable tool for searching
 */
class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchData: { country: [], ...this.props.match.params },
      onSave: null,
      ancholEl: null,
      open: false,
    };
    //this.enterPressed(true);
  }

  componentDidUpdate(prevProps) {
    // if nothing important changed, we're done
    if (prevProps.searchData === this.props.searchData) return;

    this.setState({
      searchData: {
        country: [],
        ...this.props.match.params,
        ...this.props.searchData,
      },
    });
  }

  /*
   ** handleChange - set the state for a field when the value of the field has changed
   **/
  handleChange = (name) => (event, data) => {

    if (name === "country") {
      this.setState({
        searchData: { ...this.state.searchData, [name]: data },
      });
      return;
    }
    else if (event.target.type === "checkbox") {
      this.setState({
        searchData: { ...this.state.searchData, [name]: Number(event.target.checked) },
      });
      return;
    }
    this.setState({
      searchData: { ...this.state.searchData, [name]: event.target.value },
    });
  };
  /*
   ** enterPressed - enter or return key pressed
   */
  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13 || event === true) {
      // 13 is the enter keycode
      this.setState({ anchorEl: null });
      this.props.onSave(this.state.searchData)();
    }
  }

  /*
   ** render
   **
   */
  render() {
    const { classes } = this.props;
    const { searchData } = this.state;

    /*
     ** clear
     */
    const clearPressed = (event) => {
      this.setState({
        searchData: { country: [] },
      });
    };

    const handleClick = (event) => {
      this.setState({ anchorEl: document.getElementById("form") });
    };

    const handleTermDelete = (term) => (event) => {
      let newData = this.state.searchData;
      if ("country" === term) newData["country"] = [];
      else delete newData[term];

      if (searchData["mainField"] === term) {
        delete newData["mainField"];
        delete newData["main"];
      }

      this.setState({
        searchData: newData,
      });
      this.props.onSave(searchData)();
    };

    const handleClose = () => {
      this.setState({ anchorEl: null });
    };

    const searchCalled = () => {
      handleClose();
      this.props.onSave(searchData)();
    };

    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <div className={classes.advancedSearch} noValidate autoComplete="off">
        <Paper id="form" className={classes.root}>
          <IconButton
            className={classes.iconButton}
            onClick={this.props.onSave(this.state.searchData)}
          >
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.input}
            placeholder="Type here to search the full database"
            onKeyPress={this.enterPressed.bind(this)}
            value={searchData.main ? searchData.main : ""}
            onChange={this.handleChange("main")}
          />

          <Fade in={Object.keys(this.state.searchData).length !== 0}>
            <IconButton className={classes.iconButton} onClick={clearPressed}>
              <ClearIcon />
            </IconButton>
          </Fade>

          <Divider className={classes.divider} orientation="vertical" />
          <h3>
            <Button onClick={handleClick}>Filter</Button>
          </h3>
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton className={classes.iconButton} onClick={handleClick}>
            <ExpandMoreIcon />
          </IconButton>
        </Paper>

        <br />

        <SearchTermChips
          searchData={searchData}
          handleDelete={handleTermDelete}
        />

        <Popover
          id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={classes.adSearchPopOver}>
            <p className={classes.advancedSearchText}>
              Fill in one ore more of the field below to filter your results.
            </p>

            <form>
              {AdvancedSearchTextFields(
                this.props.searchCriteria,
                classes,
                this
              )}

              {LocationSearch(
                searchData,
                classes,
                this
              )}

              <hr className={classes.hrStyle} />

              <div
                style={{
                  textAlign: "left",
                  marginLeft: "1em",
                  display: "inline-block",
                }}
              >
                <Button
                  ref="autocomplete"
                  onClick={searchCalled}
                  variant="contained"
                  color="primary"
                  className={classes.buttonAdSearch}
                >
                  Search
                </Button>
              </div>

              <div
                style={{
                  textAlign: "right",
                  marginLeft: "1em",
                  display: "inline-block",
                }}
              >
                <Button
                  ref="autocomplete"
                  onClick={clearPressed}
                  variant="contained"
                  disabled={Object.keys(this.state.searchData).length === 0}
                  className={classes.buttonAdSearch}
                >
                  Clear
                </Button>
              </div>
            </form>
          </div>
        </Popover>
      </div>
    );
  }
}

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchBar);
