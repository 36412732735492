import React, { useEffect, useRef, useState } from "react";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import { fetchData, getUserInitials } from "./helpers";
import Wrapper from "./Wrapper";
import CodesTable from "./Table";

const CodesFileUpload = ({ setTitle }) => {
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState("");
  const [newCodes, setNewCodes] = useState([]);
  const fileRef = useRef(null);

  useEffect(() => {
    setTitle("Upload unmapped codes from file");
  }, []);
  const handleUpload = () => {
    setLoading(true);
    fetchData({
      endpoint: "mapcodes/parseFile",
      body: {
        source,
        codesFile: fileRef.current,
        initials: getUserInitials(),
      },
      handleComplete: (response) => {
        setLoading(false);
        setNewCodes(response.data);
      },
    });
  };

  function handleFile(event) {
    fileRef.current = event.target.files[0];
  }

  return (
    <Wrapper>
      <div
        style={{
          alignItems: "flex-end",
          display: "flex",
          gap: "1rem",
          margin: "1rem 0",
        }}
      >
        <FormControl>
          <FormLabel>Source</FormLabel>
          <Input
            type="text"
            value={source}
            onChange={(e) => setSource(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>File</FormLabel>
          <Input type="file" ref={fileRef} onChange={handleFile} />
        </FormControl>
        <Button disabled={loading} onClick={handleUpload} variant="contained">
          Get codes
        </Button>
      </div>
      {!!newCodes.length && <CodesTable codes={newCodes} />}
    </Wrapper>
  );
};

export default CodesFileUpload;
