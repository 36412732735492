import React, { Component } from "react";
import "./App.css";
import TopAppBar from "./components/TopAppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SideBar } from "./components/SideBar";
import { Router } from "./components/Router";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { BoxofficeRed, BoxofficeDeepRed } from "./components/CsStyles"

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "Home",
      open: false,
    };

    // Bind this
    this.handleSidebarToggle = this.handleSidebarToggle.bind(this);
    this.setTitle = this.setTitle.bind(this);
  }

  /*
   ** Allow sidebar to toggle its own state
   */
  handleSidebarToggle = () => {
    var oldState = this.state.open;
    var newState = !oldState;
    this.setState({ open: newState });
  };

  /*
   ** Allow components in router to set a new title
   */
  setTitle = (newTitle) => {
    this.setState({ title: newTitle });
  };

  /*
   ** render the componnent
   */
  render() {
    const theme = createTheme({
      palette: {
        secondary: {
          light: "#e94948",
          main: "#b00020",
          dark: "#790000",
          contrastText: "#ffffff",
        },
        primary: {
          light: "#767ee9",
          main: "#4052b6",
          dark: "#002a85",
          contrastText: "#ffffff",
        },
        boxoffice: {
          main: BoxofficeRed,
          dark: BoxofficeDeepRed,
        }
      },
    });

    return (
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <CssBaseline>
            <TopAppBar
              isOpen={this.state.open}
              title={this.state.title}
              onClick={this.handleSidebarToggle}
            />
            <SideBar
              isOpen={this.state.open}
              onClick={this.handleSidebarToggle}
            />
            <Router setTitle={this.setTitle} />
          </CssBaseline>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;
