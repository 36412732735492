import React, { Fragment, useState, useEffect, useCallback } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import { debounce } from "lodash";
import Tooltip from "@material-ui/core/Tooltip";

import { fetchData, getUserInitials } from "./helpers";

const UPDATE_STATUSES = ["COMPLETE", "SKIPPED", "TO_UPDATE"];

const Code = ({ code, handleUpdateData, isNew = false }) => {
  const [loading, setLoading] = useState(false);
  const [nextId, setNextId] = useState("");
  const [shouldIgnore, setShouldIgnore] = useState(code.ignore || false);
  const [nextAttributes, setNextAttributes] = useState(code.attributes || "");
  const [posCode, setPosCode] = useState(code.code);
  const [movies, setMovies] = useState([]);
  const [movieSearch, setMovieSearch] = useState(code.internal_title || "");
  const [moviesLoading, setMoviesLoading] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (code.movie_id) {
      setNextId(`${code.movie_id}`);
      return;
    }
    if (code.original_id) {
      setNextId(`${code.original_id}`);
      return;
    }
  }, [code.movie_id, code.original_id]);

  useEffect(() => {
    if (shouldSearch && movieSearch !== "") {
      debouncedSearch(movieSearch);
    }
  }, [movieSearch, shouldSearch]);

  const debouncedSearch = useCallback(
    debounce((search) => {
      setMoviesLoading(true);
      fetchData({
        endpoint: "mapcodes/searchMovies",
        body: {
          search,
        },
        handleComplete: (response) => {
          if (response.data) {
            setMovies(response.data);
          }
        },
        handleFinally: () => {
          setMoviesLoading(false);
        },
      });
    }, 500),
    [],
  );

  const handleUpdate = () => {
    if (nextId === code.movie_id && shouldIgnore === code.ignore) {
      return;
    }

    setLoading(true);
    if (!!code.id) {
      fetchData({
        endpoint: "mapcodes/updateCode",
        body: {
          id: code.id,
          movie_id: nextId,
          ignore: shouldIgnore ? "1" : "0",
          initials: getUserInitials(),
          attributes: nextAttributes,
          status: UPDATE_STATUSES.includes(code.status)
            ? "TO_UPDATE"
            : "TO_MAP",
        },
        handleComplete: (response) => {
          setLoading(false);
          if (!response.error && handleUpdate) {
            handleUpdateData("id", response.data.id, {
              status: response.data.status,
              movie_id: response.data.movie_id,
              ignore: response.data.ignore,
            });
          }
          if (response.error) {
            setError(response.error);
          }
        },
      });
    } else {
      fetchData({
        endpoint: "mapcodes/addCode",
        body: {
          source: code.source,
          code: posCode,
          external_title: code.external_title,
          movie_id: nextId,
          ignore: shouldIgnore ? "1" : "0",
          initials: getUserInitials(),
          attributes: nextAttributes,
          status: UPDATE_STATUSES.includes(code.status)
            ? "TO_UPDATE"
            : "TO_MAP",
        },
        handleComplete: (response) => {
          setLoading(false);
          if (!response.error && handleUpdate) {
            handleUpdateData("code", response.data.code, {
              id: response.data.id,
              status: response.data.status,
              movie_id: response.data.movie_id,
              ignore: response.data.ignore,
            });
          }
          if (response.error) {
            setError(response.error);
          }
        },
      });
    }
  };

  return (
    <TableRow>
      <TableCell>{code.source}</TableCell>
      <TableCell>
        {isNew ? (
          <TextField
            label={posCode ? null : "Code"}
            name="posCode"
            type="text"
            value={posCode}
            onChange={(e) => {
              setPosCode(e.target.value);
            }}
            margin="normal"
          />
        ) : (
          code.code
        )}
      </TableCell>
      <TableCell>{code.external_title}</TableCell>
      <TableCell>
        <div
          style={{
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            label={nextId ? null : "Movie ID"}
            name="idMovie"
            type="text"
            value={nextId}
            onChange={(e) => {
              setNextId(e.target.value);
            }}
            margin="normal"
          />
          {nextId && code.original_id && nextId !== `${code.original_id}` ? (
            <span>Previously {code.original_id}</span>
          ) : (
            ""
          )}
        </div>
      </TableCell>
      <TableCell>
        <FormControl style={{ width: "12.5rem" }}>
          <Tooltip
            disableHoverListener={!movieSearch}
            placement="top"
            title={movieSearch || ""}
          >
            <Autocomplete
              options={movies}
              filterSelectedOptions
              inputValue={movieSearch}
              onInput={(e) => {
                setMovieSearch(e.target.value);
              }}
              loading={moviesLoading}
              onChange={(_, data) => {
                if ('movie_id' in data) {
                  setNextId(data.movie_id);
                }
              }}
              onFocus={() => {
                if (!shouldSearch) {
                  setShouldSearch(true);
                }
              }}
              getOptionLabel={(option) =>
                `${option.name} (${
                  option.release
                    ? new Date(option.release).getFullYear()
                    : "N/A"
                })` || ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="normal"
                  label="Movie"
                  placeholder="Pick movie"
                />
              )}
            />
          </Tooltip>
        </FormControl>
      </TableCell>
      <TableCell>
        <TextField
          label={"Attributes"}
          name="attributes"
          type="text"
          value={nextAttributes}
          onChange={(e) => {
            setNextAttributes(e.target.value);
          }}
          margin="normal"
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={shouldIgnore}
          onChange={(e) => setShouldIgnore(!shouldIgnore)}
          title="Should ignore code"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </TableCell>
      <TableCell>
        {loading ? (
          <CircularProgress />
        ) : (code.status === "UNMAPPED" && (!!nextId || shouldIgnore)) ||
          (nextAttributes !== code.attributes && (!!nextId || shouldIgnore)) ||
          (code.status !== "UNMAPPED" &&
            (!!code.movie_id
              ? nextId !== `${code.movie_id}`
              : nextId !== `${code.original_id}` ||
                shouldIgnore !== code.ignore)) ? (
          <Fragment>
            <Button onClick={handleUpdate} variant="contained">
              Save
            </Button>
            {error && (
              <span style={{ color: "#FF0000", fontSize: "0.75rem" }}>
                {error}
              </span>
            )}
          </Fragment>
        ) : (
          <div style={{ width: "4.125rem" }}>
            {error && (
              <span style={{ color: "#FF0000", fontSize: "0.75rem" }}>
                {error}
              </span>
            )}
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Code;
