import React from "react";
import { GoogleLogin } from "react-google-login-component-improved";
import { configState } from "../Config";
var component = this;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callback: this.props.callback,
    };

    var emailAddress = localStorage.getItem("email");
    var tokenValue = localStorage.getItem("token");

    if (configState.siteState === "DEV") {
      emailAddress = "testing@webedia.us";
      tokenValue = "1234";
      localStorage.setItem("email", emailAddress);
      localStorage.setItem("token", tokenValue);
    } else {
      if (emailAddress === null) {
        emailAddress = "Please Login";
      }
      if (tokenValue === null) {
        tokenValue = "";
      }
    }

    this.state = {
      token: tokenValue, // not logged in yet
      email: emailAddress,
    };
    component = this;
  }

  /*
   ** setAuth - keep login token in state
   */
  setAuth(doFunction, newToken, newEmail) {
    if (doFunction === "login") {
      // Must be a valid login
      if (newEmail.indexOf("webedia") === -1) {
        alert("Must login with a webedia email address");
        return;
      }

      component.setState({
        token: newToken,
        email: newEmail,
      });
    }

    if (doFunction === "logout") {
      component.setState({
        token: "",
        email: "Please login",
      });
    }

    localStorage.setItem("email", newEmail);
    localStorage.setItem("token", newToken);

    // Go home!
    window.location = "/";
  }

  /*
   ** responseGoogle -- callback after Google authorization
   */
  responseGoogle(googleUser) {
    var id_token = googleUser.getAuthResponse().id_token;
    console.log({ accessToken: id_token });

    // anything else you want to do(save to localStorage)...
    var profile = googleUser.getBasicProfile();
    console.log("ID: " + profile.getId()); // Don't send this directly to your server!
    console.log("Full Name: " + profile.getName());
    console.log("Given Name: " + profile.getGivenName());
    console.log("Family Name: " + profile.getFamilyName());
    console.log("Image URL: " + profile.getImageUrl());
    console.log("Email: " + profile.getEmail());

    component.setAuth("login", id_token, profile.getEmail());
  }

  /*
   ** render
   */
  render() {
    // If already logged in, display the username and as a link to logout
    if (this.state.token !== "") {
      return (
        <button
          className="google-login"
          onClick={() => {
            this.setAuth("logout", "", "");
          }}
        >
          {this.state.email}
        </button>
      );
    } else {
      return (
        <div>
          <GoogleLogin
            socialId="623265186947-2kiqr6aii3hpaqf71bg6425pm7d6mst8.apps.googleusercontent.com"
            className="google-login"
            scope="profile email"
            responseHandler={this.responseGoogle}
            buttonText="Login With Google"
          />
        </div>
      );
    }
  }
}

export default Login;
