import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Link from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core/styles";
import { configState } from "../Config";
import SearchDetailSection from "./SearchDetailSection";

import { BoxofficeRed } from "./CsStyles";

import tempImage from "../TEMPTHEATERIMAGE.jpg";

/* Style */
const styles = (theme) => ({
  searchResultsClass: {
    margin: "auto",
  },
});

class HouseDetail extends React.Component {
  state = {
    open: false,
    house_id: 0,
    edit: true,
    record: null,
    dataSource: [],
  };

  // handleClose
  handleClose = () => {
    this.setState({
      open: false,
    });
    this.props.onClose();
  };

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }
  /*
   ** executeQuery - get data from API
   */
  async executeQuery(house_id) {
    var url = configState.apiBase + "houses?function=search";
    if (house_id !== "") url += "&house_id=" + house_id;
    console.log("Loading: " + url);
    try {
      const response = await fetch(url);
      const responseJson = await response.json();

      if (responseJson.rows[0]["url"])
        responseJson.rows[0]["url"] = (
          <Link
            underline="hover"
            href={responseJson.rows[0]["url"]}
            style={{ color: "black" }}
          >
            {responseJson.rows[0]["url"]}
          </Link>
        );

      if (responseJson.rows[0]["url1"])
        responseJson.rows[0]["url1"] = (
          <Link
            underline="hover"
            href={responseJson.rows[0]["url1"]}
            style={{ color: "black" }}
          >
            {responseJson.rows[0]["url1"]}
          </Link>
        );

      if (responseJson.rows[0]["urlx"])
        responseJson.rows[0]["urlx"] = (
          <Link
            underline="hover"
            href={responseJson.rows[0]["urlx"]}
            style={{ color: "black" }}
          >
            {responseJson.rows[0]["urlx"]}
          </Link>
        );

      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows,
          record: responseJson.rows == null ? [] : responseJson.rows[0],
        },
        function () {
          // do something with new state
          console.log("HouseDetail: got new data state.");
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  /*
   ** handleChange - handle the change in a text field, to make it permanent
   */
  handleChange = (row, name) => (event) => {
    let newRec = this.state.record;
    newRec[name] = event.target.value;
    this.setState({ record: newRec });
  };

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    console.log(
      "HouseDetail: got new props with house_id " + this.props.house_id
    );

    // If nothing important changed, we're done.
    if (
      prevProps.house_id === this.props.house_id &&
      prevProps.open === this.props.open
    )
      return;

    if (this.props.house_id > 0) {
      this.setState({ open: true });
      this.executeQuery(this.props.house_id);
    } else {
      this.setState({ open: false });
    }

    this.setState({
      house_id: this.props.house_id, // house_id parameter
    });
  }

  /*
   ** render
   */
  render() {
    const { classes } = this.props;

    var houseName = "";
    if (this.state.record != null) {
      var row = this.state.record;
      houseName = row.name;
    }

    return (
      <div>
        <Dialog
          className={classes.searchResultsClass}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth="sm"
        >
          <div
            style={{
              backgroundColor: BoxofficeRed,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "200px",
                overflow: "hidden",
              }}
            >
              <img
                style={{
                  display: "inline-block",
                  width: "100%",
                  marginTop: "-75px",
                }}
                src={tempImage}
                alt={"https://www.movienewsletters.net/photos/000000H1.jpg"}
              />
            </div>
            <div
              style={{
                padding: "1em",
                display: "inline-block",
                margin: "0",
                color: "white",
              }}
            >
              <h1>{houseName}</h1>
              <h2>
                House ID: {row ? row.house_id : null}
                <br />
                DBZ ID:{" "}
                {row ? (
                  <Link
                    href={"https://cms.boxoffice.com/theaters/" + row.dbzid}
                    target="_blank"
                    color="inherit"
                  >
                    {" "}
                    {row.dbzid}{" "}
                  </Link>
                ) : null}
              </h2>
            </div>
          </div>
          <DialogContent>
            {this.state.record ? (
              <div className={classes.advancedSearch}>
                {SearchDetailSection(
                  row,
                  {
                    address1: "Address 1 ",
                    address2: "Address 2 ",
                    city: "City ",
                    state: "State ",
                    zip: "Zip/Postal ",
                    country: "Country ",
                    timezone: "Timezone ",
                  },
                  "ADDRESS"
                )}
                {SearchDetailSection(
                  row,
                  {
                    numscreens: "Screen Count ",
                    type: "Type 1 ",
                    type2: "Type 2 ",
                    type3: "Type 3 ",
                  },
                  "SCREENS and TYPES"
                )}
                {SearchDetailSection(
                  row,
                  {
                    altid: "1 Alt ID ",
                    pdicode: "2 TMS/PDI code ",
                    holcode: "3 Holcode ",
                  },
                  "ALTID"
                )}
                {SearchDetailSection(
                  row,
                  {
                    postype: "POS Type ",
                    posuser: "POS Username ",
                    pospass: "POS Password ",
                    posdata: "POS Data URL ",
                  },
                  "POS"
                )}
                {SearchDetailSection(row, { url: "URL" }, "MAIN URL")}
                {SearchDetailSection(
                  row,
                  {
                    fan_id: "Fandango Number ",
                    onlinetix: "Atom Enabled ",
                  },
                  "TICKETING"
                )}
                {SearchDetailSection(
                  row,
                  {
                    chain_name: "Chain ",
                    chain_id: "Chain Id ",
                    number: "Exhibitor Unit # ",
                    url1: "URL 1 ",
                    urlx: "URL X ",
                  },
                  "EXHIBITION DETAILS"
                )}
              </div>
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(HouseDetail);
