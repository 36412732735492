import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

import { fetchData } from "./helpers";
import Wrapper from "./Wrapper";
import CodeFile from "./CodeFile";

const Files = ({ setTitle }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [allSources, setAllSources] = useState([]);
  const [_, setCheckResponse] = useState({});
  useEffect(() => {
    setTitle("Codes files and sources");
    handleListFiles();
    fetchData({
      endpoint: "mapcodes/sources",
      handleComplete: (response) => {
        setAllSources(response.data.all);
      },
    });
  }, []);

  const handleListFiles = () => {
    setLoading(true);
    fetchData({
      endpoint: "mapcodes/listFiles",
      handleComplete: (response) => {
        setFiles(response.data);
        setLoading(false);
      },
    });
  };

  const handleCheck = () => {
    setIsChecking(true);
    fetchData({
      endpoint: "mapcodes/loadFiles",
      handleComplete: (response) => {
        setCheckResponse(response.data);
        setIsChecking(false);
        handleListFiles();
      },
    });
  };

  return (
    <Wrapper loading={loading}>
      <Button
        onClick={handleCheck}
        variant="contained"
        disabled={isChecking}
        style={{ margin: "1rem 0" }}
      >
        Force files check
      </Button>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>File</TableCell>
            <TableCell>Codes Source</TableCell>
            <TableCell>Last Checked</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file) => (
            <CodeFile key={file.id} file={file} allSources={allSources} />
          ))}
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default Files;
