import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Token from "../Token";

const Wrapper = ({ title, children, loading }) => (
  <div
    style={{
      margin: "0 auto",
      maxWidth: "62.5em",
      width: "100%",
    }}
  >
    <Token />
    <h2>{title}</h2>
    {loading ? <CircularProgress /> : children}
  </div>
)

export default Wrapper;