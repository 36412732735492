import React from "react";
import PropTypes from "prop-types";
import Token from "../components/Token";
import TextField from "@material-ui/core/TextField";
import ScheduleStatusTable from "../components/ScheduleStatusTable";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { styles } from "../components/CsStyles";
import { withStyles } from "@material-ui/core/styles";

class ScheduleStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chain_id: "",
      country: "",
      search_chain_id: "",
      search_country: "",
      open: false,
    };

    // Title
    if (this.props.setTitle != null) {
      this.props.setTitle("SheduleStatus");
    }

    this.fetchData = this.fetchData.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  /*
   **
   ** fetchData
   **
   */
  fetchData() {
    console.log("Search ScheduleStatus");
    this.setState({
      search_chain_id: this.state.chain_id,
      search_country: this.state.country,
    });
  }

  /*
   ** handleChange - set the state for a field when the value of the field has changed
   **/
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  /*
   ** enterPressed - enter or return key pressed
   */
  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      // 13 is the enter keycode
      this.fetchData();
    }
  }

  /*
   **
   ** clearSearch
   **
   */
  clearSearch() {
    this.setState({
      search_chain_id: "",
      search_country: "",
      chain_id: "",
      country: "",
    });
  }

  /*
   ** render
   **
   */
  render() {
    const { classes } = this.props;
    console.log("ScheduleStatus: Rerendering.");
    return (
      <React.Fragment>
        <Token />
        <CssBaseline />

        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />

            {/* Form begins here */}
            <form className={classes.csMenu} noValidate autoComplete="off">
              <Grid container spacing={0}>
                <TextField
                  id="chain_id"
                  label="Chain ID"
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.chain_id}
                  onChange={this.handleChange("chain_id")}
                  margin="normal"
                />
                <TextField
                  id="country"
                  label="Country"
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.country}
                  onChange={this.handleChange("country")}
                  margin="normal"
                />

                <Button
                  ref="autocomplete"
                  onClick={this.fetchData}
                  className={classes.button}
                >
                  Search
                </Button>
                <Button onClick={this.clearSearch} className={classes.button}>
                  Clear
                </Button>
              </Grid>
            </form>

            <div className={classes.tableContainer}>
              <ScheduleStatusTable
                chain_id={this.state.search_chain_id}
                chain_name=""
                country={this.state.search_country}
              />
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
} // end Chains

ScheduleStatus.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ScheduleStatus);
