import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ChainDetail from "./ChainDetail";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import { configState } from "../Config";
import { createSortHandler } from "../components/TableSortHelperMethods";
import SendToWeb from "./SendToWeb";

/*
 **
 ** ChainTable
 **
 */
export default class ChainTable extends React.Component {
  /*
   ** constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      chain_id: this.props.chain_id, // search chain_id
      chain_name: this.props.chain_name, // search chain_name
      country: this.props.country, // search country
      onSelect: this.props.onSelect, // callback for selection, return code or house_id
      isLoading: true,
      selectedChainId: 0, // chain_id if we have a popup to display
      dataSource: [],
      detailOpen: false,
    };

    this.executeQuery = this.executeQuery.bind(this);
    //this.handleClickOpen = this.handleClickOpen.bind(this);
    this.executeQuery(
      this.props.chain_id,
      this.props.chain_name,
      this.props.country
    );
  }

  /*
   ** Button click
   */
  handleClickOpen(chain_id) {
    this.setState({
      selectedChainId: chain_id,
      detailOpen: true,
    });
  }

  // Return from modal
  handleCloseModal = () => {
    this.setState({
      detailOpen: false,
    });
  };
  /*
   ** executeQuery - get data from API
   */
  async executeQuery(chain_id, chain_name, country) {
    var url = configState.apiBase + "chains?function=search";
    if (chain_name !== "") {
      url += "&wildcard=" + encodeURIComponent(chain_name);
    }
    if (chain_id !== "") {
      url += "&chain_id=" + encodeURIComponent(chain_id);
    }
    if (country !== "") {
      url += "&country=" + encodeURIComponent(country);
    }
    try {
      console.log("Loading: " + url);
      const response = await fetch(url);
      const responseJson = await response.json();
      this.setState(
        {
          isLoading: false,
          dataSource: responseJson.rows == null ? [] : responseJson.rows,
        },
        function () {
          // do something with new state
        }
      );
    } catch (error) {
      console.error(error);
      this.setState(
        {
          ...this.state,
          isLoading: false,
          dataSource: [],
        },
      );
    }
  }

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    // if nothing important changed, we're done.
    if (
      prevProps.chain_id === this.props.chain_id &&
      prevProps.detailOpen === this.props.detailOpen &&
      prevProps.chain_name === this.props.chain_name &&
      prevProps.country === this.props.country &&
      prevProps.selectedChainId === this.props.selectedChainId
    ) {
      return;
    }

    this.setState({
      chain_id: this.props.chain_id, // chain_id parameter
      chain_name: this.props.chain_name, // chain_name parameter
      country: this.props.country, // country parameter
      onSelect: this.props.onSelect, // callback for selection
      selectedChainId: this.props.selectedChainId,
      isLoading: true,
      dataSource: [],
    });

    // New props, have to rerun query
    this.executeQuery(
      this.props.chain_id,
      this.props.chain_name,
      this.props.country
    );
  }

  /*
   ** wasSearching - return true if there was a search in progress, false if all search params are blank
   */
  wasSearching() {
    if (
      this.state.chain_id === "" &&
      this.state.chain_name === "" &&
      this.state.country === ""
    ) {
      return "Please enter search parameters and click the SEARCH button";
    } else {
      return "No results found";
    }
  }

  /*
   ** shouldComponentUpdate
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.detailOpen === this.state.detailOpen &&
      nextState.dataSource === this.state.dataSource &&
      nextState.order === this.state.order &&
      nextState.orderBy === this.state.orderBy &&
      nextProps.chain_id === this.props.chain_id &&
      nextProps.chain_name === this.props.chain_name &&
      nextProps.country === this.props.country
    )
      return false;
    else return true;
  }

  /*
   ** render
   */
  render() {
    // Still loading, show animation
    if (this.state.isLoading) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <span className="glyphicon-left glyphicon glyphicon-refresh spinning"></span>
        </div>
      );
    } else if (this.state.dataSource.length === 0) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>{this.wasSearching()}</div>
      );
    } else {
      /* Chain table */
      console.log("ChainTable: Rerendering.");
      return (
        <div>
          <ChainDetail
            open={this.state.detailOpen}
            chain_id={this.state.selectedChainId}
            onClose={this.handleCloseModal}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "chain_id"}
                    direction={this.state.order}
                    onClick={createSortHandler("chain_id", this)}
                  >
                    Chain ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "name"}
                    direction={this.state.order}
                    onClick={createSortHandler("name", this)}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "address1"}
                    direction={this.state.order}
                    onClick={createSortHandler("address1", this)}
                  >
                    Address 1
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "city"}
                    direction={this.state.order}
                    onClick={createSortHandler("city", this)}
                  >
                    City
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "state"}
                    direction={this.state.order}
                    onClick={createSortHandler("state", this)}
                  >
                    State
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "zip"}
                    direction={this.state.order}
                    onClick={createSortHandler("zip", this)}
                  >
                    Zip
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "exhib_ctry"}
                    direction={this.state.order}
                    onClick={createSortHandler("exhib_ctry", this)}
                  >
                    Country
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dataSource.map((row) => {
                return (
                  <TableRow key={row.chain_id}>
                    <TableCell>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => {
                          this.handleClickOpen(row.chain_id);
                        }}
                        align="right"
                      >
                        <InfoIcon style={{ padding: 0 }} />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        style={{ padding: 0 }}
                        href={"/schedule/" + row.chain_id}
                      >
                        <CalendarTodayIcon style={{ padding: 0 }} />
                      </IconButton>
                    </TableCell>

                    <TableCell>
                      <SendToWeb chainId={row.chain_id} />
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.chain_id}
                    </TableCell>

                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.address1}</TableCell>

                    <TableCell>{row.city}</TableCell>

                    <TableCell>{row.state}</TableCell>

                    <TableCell>{row.zip}</TableCell>

                    <TableCell>{row.exhib_ctry}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    } // not isLoading
  } // render
}
