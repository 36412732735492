import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Token from "../components/Token";
import { styles } from "../components/CsStyles";
import PerfsTable from "../components/PerfsTable";

/*
 ** Perfs - a list of performances
 */
class Perfs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      house_id: this.props.match.params["house_id"],
      movie_id: "",
      auditorium: "",
      date: "",
      latest: false,
      search_house_id: this.props.match.params["house_id"],
      search_movie_id: "",
      search_auditorium: "",
      search_date: "",
      search_latest: false,
      open: false,
    };

    // Title
    if (this.props.setTitle != null) this.props.setTitle("Perfs");

    this.fetchData = this.fetchData.bind(this);
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDateChange = (name) => (date) => {
    this.setState({ [name]: date });
  };

  handleToggle = (event) => {
    this.setState({
      [event.target.value]: !this.state[event.target.value],
    });
  };

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      this.fetchData();
    }
  }

  /*
   **
   ** fetchData
   **
   */
  fetchData() {
    console.log("Search now");
    this.setState({
      search_house_id: this.state.house_id,
      search_movie_id: this.state.movie_id,
      search_auditorium: this.state.auditorium,
      search_date: this.state.date,
      search_latest: this.state.latest,
    });
  }

  /*
   ** render
   **
   */
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Token />
        <CssBaseline />
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />

            {/* Form begins here */}
            <form className={classes.csMenu} noValidate autoComplete="off">
              <Grid container spaceing={24}>
                <TextField
                  id="movie_id"
                  label="Movie ID"
                  autoFocus
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.name}
                  onChange={this.handleChange("movie_id")}
                  margin="normal"
                />

                <TextField
                  id="house_id"
                  label="House ID"
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.house_id}
                  onChange={this.handleChange("house_id")}
                  margin="normal"
                />

                <TextField
                  id="date"
                  label="Date"
                  className={classes.textField}
                  value={this.state.date}
                  type="date"
                  onChange={this.handleChange("date")}
                  onKeyPress={this.enterPressed.bind(this)}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  id="auditorium"
                  label="Auditorium"
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.auditorium}
                  onChange={this.handleChange("auditorium")}
                  margin="normal"
                  style={{ width: 100 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={"latest"}
                      checked={this.state.latest}
                      onChange={this.handleToggle}
                    />
                  }
                  label="Latest"
                  labelPlacement="top"
                  style={{ display: "none" }}
                />

                <Button
                  variant="outlined"
                  onClick={this.fetchData}
                  className={classes.button}
                  style={{ padding: 5, marginLeft: 10, marginTop: 25, height: 40 }}
                >
                  Search
                </Button>
              </Grid>
            </form>

            <div className={classes.tableContainer}>
              <PerfsTable
                house_id={this.state.search_house_id}
                movie_id={this.state.search_movie_id}
                auditorium={this.state.search_auditorium}
                date={this.state.search_date}
                latest={this.state.search_latest}
              />
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Perfs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Perfs);
