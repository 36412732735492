const drawerWidth = 220;

export const BoxofficeRed = "#B00020";
export const BoxofficeDeepRed = "#91242C";

export const styles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#FFFFFF",
    color: "#000000",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  fullImage: {
    width: "100%",
  },
  drawerPaper: {
    // position: 'relative',
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: { minHeight: "20px", 
  marginLeft: "auto",
  marginRight: "auto" },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "90vh",
    overflow: "auto",
  },
  contentflex: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflow: "auto",
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 350,
  },
  tcontainer: {
    overflowX: "initial",
  },
  bighead: {
    height: 150,
  },
  csMenu: {
    marginBottom: 20,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  datePicker: {
    marginLeft: 10,
    marginTop: 20,
  },
  sectionTitle: {
    paddingTop: 20,
  },
  paperVert: {
    height: 200,
    paddingTop: 100,
    verticalAlign: "center",
  },
  advancedSearch: {
    margin: "auto",
    textAlign: "left",
    maxWidth: "100%",
    width: "1024px",
  },
  search: {
    position: "relative",
    borderStyle: "solid",
    borderWidth: "0.5px",
    borderColor: "lightgrey",
    margin: "0 auto",
  },
  input: {
    color: "inherit",
    width: "100%",
  },
  adSearchPopOver: {
    color: "inherit",
    width: "1024px",
    maxWidth: "100%",
    padding: "2%",
  },
  hrStyle: {
    margin: "2%",
    border: "solid 1px lightgrey",
  },
  advancedSearchText: {
    margin: "1em auto",
    textAlign: "center",
    fontSize: "14px",
  },
  searchTextFieldBox: {
    margin: "0 auto",
    textAlign: "left",
    width: "50%",
    display: "inline-block",
    padding: "0% 2%",
  },
  searchTextFieldBoxWide: {
    margin: "0 auto",
    textAlign: "left",
    width: "100%",
    display: "inline-block",
    padding: "0% 2%",
  },
  searchTextField: {
    margin: "auto",
    textAlign: "center",
    width: "100%",
  },
  countrySelectAutoComplete: {
    marginTop: 10,
    marginRight: "20px",
    width: "450px",
    maxWidth: "50%",
    display: "inline-block",
  },
  searchCheckBox: {
    margin: "auto",
    textAlign: "center",
  },
  searchSelectField: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  buttonAdSearch: {
    height: "3.5em",
    width: "6em",
    margin: "1em auto",
    textAlign: "left",
  },
  foreignButton: {
    marginTop: "29px",
    marginLeft: "20px",
  },
  foreignCheck: {
    margin: "10px 20px 0px 20px",
  },
  foreignStatsColumn: {
    float: "left",
    whiteSpace:"nowrap",
    marginRight:"2px",
  },
  foreignStatsRow: {
    content: "",
    display: "table",
    clear: "both"
  },
});
