import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Token from "../components/Token";
import { styles } from "../components/CsStyles";
import { AutoTable } from "../components/AutoTable";
import { QueueTable } from "../components/QueueTable";

class Automaters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "", // User entered field
      name: "", // User entered field
      search_id: "", // Search data
      search_name: "", // Search data
      open: false,
    };

    // Title
    if (this.props.setTitle != null) this.props.setTitle("Automaters");

    this.fetchData = this.fetchData.bind(this);
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });

    if (name === "id") this.setState({ name: "" });
    if (name === "name") this.setState({ id: "" });
  };

  /*
   **
   ** fetchData
   **
   */
  fetchData() {
    console.log("Search now");
    this.setState({
      search_id: this.state.id,
      search_name: this.state.name,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Token />
        <CssBaseline />
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />

            <Typography variant="h4" className={classes.sectionTitle}>
              Automaters
            </Typography>

            <div className={classes.contentflex}>
              <AutoTable
                id={this.state.search_id}
                search={this.state.search_name}
              />
            </div>

            <Typography variant="h4" className={classes.sectionTitle}>
              Queues
            </Typography>
            <div className={classes.contentflex}>
              <QueueTable
                id={this.state.search_id}
                search={this.state.search_name}
              />
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Automaters.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Automaters);
