import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MovieDetail from "./MovieDetail";
import ImageBox from "./ImageBox";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import { configState } from "../Config";
import {
  createSortHandler,
  newSearchSort,
} from "../components/TableSortHelperMethods";
var component = this;

/*
 **
 ** MovieTable
 **
 */
export class MovieTable extends React.Component {
  /*
   ** constructor
   **/
  constructor(props) {
    super(props);
    this.state = {
      search: this.props.search, // search parameter, text
      movie_id: this.props.movie_id, // movie_id
      director: this.props.director,
      startrelease: this.props.startrelease,
      endrelease: this.props.endrelease,
      fsearch: this.props.fsearch,
      dbzid: this.props.dbzid,
      country: this.props.country,
      selectedMovie: 0, // selected movie for detail section
      selectedPoster: "",
      onSelect: this.props.onSelect, // callback for selection, return code or movie_id
      isLoading: true,
      imageOpen: false,
      detailOpen: false,
      dataSource: [],
    };

    this.redoQuery = this.redoQuery.bind(this);
    this.redoQuery(
      this.props.movie_id,
      this.props.search,
      this.props.director,
      this.props.startrelease,
      this.props.endrelease,
      this.props.country,
      this.props.dbzid,
      this.props.fsearch
    );
    component = this;
  }

  /*
   ** componentDidMount - when first mounting, do query, which is probably empty
   */
  componentDidMount() {
    this.redoQuery("", "", "", "", "", "", "", false);
  }

  /*
   ** Button click
   */
  handleClickOpen(movieId, country, poster) {
    this.setState({
      selectedMovie: movieId,
      selectedCountry: country,
      selectedPoster: poster,
      detailOpen: true,
    });
  }

  handleOpenImage(movieId) {
    this.setState({ selectedMovie: movieId, imageOpen: true });
    console.log("opening image");
  }
  handleCloseImage = (movieId) => {
    this.setState({
      imageOpen: false,
    });
  };
  // Return from modal
  handleCloseModal = () => {
    this.setState({
      detailOpen: false,
    });
  };

  /*
   ** redoQuery - query from AJAX
   */
  async redoQuery(
    movie_id,
    search,
    director,
    startrelease,
    endrelease,
    country,
    dbzid,
    fsearch
  ) {
    var url = configState.apiBase + "movies?function=search";
    if (fsearch === "foreign") {
      url += "&foreign=" + 1; // Also search foreign
      if (country !== "") {
        url += "&country=" + encodeURIComponent(country);
      }
    }
    if (search !== "") {
      url += "&wildcard=" + encodeURIComponent(search); // Only search US
    }

    if (dbzid === "1") {
      if (movie_id !== "") {
        url += "&dbzid=" + encodeURIComponent(movie_id);
      }
    }
    else {
      if (movie_id !== "") {
        url += "&movie_id=" + encodeURIComponent(movie_id);
      }
    }
    if (director !== "") {
      url += "&director=" + encodeURIComponent(director);
    }
    if (startrelease !== "") {
      if (endrelease === "") {
        url += "&startrelease=" + encodeURIComponent(startrelease);
      } else {
        url +=
          "&startrelease=" +
          encodeURIComponent(startrelease) +
          "&endrelease=" +
          encodeURIComponent(endrelease);
      }
    }

    console.log("Query URL: " + url);
    return fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        let newData = [];
        if (responseJson.rows) {
          if (this.state.orderBy)
            newData = newSearchSort(
              this.state.orderBy,
              this.state.order,
              responseJson.rows
            );
          else newData = responseJson.rows;
        }

        this.setState(
          {
            isLoading: false,
            dataSource: newData,
          },
          function () {
            // do something with new state
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {
    // if nothing important changed, we're done.
    if (
      prevProps.search === this.props.search &&
      prevProps.movie_id === this.props.movie_id &&
      prevProps.director === this.props.director &&
      prevProps.fsearch === this.props.fsearch &&
      prevProps.dbzid === this.props.dbzid &&
      prevProps.startrelease === this.props.startrelease &&
      prevProps.endrelease === this.props.endrelease &&
      prevProps.country === this.props.country
    ) {
      return;
    }

    this.setState({
      search: this.props.search, // search parameter
      movie_id: this.props.movie_id, // movie_id parameter
      director: this.props.director,
      startrelease: this.props.startrelease,
      endrelease: this.props.endrelease,
      fsearch: this.props.fsearch,
      dbzid: this.props.dbzid,
      country: this.props.country,
      onSelect: this.props.onSelect, // callback for selection
      isLoading: true,
      dataSource: [],
    });

    // New props, have to rerun query
    this.redoQuery(
      this.props.movie_id,
      this.props.search,
      this.props.director,
      this.props.startrelease,
      this.props.endrelease,
      this.props.country,
      this.props.dbzid,
      this.props.fsearch
    );
  }

  ImageCheck(isPhoto) {
    if (isPhoto) {
      return (
        <TableCell>
          <img
            src={window.location.origin + "/check.jpg"}
            className="check-mark"
            style={{ width: 25, textAlign: "middle" }}
            alt=""
          />
        </TableCell>
      );
    } else {
      return (
        <TableCell>
          <img
            src={window.location.origin + "/x.jpg"}
            className="x-mark"
            style={{ width: 25, textAlign: "middle" }}
            alt=""
          />
        </TableCell>
      );
    }
  }

  /*
   ** render
   */
  render() {
    console.log("re-rendering movie table");
    var foreignTitle = "";
    var foreignCert = "";
    var couVsorigin = "";
    var foreignRele = "";
    var photo = "";
    var foreignTable = null;
    if (this.props.fsearch === "foreign") {
      foreignTable = (
        <React.Fragment>
          <TableCell>
            <TableSortLabel
              active={this.state.orderBy === "fname"}
              direction={this.state.order}
              onClick={createSortHandler("fname", this)}
            >
              Foreign Name
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={this.state.orderBy === "fcert"}
              direction={this.state.order}
              onClick={createSortHandler("fcert", this)}
            >
              Cert
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={this.state.orderBy === "frelease"}
              direction={this.state.order}
              onClick={createSortHandler("frelease", this)}
            >
              Foreign Release
            </TableSortLabel>
          </TableCell>
        </React.Fragment>
      );
    } else {
      foreignTable = null;
    }
    // Still loading, show animation
    if (this.state.isLoading) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <CircularProgress />
        </div>
      );
    } else if (
      this.state.dataSource.length === 0 &&
      (this.state.search !== "" || this.state.movie_id !== "")
    ) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <h4>No Results</h4>
        </div>
      );
    } else {
      /* Movie table */
      return (
        <div>
          <MovieDetail
            open={this.state.detailOpen}
            movie_id={this.state.selectedMovie}
            country={this.state.selectedCountry}
            onClose={this.handleCloseModal}
            poster={this.state.selectedPoster}
          />
          <ImageBox
            open={this.state.imageOpen}
            movie_id={this.state.selectedMovie}
            onClose={this.handleCloseImage}
          />

          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "movie_id"}
                    direction={this.state.order}
                    onClick={createSortHandler("movie_id", this)}
                  >
                    MovieID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={this.state.orderBy === "dbzid"}
                        direction={this.state.order}
                        onClick={createSortHandler("dbzid", this)}
                    >
                        DBZID
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "name"}
                    direction={this.state.order}
                    onClick={createSortHandler("name", this)}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "mpaa"}
                    direction={this.state.order}
                    onClick={createSortHandler("mpaa", this)}
                  >
                    US MPAA
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "runtime"}
                    direction={this.state.order}
                    onClick={createSortHandler("runtime", this)}
                  >
                    Runtime
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "release"}
                    direction={this.state.order}
                    onClick={createSortHandler("release", this)}
                  >
                    Release1
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "distrib"}
                    direction={this.state.order}
                    onClick={createSortHandler("distrib", this)}
                  >
                    Distributor
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "director"}
                    direction={this.state.order}
                    onClick={createSortHandler("director", this)}
                  >
                    Director
                  </TableSortLabel>
                </TableCell>
                {foreignTable}
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "photo"}
                    direction={this.state.order}
                    onClick={createSortHandler("photo", this)}
                  >
                    Poster
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "hiphotos"}
                    direction={this.state.order}
                    onClick={createSortHandler("hiphotos", this)}
                  >
                    Still
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "country"}
                    direction={this.state.order}
                    onClick={createSortHandler("country", this)}
                  >
                    Country
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dataSource.map((row, index) => {
                if (this.state.fsearch === "foreign") {
                  foreignTitle = (
                    <TableCell>
                      <b>{row.fname}</b>
                    </TableCell>
                  );
                  foreignCert = <TableCell>{row.fcert}</TableCell>;
                  foreignRele = <TableCell>{row.frelease}</TableCell>;
                  photo = row.poster
                  couVsorigin = row.country;
                } else {
                  foreignTitle = null;
                  foreignCert = null;
                  foreignRele = null;
                  photo = row.photos
                  couVsorigin = row.origin;
                }
                return (
                  <TableRow key={index} style={{ cursor: "pointer" }}>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          component.handleClickOpen(
                            row.movie_id,
                            row.country,
                            row.poster
                          );
                        }}
                        align="right"
                      >
                        <InfoIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.movie_id}
                    </TableCell>
                    <TableCell>{row.dbzid}</TableCell>
                    <TableCell><b>{row.name}</b></TableCell>
                    <TableCell>{row.mpaa}</TableCell>
                    <TableCell>{row.runtime}</TableCell>
                    <TableCell>{row.release}</TableCell>
                    <TableCell>{row.distrib}</TableCell>
                    <TableCell>{row.director}</TableCell>
                    {foreignTitle}
                    {foreignCert}
                    {foreignRele}
                    {this.ImageCheck(photo)}
                    {this.ImageCheck(row.hiphotos)}
                    <TableCell>{couVsorigin}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    } // not isLoading
  } // render
} // MovieTable.js
