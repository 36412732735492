import React, { useEffect, useState } from "react";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import UploadIcon from "@material-ui/icons/CloudUpload";
import { fetchData } from "../utils/fetchData";
import uniqueId from "lodash/uniqueId";

const getStatusFromData = (data) => {
  let statuses = [];
  if (!data) {
    return statuses;
  }
  if (data.updated_at || data.created_at) {
    statuses.push(
      !data.updated_at
        ? "Queued for sending"
        : `Manually sent at ${new Date(data.updated_at).toLocaleString()}`,
    );
  }

  if (data.uscsi_timestamp) {
    const showtimesUpdate = `Showtimes updated at ${new Date(
      data.uscsi_timestamp,
    ).toLocaleString()}`;
    if (data.updated_at && data.updated_at < data.uscsi_timestamp) {
      statuses[0] = showtimesUpdate;
    } else {
      statuses.push(showtimesUpdate);
    }
  }

  return statuses;
};

const SendToWeb = ({ houseId, chainId, color = "primary" }) => {
  const [status, setStatus] = useState(getStatusFromData());
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (hovered && houseId) {
      fetchData({
        endpoint: `sendtoweb/getLatest`,
        body: {
          house_id: houseId || "",
        },
        handleComplete: (response) => {
          setStatus(getStatusFromData(response.data));
        },
      });
    }
  }, [hovered, houseId]);

  const handleSend = () => {
    fetchData({
      endpoint: `sendtoweb/create`,
      body: {
        house_id: houseId || "",
        chain_id: chainId || "",
      },
      onComplete: (response) => {
        if (houseId) {
          setStatus(getStatusFromData(response.data));
        }
      },
    });
  };

  return (
    <Tooltip
      placement="top"
      title={
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            fontSize: "0.75rem",
            textAlign: "center",
          }}
        >
          <div style={{ fontSize: "1.125em" }}>Send to web</div>
          {status.map((status) => (
            <div key={uniqueId("status")} style={{ marginTop: "0.25em" }}>
              {status}
            </div>
          ))}
        </div>
      }
    >
      <IconButton
        onClick={handleSend}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{ color }}
      >
        <UploadIcon />
      </IconButton>
    </Tooltip>
  );
};

export default SendToWeb;
