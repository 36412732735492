import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImageIcon from "@material-ui/icons/Image";
import CloseIcon from "@material-ui/icons/Close";
import Link from "@material-ui/icons/Link";
import CheckCircle from "@material-ui/icons/CheckCircle";
import HdIcon from "@material-ui/icons/Hd";
import ImageBox from "./ImageBox";
import TextBox from "./TextBox";
import { configState } from "../Config";
import { createSortHandler } from "../components/TableSortHelperMethods";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
var counter = 0;
var color1 = "#E0E0E0"
var color2 = "#FAFAFA"

/* Class definition */
class ForeignTableBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startdate: this.props.startdate,
            enddate: this.props.enddate,
            country: this.props.country,
            isLoading: false,
            dataSource: [],
            stats: {},
            statsAnchorEl: null,
        };

        this.executeQuery = this.executeQuery.bind(this);
    }

    /*
     ** componentDidMount - when first mounting, do query, which is probably empty
     */
    componentDidMount() {
        this.executeQuery(
            this.state.country,
            this.state.startdate,
            this.state.enddate
        );
    }

    handleOpenImage(movieId, posterUrl) {
        this.setState({
            selectedMovie: movieId,
            selectedPoster: posterUrl,
            imageOpen: true,
        });
        console.log("opening image");
    }

    handleCloseImage = (movieId) => {
        this.setState({ imageOpen: false });
    };

    handleClickOpen = () => {
        this.setState({ textOpen: true });
    };

    handleClose = () => {
        this.setState({ textOpen: false });
    };

    USLink(movieId, isPhoto) {
        if (isPhoto) {
            return (
                <TableCell style={{
                    borderBottom: "1px solid",
                    backgroundColor: color2,
                    textAlign: "center",
                }}>
                    <ImageIcon
                        color="primary"
                        onClick={() => {
                            this.handleOpenImage(movieId, "");
                        }}
                        style={{ cursor: "pointer" }}
                    />
                </TableCell>
            );
        } else {
            return (
                <TableCell style={{
                    borderBottom: "1px solid",
                    backgroundColor: color2,
                    textAlign: "center",
                }}>
                    <CloseIcon />
                </TableCell>
            );
        }
    }

    IntlLink(movieId, posterUrl) {
        var url = posterUrl.trim();
        if (url !== "") {
            return (
                <TableCell style={{
                    borderBottom: "1px solid",
                    backgroundColor: color2,
                    textAlign: "center",
                }}>
                    <ImageIcon
                        color="primary"
                        onClick={() => {
                            this.handleOpenImage(movieId, url);
                        }}
                    />
                </TableCell>
            );
        } else {
            return (
                <TableCell style={{
                    borderBottom: "1px solid",
                    backgroundColor: color2,
                    textAlign: "center",
                }}>
                    <CloseIcon />
                </TableCell>
            );
        }
    }

    /*
     ** wasSearching - return true if there was a search in progress, false if all search params are blank
     */
    wasSearching() {
        if (
            this.state.country === "" &&
            this.state.startdate === "" &&
            this.state.enddate === ""
        ) {
            return "Please enter search parameters and click the SEARCH button";
        } else {
            return "No results found";
        }
    }
    /*
     ** executeQuery - get data from API
     */
    async executeQuery(country, startdate, enddate) {
        var url = configState.apiBase + "international?function=search";
        var baseUrl = url;
        if (country) url += "&country=" + country;
        if (startdate) url += "&startdate=" + startdate;
        if (enddate) url += "&enddate=" + enddate;

        // Make sure we have at least one param
        if (url === baseUrl) {
            this.setState({ isLoading: false, dataSource: [] });
            return;
        }

        console.log("Foreign: Loading URL: " + url);
        this.setState({ isLoading: true });
        try {
            const response = await fetch(url);
            const responseJson = await response.json();

            let newStats = {
                titles: 0,
                intPoster: 0,
                usaPoster: 0,
                intSynopsis: 0,
                usaSynopsis: 0,
                intTrailer: 0,

                realMovies: 0,
                realIPosters: 0,
                realDirectors: 0,
                realIntCerts: 0,
                realIntSynopsises: 0,
                realIntTrailers: 0,

                realTopMovies: 0,
                realTopIntPosters: 0,
                realTopDirectors: 0,
                realTopIntCerts: 0,
                realTopIntSynopsises: 0,
                realTopIntTrailers: 0,
            };
            newStats.titles = responseJson.rows.length;

            for (let i = 0; i < responseJson.rows.length; i++) {
                const movie = responseJson.rows[i];

                if (movie["fposter"]) newStats.intPoster++;
                if (movie["photos"]) newStats.usaPoster++;
                if (movie["fsynopsis"]) newStats.intSynopsis++;
                if (movie["ussynopsis"]) newStats.usaSynopsis++;
                if (movie["videos"]) newStats.intTrailer++;

                if (movie["exclude"] === 0 && movie["starts"] !== 0) {
                    newStats.realMovies++;

                    if (movie["fposter"]) newStats.realIPosters++;
                    if (movie["director"]) newStats.realDirectors++;
                    if (movie["fcert"]) newStats.realIntCerts++;
                    if (movie["fsynopsis"]) newStats.realIntSynopsises++;
                    if (movie["videos"]) newStats.realIntTrailers++;
                }
            }
            for (let i = 0; i < responseJson.rows.length; i++) {
                const movie = responseJson.rows[i];

                if (
                    movie["exclude"] === 0 &&
                    movie["starts"] !== 0 &&
                    i <= Math.round(newStats.realMovies / 10)
                ) {
                    if (movie["fposter"]) newStats.realTopIntPosters++;
                    if (movie["director"]) newStats.realTopDirectors++;
                    if (movie["fcert"]) newStats.realTopIntCerts++;
                    if (movie["fsynopsis"]) newStats.realTopIntSynopsises++;
                    if (movie["videos"]) newStats.realTopIntTrailers++;
                }
            }
            newStats.realTopMovies = Math.round(newStats.realMovies / 10);

            this.setState({
                isLoading: false,
                dataSource: responseJson.rows == null ? [] : responseJson.rows, // Full dataset
                stats: newStats,
            });
        } catch (error) {
            console.error(error);
        }
    }

    /*
     ** Got new props. So change state if they really changed.
     */
    componentDidUpdate(prevProps, prevState) {
        // Execute the query only if the parameters have changed
        if (
            this.props.country !== prevProps.country ||
            this.props.startdate !== prevProps.startdate ||
            this.props.enddate !== prevProps.enddate
        ) {
            // Update the state and get the new data
            this.setState({
                country: this.props.country,
                startdate: this.props.startdate,
                enddate: this.props.enddate,
                isLoading: true,
                dataSource: [],
            });

            // Actually execute a new data fetch
            this.executeQuery(
                this.props.country,
                this.props.startdate,
                this.props.enddate
            );
        }
    }

    handleStatsClick = (event) => {
        this.setState({
            statsAnchorEl: event.currentTarget,
        });
    };

    handleStatsClose = () => {
        this.setState({
            statsAnchorEl: null,
        });
    };

    /*
     ** render
     */
    render() {
        const { classes } = this.props;
        const open = Boolean(this.state.statsAnchorEl);
        const id = open ? "simple-popover" : undefined;
        var counter = 0;
        var textColor;
        // Still loading, show animation
        if (this.state.isLoading) {
            return <CircularProgress></CircularProgress>;
        } else if (this.state.dataSource.length === 0) {
            return (
                <div style={{ flex: 1, paddingTop: 20 }}>{this.wasSearching()}</div>
            );
        } else {
            /* Chain table */

            console.log("Foreign Table: Rerendering.");
            return (
                <div>
                    <div style={{ padding: 10}}>
                        <ImageBox
                            open={this.state.imageOpen}
                            movie_id={this.state.selectedMovie}
                            posterUrl={this.state.selectedPoster}
                            onClose={this.handleCloseImage}
                        />
                        <Button
                            aria-describedby={id}
                            variant="contained"
                            onClick={this.handleStatsClick}
                        >
                            Open Stats
                        </Button>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={this.state.statsAnchorEl}
                            onClose={this.handleStatsClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <Typography style={{ padding: 10 }}>
                                <h2>Total Counts</h2>
                                Titles: {this.state.stats.titles}
                                <br />
                                <div className={classes.foreignStatsRow}>
                                    <div className={classes.foreignStatsColumn}>
                                        Int. Posters: {this.state.stats.intPoster}
                                        <br />
                                        Int. Synopsises: {this.state.stats.intSynopsis}
                                        <br />
                                        Int. Trailers: {this.state.stats.intTrailer}
                                        <br />
                                    </div>
                                    <div className={classes.foreignStatsColumn}>
                                        USA Posters: {this.state.stats.usaPoster}
                                        <br />
                                        USA Synopsises: {this.state.stats.usaSynopsis}
                                        <br />
                                    </div>
                                </div>
                                <h2>Real Movies</h2>
                                <div className={classes.foreignStatsRow}>
                                    <div className={classes.foreignStatsColumn}>
                                        Movies: {this.state.stats.realMovies}
                                        <br />
                                        Movies Posters: {this.state.stats.realIPosters}
                                        <br />
                                        Movies Directors: {this.state.stats.realDirectors}
                                        <br />
                                    </div>
                                    <div className={classes.foreignStatsColumn}>
                                        Int. Certs.: {this.state.stats.realIntCerts}
                                        <br />
                                        Int Synopisies: {this.state.stats.realIntSynopsises}
                                        <br />
                                        Int. Trailers: {this.state.stats.realIntTrailers}
                                        <br />
                                    </div>
                                </div>
                                <h2>Top 10% Real Moves</h2>
                                <div className={classes.foreignStatsRow}>
                                    <div className={classes.foreignStatsColumn}>
                                        Movies: {this.state.stats.realTopMovies}
                                        <br />
                                        Movies Posters: {this.state.stats.realTopIntPosters}
                                        <br />
                                        Movies Directors: {this.state.stats.realTopDirectors}
                                        <br />
                                    </div>
                                    <div className={classes.foreignStatsColumn}>
                                        Int. Certs.: {this.state.stats.realTopIntCerts}
                                        <br />
                                        Int Synopisies: {this.state.stats.realTopIntSynopsises}
                                        <br />
                                        Int. Trailers: {this.state.stats.realTopIntTrailers}
                                        <br />
                                    </div>
                                </div>
                            </Typography>
                        </Popover>
                    </div>
                    <TableContainer 
                        style={{
                            maxHeight: '70vmax'
                        }}
                        >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow className={classes.bighead}>

                                    <TableCell style={{
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "ranking"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("ranking", this)}
                                        >
                                            Ranking
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        position: "sticky",
                                        left: 0,
                                        zIndex: 100,
                                        backgroundColor: "#E0E0E0",
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "movie_id"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("movie_id", this)}
                                        >
                                            MovieID
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "imdb"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("imdb", this)}
                                        >
                                            IMDB
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{
                                        backgroundColor: "#E0E0E0",
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "dbzid"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("dbzid", this)}
                                        >
                                            DBZ
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        borderBottom: "1px solid"
                                    }}>                                        <TableSortLabel
                                            active={this.state.orderBy === "runtime"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("runtime", this)}
                                        >
                                            Runtime
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        backgroundColor: "#E0E0E0",
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "director"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("director", this)}
                                        >
                                            Director
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        borderBottom: "1px solid"
                                    }}>                                        <TableSortLabel
                                            active={this.state.orderBy === "photos"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("photos", this)}
                                        >
                                            US-Poster
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        backgroundColor: "#E0E0E0",
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "name"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("name", this)}
                                        >
                                            US Title
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        borderBottom: "1px solid"
                                    }}>                                        <TableSortLabel
                                            active={this.state.orderBy === "release"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("release", this)}
                                        >
                                            US Release
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        backgroundColor: "#E0E0E0",
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "mpaa"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("mpaa", this)}
                                        >
                                            US MPAA
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        borderBottom: "1px solid"
                                    }}>                                        <TableSortLabel
                                            active={this.state.orderBy === "ussynopsis"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("ussynopsis", this)}
                                        >
                                            US Synopsis
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        backgroundColor: "#E0E0E0",
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "dname"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("dname", this)}
                                        >
                                            US Dist
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        borderBottom: "1px solid"
                                    }}>                                        <TableSortLabel
                                            active={this.state.orderBy === "actor1"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("actor1", this)}
                                        >
                                            Cast
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        backgroundColor: "#E0E0E0",
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "url"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("url", this)}
                                        >
                                            URL
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        borderBottom: "1px solid"
                                    }}>                                        <TableSortLabel
                                            active={this.state.orderBy === "cnthouses"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("cnthouses", this)}
                                        >
                                            Houses
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{
                                        backgroundColor: "#E0E0E0",
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "starts"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("starts", this)}
                                        >
                                            Starts
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        borderBottom: "1px solid"
                                    }}>                                        <TableSortLabel
                                            active={this.state.orderBy === "fposter"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("fposter", this)}
                                        >
                                            Intl-Poster
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        backgroundColor: "#E0E0E0",
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "fname"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("fname", this)}
                                        >
                                            Intl-Title
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        borderBottom: "1px solid"
                                    }}>                                        <TableSortLabel
                                            active={this.state.orderBy === "frelease"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("frelease", this)}
                                        >
                                            Intl-Release
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        backgroundColor: "#E0E0E0",
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "fcert"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("fcert", this)}
                                        >
                                            Intl-Cert
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        borderBottom: "1px solid"
                                    }}>                                        <TableSortLabel
                                            active={this.state.orderBy === "fsynopsis"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("fsynopsis", this)}
                                        >
                                            Intl-Syn
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        backgroundColor: "#E0E0E0",
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "fdname"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("fdname", this)}
                                        >
                                            Intl-Dist
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "lang"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("lang", this)}
                                        >
                                            Language
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell style={{
                                        backgroundColor: "#E0E0E0",
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "video_hd"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("video_hd", this)}
                                        >
                                            Intl-HD
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "video_low"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("video_low", this)}
                                        >
                                            Intl-Low
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{
                                        backgroundColor: "#E0E0E0",
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === "warning"}
                                            direction={this.state.order}
                                            onClick={createSortHandler("warning", this)}
                                        >
                                            Intl-Warn
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {this.state.dataSource.map((row, index) => {
                                    var length = Math.ceil(this.state.dataSource.length * .1)
                                    counter++
                                    if (counter <= length) {
                                        textColor = "#E4022B"

                                    }
                                    else {
                                       textColor = "black"
                                    }
                                    return (
                                        <TableRow key={index}>

                                            <TableCell padding="normal" component="th" scope="row" style={{
                                                borderBottom: "1px solid",
                                                textAlign: "center",
                                                backgroundColor: color2
                                            }}>
                                                {row.ranking}
                                            </TableCell>
                                            <TableCell padding="normal" component="th" scope="row"
                                                style={{
                                                    position: "sticky",
                                                    left: 0,
                                                    zIndex: 90,
                                                    fontWeight: "bold",
                                                    backgroundColor: color1,
                                                    color: textColor,
                                                    borderBottom: "1px solid black",
                                                    textAlign: "center",
                                                }}>
                                                {row.movie_id}
                                            </TableCell>
                                            <TableCell style={{
                                                borderBottom: "1px solid",
                                                backgroundColor: color2,
                                                textAlign: "center",
                                            }}>
                                                {row.imdb ? (
                                                    <a
                                                        href={
                                                            "https://www.imdb.com/title/tt" + row.imdb + "/"
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <Link color="primary" />
                                                    </a>
                                                ) : (
                                                    <CloseIcon />
                                                )}
                                            </TableCell>
                                            <TableCell style={{
                                                backgroundColor: color1,
                                                borderBottom: "1px solid",
                                                textAlign: "center",
                                            }}>{row.dbzid}</TableCell>
                                            <TableCell style={{
                                                borderBottom: "1px solid",
                                                backgroundColor: color2,
                                                textAlign: "center",
                                            }}>{row.runtime}</TableCell>
                                            <TableCell style={{
                                                backgroundColor: color1,
                                                borderBottom: "1px solid"
                                            }}>{row.director}</TableCell>
                                            {this.USLink(row.movie_id, row.photos)}
                                            <TableCell style={{
                                                backgroundColor: color1,
                                                borderBottom: "1px solid",
                                                fontWeight: "bold"
                                            }}>{row.name}</TableCell> {/* Title */}
                                            <TableCell style={{
                                                borderBottom: "1px solid",
                                                backgroundColor: color2
                                            }}>{row.release}</TableCell>
                                            <TableCell style={{
                                                backgroundColor: color1,
                                                borderBottom: "1px solid",
                                                textAlign: "center",
                                            }}>{row.mpaa}</TableCell>
                                            <TableCell style={{
                                                borderBottom: "1px solid",
                                                backgroundColor: color2,
                                                textAlign: "center",
                                            }}>
                                                {row.ussynopsis !== null &&
                                                    row.ussynopsis.trim() !== "" ? (
                                                    <TextBox
                                                        title={"US Synopsis"}
                                                        text={row.ussynopsis}
                                                    />
                                                ) : (
                                                    <CloseIcon />
                                                )}
                                            </TableCell>
                                            <TableCell style={{
                                                backgroundColor: color1,
                                                borderBottom: "1px solid"
                                            }}>{row.dname}</TableCell>
                                            <TableCell style={{
                                                borderBottom: "1px solid",
                                                backgroundColor: color2,
                                                textAlign: "center",
                                            }}>
                                                <TextBox
                                                    title={"Actors"}
                                                    text={
                                                        (row.actor1 ? row.actor1 : "") +
                                                        (row.actor2 ? ", " + row.actor2 : "") +
                                                        (row.actor3 ? ", " + row.actor3 : "") +
                                                        (row.actor4 ? ", " + row.actor4 : "") +
                                                        (row.actor5 ? ", " + row.actor5 : "")
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell style={{
                                                backgroundColor: color1,
                                                borderBottom: "1px solid",
                                                textAlign: "center",
                                            }}>
                                                {row.url !== null && row.url !== "" ? (
                                                    <a
                                                        href={row.url}
                                                        target={"_blank"}
                                                        title={"Link"}
                                                        text={row.url}
                                                    >
                                                        Link
                                                    </a>
                                                ) : (
                                                    <CloseIcon />
                                                )}
                                            </TableCell>
                                            <TableCell style={{
                                                borderBottom: "1px solid",
                                                backgroundColor: color2,
                                                textAlign: "center",
                                            }}>{row.cnthouses}</TableCell>
                                            <TableCell style={{
                                                backgroundColor: color1,
                                                borderBottom: "1px solid",
                                                textAlign: "center",
                                            }}>{row.starts}</TableCell>
                                            {this.IntlLink(row.movie_id, row.fposter)}
                                            <TableCell style={{
                                                backgroundColor: color1,
                                                borderBottom: "1px solid",
                                                fontWeight: "bold"
                                            }}>{row.fname}</TableCell>
                                            <TableCell style={{
                                                borderBottom: "1px solid",
                                                backgroundColor: color2
                                            }}>{row.frelease}</TableCell>
                                            <TableCell style={{
                                                backgroundColor: color1,
                                                borderBottom: "1px solid"
                                            }}>{row.fcert}</TableCell>
                                            <TableCell style={{
                                                borderBottom: "1px solid",
                                                backgroundColor: color2,
                                                textAlign: "center",
                                            }}>
                                                {row.fsynopsis !== null &&
                                                    row.fsynopsis.trim() !== "" ? (
                                                    <TextBox
                                                        title={"Foreign Synopsis"}
                                                        text={row.fsynopsis}
                                                    />
                                                ) : (
                                                    <CloseIcon />
                                                )}
                                            </TableCell>
                                            <TableCell style={{
                                                backgroundColor: color1,
                                                borderBottom: "1px solid"
                                            }}>{row.fdname}</TableCell>
                                            <TableCell style={{
                                                borderBottom: "1px solid",
                                                backgroundColor: color2
                                            }}>{row.lang}</TableCell>
                                            <TableCell style={{
                                                backgroundColor: color1,
                                                borderBottom: "1px solid",
                                                textAlign: "center",
                                            }}>
                                                {row.video_hd ? (
                                                    <HdIcon color="primary" />
                                                ) : (
                                                    <CloseIcon />
                                                )}
                                            </TableCell>
                                            <TableCell style={{
                                                borderBottom: "1px solid",
                                                backgroundColor: color2,
                                                textAlign: "center",
                                            }}>
                                                {row.video_low ? (
                                                    <CheckCircle color="primary" />
                                                ) : (
                                                    <CloseIcon />
                                                )}
                                            </TableCell>
                                            <TableCell style={{
                                                backgroundColor: color1,
                                                borderBottom: "1px solid"
                                            }}>{row.warning}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            );
        } // not isLoading
    } // render
}

export const ForeignTable = React.memo(ForeignTableBase);
