import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { BoxofficeDeepRed } from "./CsStyles";
import Typography from "@material-ui/core/Typography";
import "../App.css";

/*
 ** formatTimes - show up to three movies unless showing extra
 */
export default function ShowtimesTimesGrid(params) {
  if (params.data.oldHouse === 0) params.data.oldHouse = params.row.name
  else if (params.data.oldHouse !== params.row.name) {
    params.data.oldHouse = params.row.name
    params.data.showtimeMovieCounter = 0
  }
  params.data.showtimeMovieCounter++;
  var mtime = 1;
  var timeArr = [];
  for (var i = 1; i <= 24; i++) {
    var outTimes = "";
    var timer = params.row["time" + i];
    var am = params.row["mer" + i];
    timer = timer.trim();
    if (timer) {
      //if (outTimes !== "") outTimes += ", ";
      outTimes += timer;
      if (params.military) {
        if (am === "0") {
          var temp = parseInt(outTimes.substring(0, 2), 10);
          if (temp !== 12) {
            temp += 12;
          }
          outTimes = "" + temp + outTimes.substring(2);
        }
      } else {
        mtime = 0;
        if (am === "1") outTimes += " AM ";
        else outTimes += " PM ";
      }

      timeArr.push(outTimes);
    }
  }
  var withUrl;
  var outputTime = timeArr.map(function (time, index) {
    var perfurls = "";
    withUrl = 0;
    params.prow.map(function (perf) {
      if (params.presentDate === perf["showdate"]) {
        if (parseInt(params.row.movie_id, 10) === perf["movie_id"]) {
          if (mtime === 1) {
            if (perf["showtime"] === time) {
              perfurls = perf["showtext"];
              withUrl = 1;
            }
          } else {
            var perfTime;
            if (perf["showtime"].substring(0, 2) >= 13) {
              if (perf["showtime"].substring(0, 2) - 12 >= 10) {
                perfTime =
                  perf["showtime"].substring(0, 2) -
                  12 +
                  perf["showtime"].substring(2);
                withUrl = 1;
              } else {
                var temptime = "" + perf["showtime"].substring(0, 2) - 12;
                perfTime = "0" + temptime + perf["showtime"].substring(2);
                withUrl = 1;
              }
            } else {
              perfTime = perf["showtime"];
              withUrl = 1;
            }
            if (time.substring(0, 5) === perfTime) {
              perfurls = perf["showtext"];
              withUrl = 1;
            }
          }
        }
      }
      return null;
    });
    var comment = params.row.comment;
    params.data.fixedComment = "";
    for (var x = 0; x < comment.length; x++) {
      if (comment.charAt(x) === ";") {
        params.data.fixedComment += ", ";
      } else {
        params.data.fixedComment += comment.charAt(x);
      }
    }

    return (
      <Grid key={index} item>
        <Container
          className="time-container"
          disableGutters
          maxWidth='lg'
          style={{ marginRight: 5, marginTop: 10 }}
        >
          <div id={"hoverBase"}>
            <Button
              rel="noopener noreferrer"
              variant="contained"
              href={perfurls}
              target="_blank"
              color={withUrl ? "default" : "secondary"}
              disabled={!withUrl}
              style={
                withUrl
                  ? {
                    fontSize: 15,
                    backgroundColor: BoxofficeDeepRed,
                    color: "white",
                  }
                  : null
              }
            >
              {time}
            </Button>
          </div>
          <Typography sx={{ p: 1 }} id="hoverPopup" style={{ fontSize: 12 }}>
            {params.row.initials && params.row.initials.trim() === ""
              ? params.row["imported"]
              : params.row["initials"]}
            {" " + params.row.dtstamp + " " + params.row.tmstamp}
          </Typography>
        </Container>
      </Grid>
    );
  });

  const maxMovies = params.data.showtimeMovieCounter <= 3;
  return (
    <div
      className={maxMovies ? "comment" : "comment-extra"}
      style={
        maxMovies
          ? { marginTop: 10 }
          : { marginTop: 10, display: params.data.showExtra }
      }
    >
      <span
        style={
          maxMovies
            ? { marginBottom: 10, fontSize: 15, color: "grey" }
            : { marginBottom: 10, fontSize: 15, color: "grey" }
        }
      >
        {params.data.fixedComment}
      </span>

      <div
        className={maxMovies ? "output_time" : "output_time-extra"}
        style={
          maxMovies
            ? { marginTop: 10 }
            : { marginTop: 10, display: params.data.showExtra }
        }


      >
        <Grid container spacing={1}>
          {outputTime}
        </Grid>
      </div>
      {maxMovies ? (
        <React.Fragment>
          <div style={{ marginTop: 10 }}></div>
        </React.Fragment>
      ) : (
        <div
          className="spacer-extra"
          style={{ marginTop: 10, display: params.data.showExtra }}
        >
          <React.Fragment>
            <div style={{ marginTop: 10 }}></div>
          </React.Fragment>
        </div>
      )}
    </div>
  );
}
