import { configState } from "../../Config";
import { USER_INITIALS } from "./constants";

export const fetchData = ({
  endpoint,
  handleComplete,
  method = "POST",
  body = {},
  handleError = undefined,
  handleFinally = undefined,
}) => {
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });

  return fetch(`${configState.apiBase}${endpoint}`, {
    method,
    body: formData,
  })
    .then((response) => response.json())
    .then((response) => {
      handleComplete(response);
    })
    .catch((error) => {
      if (handleError) {
        handleError(error);
      }
    })
    .finally(() => {
      if (handleFinally) {
        handleFinally();
      }
    });
};

export const getUserInitials = () => {
  const email = localStorage.getItem("email");
  if (email && USER_INITIALS.find((user) => user.email === email)) {
    return USER_INITIALS.find((user) => user.email === email).initials;
  }
  return "ZZZ";
};
