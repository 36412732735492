export const SourceCode = [
    "109Cinemas",
    "18TicketsAPI",
    "1StepAhead",
    "21Cineplex",
    "2Tickets",
    "3BelowPeach",
    "3BelowRTS",
    "AbbeygateCinema",
    "AbingdonPeach",
    "AbingdonRTS",
    "AceCinemas",
    "ActiveTicketGenericJSON",
    "AcxOdata",
    "ACXPeach",
    "AcxVista",
    "Admitone",
    "AdmitOneAPI",
    "AeonCinema",
    "AFACinemasCineRitz",
    "Afisha",
    "AFIVista",
    "Agile",
    "AktualitySK",
    "AlbaCinema",
    "AlexCinemas",
    "Allocine",
    "AllocineQL",
    "AmbassadorTheatres",
    "AMCAPI",
    "AMCint",
    "AMCVista",
    "AmericaRTS",
    "ApertureFilmbot",
    "AppleRTS",
    "ArchesGenericJSON",
    "Arclight",
    "ArcoplexVendaBem",
    "ArenaCinemas",
    "ArkLodge",
    "Artesiete",
    "ArthouseCHGeneric",
    "Athena",
    "AtlasCinesArg",
    "AtMovies",
    "Austin",
    "BanquetFilmbot",
    "BathgateGenericJSON",
    "BCGRTS",
    "BeartoothFilmbot",
    "BedfordFilmbot",
    "BeehiveSavoy",
    "BennettNO",
    "BetaCineplex",
    "BettWeb",
    "Bfi",
    "BFILondon",
    "BHDStarVista",
    "Biglietto",
    "BigScreen",
    "Biletinial",
    "Biletiva",
    "BiografBillet",
    "BiosAgenda",
    "BioSE",
    "BirminghamPeach",
    "BirminghamRTS",
    "BlanchesterGenericJSON",
    "Blitz",
    "BlueCinema",
    "BMSI",
    "BMSIjson",
    "BnbApi",
    "BnbPeach",
    "BnbVista",
    "BombayPeach",
    "BombayRTS",
    "BonaApiGenericJSON",
    "BookMyShowIDNGeneric",
    "BoulevardRTS",
    "Bowtie",
    "BowtieVista",
    "BoxOffice",
    "BrainDeadFilmbot",
    "Branson",
    "BrattleFilmbot",
    "BroadwayAPIGenericJSON",
    "BroadwayCircuit",
    "BroadwayVista",
    "Brushfire",
    "BygdekinoenGenericJSON",
    "CabbRTS",
    "CampusWest",
    "Capcinema",
    "Caribbean",
    "CaribbeanPR",
    "CarlScrape",
    "CarnegieScienceCenter",
    "CARTS",
    "CarverPeach",
    "CarverRTS",
    "CastelloLopes",
    "CastleCinemaGenericJSON",
    "CathayCineplexes",
    "CCCOmniterm",
    "CCUAEVista",
    "CecOmniterm",
    "Celebration",
    "CelebrationPeach",
    "CelebrityPeach",
    "CelebrityRTS",
    "CenterEdgeS3Bucket",
    "CenturyZafVista",
    "CGRcinemas",
    "CGV",
    "Cgv_id",
    "CGV_USA",
    "CGV_Vn",
    "CGVVista",
    "CGVVNMAPI",
    "CGWPeach",
    "CGWRTS",
    "ChabotRTS",
    "Chook",
    "Cineapolis",
    "CineartPy",
    "CineboxMX",
    "CineCentre",
    "Cinecitta",
    "Cinecity",
    "Cineciudad",
    "CineColombia",
    "CinedotVista",
    "CineEnterprise",
    "CineFlix",
    "Cinegest",
    "CineGrancher",
    "Cineland",
    "CineluxRTS",
    "Cinema360",
    "Cinema3D",
    "Cinema3DLaRioja",
    "CinemaAdrogue",
    "CinemaBox",
    "CinemaCenter",
    "CinemaCityAPI",
    "CinemacityBG",
    "CinemaCityIl",
    "CinemaCityPOLVectorsoft",
    "CinemacityPor",
    "CinemaCityRO",
    "CinemaConcept",
    "CinemagicMX",
    "CinemagicVista",
    "CinemaHosting",
    "CinemallOmniAPI",
    "CinemallOmniterm",
    "CinemaNova",
    "CinemaNovaVista",
    "CinemaplusVeezi",
    "Cinemark",
    "CinemarkArg",
    "CinemarkBraApi",
    "CinemarkCA",
    "CinemarkCOAPI",
    "CinemarkCol",
    "CinemarkEc",
    "CinemarkPer",
    "CinemarkTwn",
    "CinemarkTwnVista",
    "CinemaSaverPeach",
    "CinemaSaverRTS",
    "CinemasNicaragua",
    "CinemasUniplexBrazil",
    "CinemaSunshine",
    "Cinematik",
    "CinemaWestPeach",
    "CinemaWestRTS",
    "CinemaxAPI",
    "Cinemaximum",
    "CinemaxJP",
    "CinemaxPor",
    "CinemaxSVK",
    "Cinemaxx",
    "CinemaxxDK",
    "CinemaxxVista",
    "Cinemaz",
    "Cinemex",
    "CinemotionIt",
    "Cinemovida",
    "CineplacePt",
    "CineplanetCl",
    "CineplanetPeru",
    "CineplexAu",
    "CineplexDe",
    "CineplexJapan",
    "CineplexOdeonAPI",
    "CineplexxAt",
    "Cinepolis",
    "CinepolisAPI",
    "CinepolisArgGenericJSON",
    "CinepolisBahrainVista",
    "CinepolisCol",
    "CinepolisColVista",
    "CinepolisGulf",
    "CinepolisID",
    "CinepolisIDN",
    "CinepolisMex",
    "CinepolisMexVista",
    "CinepolisSAAPI",
    "CinepolisSAVista",
    "CinepolisUAE",
    "CinepolisUSA",
    "CinepolisUSAPeach",
    "CinepolisVista",
    "CinepolisWidget",
    "CineprogApi",
    "CineQ",
    "Cinergie",
    "CinergyVista",
    "CineRive",
    "Cinesa",
    "Cinesacec",
    "CineSercla",
    "CinesFilmax",
    "CinesItau",
    "CinesLaCosta",
    "CinesModerno",
    "CineStar",
    "CinestarCz",
    "CinestarDeJson",
    "CinestarUAE",
    "CinestarVN",
    "CinestarzTheaterToolkit",
    "CinesUnidos",
    "CineSunstar",
    "CinesVerdi",
    "CineSystem",
    "Cinetix",
    "Cinetixx",
    "Cineweb",
    "Cineworld",
    "CineWorldIt",
    "CineworldUK",
    "Cinexo",
    "CinextEcuador",
    "CinexVEN",
    "Cinezona",
    "CircuitoSpcine",
    "CitiCinemas",
    "CitiCinemasCR",
    "CityBasePeach",
    "CityBaseRTS",
    "CitybaseVista",
    "CityCineplex",
    "Cityplex12",
    "Classic",
    "ClassicCinemasAUAPI",
    "ClevelandOmniAPI",
    "ClevelandPeach",
    "CloudTicketDe",
    "CloverPeach",
    "CloverRTS",
    "ClvOmniterm",
    "ClydeGenericJSON",
    "CMX",
    "CMXNCR",
    "Cobb",
    "CobblePeach",
    "CobblePeach",
    "CobbleRTS",
    "CollinsRoadPeach",
    "CollinsRoadRTS",
    "Compeso",
    "Compeso",
    "CompraEntradas",
    "CoyoteDIRTS",
    "CoyotePeach",
    "CoyoteRTS",
    "CPLUSAVista",
    "CPMCines",
    "CrouchEnd",
    "CSFD",
    "CulverOCAPI",
    "CurzonCinemas",
    "CurzonVista",
    "DamodarCinemas",
    "DanbarryPeach",
    "DanbarryRTS",
    "DavisRTS",
    "Dendy",
    "DiamondTicketing",
    "DieselKino",
    "DipsonRTS",
    "Dplace",
    "DplacePeach",
    "DplaceRetriever",
    "DplaceVista",
    "DraftVista",
    "EasyWare",
    "EasyWareFeed",
    "eBilleteNorGeneric",
    "EclipseVista",
    "Eiga",
    "ElCapPeach",
    "Elevent",
    "ElkaderRTS",
    "ElvisPeach",
    "ElvisRTS",
    "EmagineVista",
    "EmperorCinemas",
    "EmpireUK",
    "Energy",
    "Enjoyfilms",
    "Entgroup",
    "EntradasApi",
    "EntradasUno",
    "Envision",
    "EnvisionVista",
    "EpicPeach",
    "EpicPOSitive",
    "EpicRTS",
    "EpicVista",
    "Euroscoop",
    "Eurostar",
    "Eventcinemas",
    "EventLive",
    "EverymanPeach",
    "EverymanVista",
    "EvoPeach",
    "EvoVista",
    "EWave",
    "EZDing",
    "FandangoCodes",
    "FatCatsVista",
    "Filbalad",
    "FilmCaOmniApi",
    "FilmCaOmniterm",
    "FilmgardeSGP",
    "FilmhouseNGA",
    "FilmLincGenericJSON",
    "FilmOnet",
    "FilmSpot",
    "FilmStreams",
    "Finnkino",
    "FlagshipRTS",
    "Flixbrewhouse",
    "FlixIDNVista",
    "FlixOData",
    "FlixPeach",
    "FocalPoint",
    "Foroxity",
    "ForoxityVista",
    "Forum6",
    "ForumMovie",
    "FoxRTS",
    "FrankVista",
    "FridleyPeach",
    "FridleyRTS",
    "FridleyVista",
    "FRMRTS",
    "FusionVista",
    "GalaxyCinemaVietnam",
    "GalaxyMacau",
    "GalaxyTheatres",
    "GalaxyTheatres",
    "GalaxyVista",
    "GatewayFilmbot",
    "GemFilmbot",
    "Genesis",
    "GenRTS",
    "GhtcOmniterm",
    "GhtcVista",
    "GladstoneCinemas",
    "Glitterfish",
    "GoldenHarvest",
    "GoldenScreenCinemas",
    "GoldenVillage",
    "GoldOmniterm",
    "Golem",
    "Goodrich",
    "GoodrichPeach",
    "GoodrichRTS",
    "GoodrichVista",
    "GQTRTS",
    "GrandCinemasAu",
    "GrossPeach",
    "GrossRTS",
    "GrupBalana",
    "GrupoCine",
    "GrupoCine",
    "GtcPeach",
    "GtcVista",
    "GulbenkianGenericJSON",
    "Guzzo",
    "GymFilmbot",
    "HarkinsAPI",
    "HarperPeach",
    "HarperRTS",
    "Helios",
    "HometownRTS",
    "HotCinema",
    "Hoyts",
    "Hoytsau",
    "HoytsChl",
    "HoytsCLVista",
    "Hoytsnz",
    "HPVillageRTS",
    "HPVPeach",
    "HUNGenericV2",
    "Huskisson",
    "IMCCinemas",
    "ImgOmniterm",
    "in89",
    "IndyCinemasGenericJSON",
    "IndyCinemasUKGenericJSON",
    "IndyFilmbot",
    "IngressoJson",
    "Inox",
    "Ipic",
    "Jacro",
    "JacroBLOAD",
    "Jadwal",
    "JBFilmCenter",
    "JeanCocteau",
    "Jsonlines",
    "KaroFilms",
    "KenwoodPeach",
    "KenwoodPeach",
    "KerVista",
    "Ketelhuis",
    "KewPeach",
    "KewRTS",
    "KeystonePeach",
    "KeystoneRTS",
    "KinekorVista",
    "KinepolisApi",
    "Kinesis",
    "Kinetike",
    "KinoArena",
    "KinoBox",
    "KinochurCH",
    "Kinoheld",
    "Kinopoisk",
    "Kinopolis",
    "KinoProgramm",
    "Kitag",
    "KiwiKinos",
    "Korona",
    "LaemmleRTS",
    "LandmarkCAPeach",
    "LandmarkPOSitive",
    "LandmarkUSPeach",
    "LandmarkVista",
    "LaserCinemas",
    "LayoutIndex",
    "Lev",
    "LewesAPI",
    "LexLiveRTS",
    "LFSMalaysia",
    "Light",
    "LighthouseCinema",
    "LighthouseVista",
    "Limelight",
    "LimelightVista",
    "LiveTicket",
    "LivingroomTheaters",
    "LookPeach",
    "LookVista",
    "LotteCinemasVn",
    "LotteKorea",
    "LunaFilmbot",
    "Luxor",
    "LuxorFilm",
    "LuxorKino",
    "MajesticCinemas",
    "MajesticTheatre",
    "MajOmniterm",
    "MajorCineplex",
    "MajorCineplexScrape",
    "MalcoVista",
    "MamaroneckPeach",
    "MannOmniterm",
    "MapleVista",
    "MAQOmniterm",
    "MarcusVista",
    "MarqueePeach",
    "MarsEDV",
    "MarshallPeach",
    "MarshallRTS",
    "MaxMovie",
    "Maya",
    "MayaPeach",
    "MayaVista",
    "MBOMalaysia",
    "MCBCinemas",
    "MCLCinema",
    "MegaBox",
    "MegaOcio",
    "MegaplexTheatres",
    "MegaplexVista",
    "Megarama",
    "MetroCinemas",
    "MetrographVista",
    "MetroPeach",
    "MetroVista",
    "MiBoleteria",
    "Mirage",
    "MiramarTwn",
    "MJRPeach",
    "MJRVista",
    "MmCineplexes",
    "MobileMovie",
    "MobileMovieScrape",
    "MobileMovieScrape",
    "Monnaie",
    "MooreRTS",
    "Moveek",
    "MovieArteVendaBem",
    "MoviesAruba",
    "MovietimeMulticines",
    "MovieTkts",
    "MulticineBo",
    "MulticinemaSLV",
    "MultiCinesECU",
    "MulticineVendabem",
    "Multikino",
    "MultikinoAPI",
    "Multiplex",
    "MultiplexARGGeneric",
    "MultiplexITAAPI",
    "MultiplexOmniaCenter",
    "MusicBoxAPI",
    "MuviCinemas",
    "Naver",
    "NCGVistaOCAPI",
    "NFBio",
    "Nfkino",
    "NightflixDI",
    "NitehawkFilmbot",
    "NLDGenericV2",
    "NovaCinemas",
    "NovaCinemasPAGenericJSON",
    "NoveKino",
    "NPHPeach",
    "NPHVista",
    "NtgOmniterm",
    "Numetro",
    "NVTPeach",
    "NVTVista",
    "Ocine",
    "OdeonKino",
    "OdeonMulticines",
    "OdeonVista",
    "OhKino",
    "OlympicGenericJSON",
    "OlympicPHP",
    "Omniweb",
    "OneilVista",
    "OnyxRTS",
    "OranaCinemas",
    "OrientalTheaters",
    "OutNow",
    "OxfordFilmbot",
    "PacificFilms",
    "PacificScienceCenter",
    "PalaceCinemasScrape",
    "PalaceNovaScrape",
    "PalaceNovaVista",
    "Palaciodelcine",
    "ParadiseAPIGenericJSON",
    "ParadisePNGVista",
    "ParadiseVista",
    "ParagonCinemas",
    "ParagonVista",
    "ParamountRTS",
    "ParkPlazaPeach",
    "ParkPlazaRTS",
    "ParkwayCinemas",
    "PatheCh",
    "Pathenl",
    "PatronManager",
    "PatronManager",
    "Paytm",
    "PCCRTS",
    "PennVista",
    "PenOmniterm",
    "PeregrineSoftware",
    "PhoenixBigVista",
    "PhoenixMIVista",
    "PhoenixUK",
    "PhoenixVista",
    "PicHouseVista",
    "PictureHouse",
    "PictureshowVista",
    "PlatinumCineplex",
    "PlatinumCineplexVietnam",
    "PlatinumIDN",
    "PoppinRTS",
    "PortagePeach",
    "PortageRTS",
    "PortHU",
    "POSitive",
    "PowerPlantVista",
    "PremierePeach",
    "PremiumKino",
    "PrimePeach",
    "PrimeRTS",
    "PrimetimePeach",
    "PrimetimeRTS",
    "PrinceCharles",
    "PrincessTheater",
    "Procinal",
    "ProgramySvk",
    "ProspectorOmniAPI",
    "ProspectorPeach",
    "PSigloNuevo",
    "QFTVista",
    "QueenPeach",
    "QueenRTS",
    "Ravhen",
    "RbwOmniterm",
    "RCTOmniterm",
    "Reading",
    "ReadingCinemasApiGenericXML",
    "ReadingNz",
    "ReadingUsa",
    "RedRTS",
    "ReelPAVista",
    "ReelVista",
    "RegalApi",
    "RegencyRTS",
    "RegentStreetCinema",
    "REGOmniterm",
    "Renaissance",
    "RenaissanceAPI",
    "RenoirAPI",
    "ReservaentradasNew",
    "Retriever",
    "RialtoRTS",
    "RiversideStudios",
    "Riviera",
    "RivieraVista",
    "RoadhouseRTS",
    "RobinsonsMovieWorld",
    "RowhouseFilmbot",
    "RoxyCinemasGenericJSON",
    "RoyalFilms",
    "Rsb",
    "RTSQS",
    "SACinepolis",
    "SalmarOmniterm",
    "SanctuaryRTS",
    "Santikos",
    "Savoy",
    "SCGRTS",
    "Schullman",
    "SchulmanRTS",
    "ScienceAndMediaMuseum",
    "ScrnlandFilmbot",
    "SCUKVista",
    "SelfridgeAPIGenericJSON",
    "SelfridgesGenericJSON",
    "SFBio",
    "SFCinemaCity",
    "ShawSg",
    "Shoreline",
    "ShowbizCinemasAU",
    "ShowbizPeach",
    "ShowbizVista",
    "ShowcasePeach",
    "ShowcaseTestVista",
    "ShowcaseUK",
    "ShowcaseUKLabOdata",
    "ShowcaseUKVista",
    "ShowcaseVista",
    "ShowplaceRTS",
    "ShowroomFilmbot",
    "ShowtimeCinemas",
    "SilkyOtter",
    "SilverCinemas",
    "SilverscreenPeach",
    "SilverscreenRTS",
    "Silverspot",
    "SKCinemas",
    "SmgApi",
    "SmithsonianAPI",
    "SMTCinema",
    "SMVistaScrape",
    "SouthernOData",
    "SouthernVista",
    "SpaceCinema",
    "Spektrix",
    "SperrysMovieHouse",
    "SperrysVista",
    "SpotRetriever",
    "SreOmniterm",
    "StarCinemasGeneric",
    "StarCinemasUAE",
    "Starlight",
    "StarlightPeach",
    "StarlightPeach",
    "StarlightVista",
    "Staseraal",
    "StateFilmbot",
    "StateNZLVista",
    "SterKinekor",
    "StMichaelOmniterm",
    "STMOmniAPI",
    "StreamRTS",
    "StrikeAndReel",
    "SucreMulticines",
    "SunRayRTS",
    "SUNRTS",
    "SunsFilmbot",
    "SuperCines",
    "SuperCinesAPI",
    "SupercinesVEN",
    "SupercinesVen",
    "SureSeats",
    "TCLVista",
    "TGVVistaGenericJSON",
    "Thana",
    "TheaterToolkit",
    "TheElectric",
    "TheElectricVista",
    "TheHub",
    "TheLot",
    "TheVillagesGenericJSON",
    "TicketMaster",
    "Ticketmob",
    "Ticketnew",
    "Ticketsocket",
    "TicketSolve",
    "TJoy",
    "Toho",
    "TouchstarPeach",
    "TouchstarRTS",
    "TowerFilmbot",
    "Traumpalast",
    "TricityPeach",
    "TriCityRTS",
    "Tsepic",
    "Tsghtc",
    "Tsmovietavern",
    "Tsoneil",
    "TSRCinemax",
    "Tsshowbiz",
    "Tssouthern",
    "Tstexas",
    "Tsthegrand",
    "UACityline",
    "UcgBe",
    "UciCinemas",
    "UCICinemasBR",
    "UCICinemasPTGenericJSON",
    "UciDe",
    "UciDeApi",
    "UECRTS",
    "UltraStarVista",
    "United",
    "UnitedCinemasAPI",
    "VarsityFilmbot",
    "VaskaFilmbot",
    "VCAWSGW",
    "Veezi",
    "VeeziScrape",
    "Velox",
    "Vieshow",
    "VillageAuAPI",
    "VillageCinemas",
    "VinePeach",
    "VineRTS",
    "VioletCrownGeneric",
    "VioletCrownPOSitive",
    "VioletCrownVista",
    "VIPRTS",
    "VisionMaxRTS",
    "VistaBload",
    "VistaCinemasPHL",
    "VivaCinemas",
    "VogliadiCinema",
    "VoxApi",
    "VoxCinemas",
    "Vue",
    "VueCinemasNL",
    "VueScrape",
    "Wallis",
    "WarrenOmniterm",
    "WarsawRTS",
    "WebediaQL",
    "WebTic",
    "WeCinemas",
    "WestWindDI",
    "WestWindDIAPI",
    "WhitewaterRTS",
    "WilliamsburgPeach",
    "Wolff",
    "WyMoviesVista",
    "WyoPOSitive",
    "YelmoApi",
    "YesPlanet",
    "YorckDe",
    "YorckDeAPI",
    "YouCinemaOCAPI",
    "YouCinemasGeneric",
    "Zinema",
    "ZoeFilmbot",
    "Zonlusomundo",
    "ZurichRTS"
];