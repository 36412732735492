import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import uniqueId from "lodash/uniqueId";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import { configState } from "../../Config";
import querystring from "querystring";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { parse } from "date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import isEqual from "lodash/isEqual";

const fetchData = ({
  endpoint,
  handleComplete,
  method = "POST",
  body = {},
  handleError = undefined,
  handleFinally = undefined,
}) =>
  fetch(`${configState.apiBase}/${endpoint}`, {
    method,
    headers:
      method === "POST"
        ? {
            "Content-Type": "application/json",
          }
        : undefined,
    body: method === "POST" ? JSON.stringify(body) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {
      handleComplete(response);
    })
    .catch((error) => {
      if (handleError) {
        handleError(error);
      }
    })
    .finally(() => {
      if (handleFinally) {
        handleFinally();
      }
    });

const APIResults = () => {
  const [error, setError] = useState(undefined);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [manualFilter, setManualFilter] = useState(false);
  const [users, setUsers] = useState([]);
  const [id, setId] = useState(null);
  const [movieIds, setMovieIds] = useState([]);
  const [houseIds, setHouseIds] = useState([]);
  const [movieInput, setMovieInput] = useState("");
  const [houseInput, setHouseInput] = useState("");
  const [countries, setCountries] = useState([]);
  const [countryFilter, setCountryFilter] = useState(null);
  const [movies, setMovies] = useState([]);
  const [houses, setHouses] = useState([]);
  const [movieSearch, setMovieSearch] = useState("");
  const [houseSearch, setHouseSearch] = useState("");

  useEffect(() => {
    if (!id || manualFilter) {
      return;
    }
    setHouses([]);
    setMovies([]);
    fetchData({
      endpoint: "tiktokapi/countries",
      body: {
        userId: id,
      },
      handleComplete: (response) => {
        if (response.data) {
          setCountries(response.data);
        }
      },
    });
  }, [id, manualFilter]);

  useEffect(() => {
    if (!id || !countryFilter || manualFilter) {
      return;
    }
    fetchData({
      endpoint: "tiktokapi/movies",
      body: {
        userId: id,
        countryId: countryFilter,
        search: movieSearch,
      },
      handleComplete: (response) => {
        if (response.data) {
          setMovies(response.data);
        }
      },
    });
  }, [id, countryFilter, manualFilter, movieSearch]);

  useEffect(() => {
    if (!id || !countryFilter || manualFilter) {
      return;
    }
    fetchData({
      endpoint: "tiktokapi/houses",
      body: {
        userId: id,
        countryId: countryFilter,
        search: houseSearch,
      },
      handleComplete: (response) => {
        if (response.data) {
          setHouses(response.data);
        }
      },
    });
  }, [id, countryFilter, manualFilter, houseSearch]);

  useEffect(() => {
    setLoading(true);
    fetchData({
      endpoint: `api_user?${querystring.encode({
        function: "search",
      })}`,
      method: "GET",
      handleComplete: (results) => {
        setUsers(results);
      },
      handleError: () => setUsers([]),
      handleFinally: () => setLoading(false),
    });
  }, []);

  useEffect(() => {
    if (!users.length) {
      return;
    }
    const tiktok = users.find((user) => user.name === "tiktok");
    if (tiktok) {
      setId(tiktok.id);
    }
  }, [users]);

  useEffect(() => {
    if (!movieIds.length || !houseIds.length) {
      return;
    }
    setLoading(true);
    setError("");
    fetchData({
      endpoint: "tiktokapi/showtimes",
      body: {
        id,
        movieIds,
        houseIds,
      },
      handleComplete: (results) =>
        setData("data" in results ? results.data : []),
      handleError: () => {
        setData([]);
        setError("Error loading api results");
      },
      handleFinally: () => setLoading(false),
    });
  }, [id, movieIds, houseIds]);

  return (
    <div
      style={{
        display: "grid",
        gap: "1em",
        margin: "0 auto",
        maxWidth: "62.5em",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1em",
          width: "100%",
        }}
      >
        <FormControl>
          <FormLabel>
            Manual filter
            <Checkbox
              checked={manualFilter}
              onChange={() => setManualFilter(!manualFilter)}
              title="Manual"
            ></Checkbox>
          </FormLabel>
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          gap: "1em",
        }}
      >
        <FormControl>
          <FormLabel>User</FormLabel>
          <Select
            value={id}
            onChange={(e) => {
              setId(e.target.value);
            }}
            style={{
              width: "6.25em",
            }}
            disabled={loading}
          >
            <MenuItem value=""></MenuItem>
            {users.map((user) => (
              <MenuItem key={uniqueId("user")} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {manualFilter ? (
          <>
            <FormControl style={{ padding: "1rem" }}>
              <FormLabel>Add movie ID</FormLabel>
              <div
                style={{
                  display: "grid",
                  gap: "1rem",
                  gridTemplateAreas: `"input button" "ids ids"`,
                }}
              >
                <TextField
                  value={movieInput}
                  onChange={(e) => setMovieInput(e.target.value)}
                  style={{ gridArea: "input" }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setMovieIds([...movieIds, movieInput]);
                    setMovieInput("");
                  }}
                  style={{ gridArea: "button" }}
                >
                  Add
                </Button>
                <div style={{ gridArea: "ids" }}>
                  {movieIds.map((movieId) => (
                    <Button
                      key={uniqueId("movieId")}
                      variant="contained"
                      color="disabled"
                      onClick={() => {
                        setMovieIds(movieIds.filter((i) => i !== movieId));
                      }}
                    >
                      {movieId} x
                    </Button>
                  ))}
                </div>
              </div>
            </FormControl>
            <FormControl style={{ padding: "1rem" }}>
              <FormLabel>Add house ID</FormLabel>
              <div
                style={{
                  display: "grid",
                  gap: "1rem",
                  gridTemplateAreas: `"input button" "ids ids"`,
                }}
              >
                <TextField
                  value={houseInput}
                  onChange={(e) => setHouseInput(e.target.value)}
                  style={{ gridArea: "input" }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setHouseIds([...houseIds, houseInput]);
                    setHouseInput("");
                  }}
                  style={{ gridArea: "button" }}
                >
                  Add
                </Button>
                <div style={{ gridArea: "ids" }}>
                  {houseIds.map((houseId) => (
                    <Button
                      key={uniqueId("houseId")}
                      variant="contained"
                      color="disabled"
                      onClick={() => {
                        setHouseIds(houseIds.filter((i) => i !== houseId));
                      }}
                    >
                      {houseId} x
                    </Button>
                  ))}
                </div>
              </div>
            </FormControl>
          </>
        ) : (
          <>
            <FormControl style={{ width: "12.5rem" }}>
              <Autocomplete
                options={countries.map((country) => country.country_code)}
                filterSelectedOptions
                onChange={(e, selected) => {
                  const country = countries.find(
                    ({ country_code }) => country_code === selected,
                  );
                  if (country) {
                    setCountryFilter(country.id);
                  }
                }}
                value={
                  countries.find((country) => country.id === countryFilter)
                    ? countries.find((country) => country.id === countryFilter)
                        .country_code
                    : undefined
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    label="Country"
                    placeholder="Pick country"
                  />
                )}
              />
            </FormControl>
            <FormControl style={{ width: "12.5rem" }}>
              <Autocomplete
                multiple
                options={movies.map((movie) => movie.name)}
                filterSelectedOptions
                inputValue={movieSearch}
                onInput={(e) => {
                  setMovieSearch(e.target.value);
                }}
                onChange={(e, data) => {
                  const nextMovieIds = data.reduce((ids, title) => {
                    const movie = movies.find(({ name }) => name === title);
                    if (movie) {
                      ids.push(movie.movie_id);
                    }
                    return ids;
                  }, []);
                  if (!isEqual(movieIds, nextMovieIds)) {
                    setMovieIds(nextMovieIds);
                  }
                }}
                value={movies
                  .filter((movie) => movieIds.includes(movie.movie_id))
                  .map((movie) => movie.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    label="Movie"
                    placeholder="Pick movie"
                  />
                )}
              />
            </FormControl>
            <FormControl style={{ width: "12.5rem" }}>
              <Autocomplete
                multiple
                options={houses.map((house) => house.name)}
                filterSelectedOptions
                inputValue={houseSearch}
                onInput={(e) => {
                  setHouseSearch(e.target.value);
                }}
                onChange={(e, data) => {
                  const nextHouseIds = data.reduce((ids, title) => {
                    const house = houses.find(({ name }) => name === title);
                    if (house) {
                      ids.push(house.house_id);
                    }
                    return ids;
                  }, []);
                  if (!isEqual(houseIds, nextHouseIds)) {
                    setHouseIds(nextHouseIds);
                  }
                }}
                value={houses
                  .filter((house) => houseIds.includes(house.house_id))
                  .map((house) => house.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    label="Theater"
                    placeholder="Pick theaters"
                  />
                )}
              />
            </FormControl>
          </>
        )}
      </div>
      <div>
        {!loading && !error && !data.length && (
          <Typography variant="h6">No results found</Typography>
        )}
        {error && <Typography variant="h6">{error}</Typography>}
        {loading ? (
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <CircularProgress />
          </div>
        ) : (
          <div>
            {data.map((house) => (
              <div key={house.id}>
                <div
                  style={{
                    backgroundColor: "#b00020",
                    borderRadius: "3px",
                    color: "white",
                    padding: "0.25rem",
                  }}
                >
                  <Typography variant="h4">{house.name}</Typography>
                </div>
                <div style={{ padding: "0.5rem" }}>
                  {house.movies.map((movie) => (
                    <div key={`${house.id}-${movie.id}`}>
                      <Typography variant="h5">{movie.name}</Typography>
                      <div style={{ padding: "0.5rem" }}>
                        {movie.schedule.map((showdate, index) => (
                          <div
                            key={`${house.id}-${movie.id}-${showdate.showdate}`}
                            style={
                              !!index
                                ? {
                                    paddingTop: "0.5rem",
                                    borderTop: "1px solid #888",
                                    marginTop: "0.5rem",
                                  }
                                : {}
                            }
                          >
                            <Typography variant="h6">
                              {parse(
                                showdate.showdate,
                                "yyyyMMdd",
                                new Date(),
                              ).toDateString()}
                            </Typography>
                            <div
                              style={{
                                display: "grid",
                                gap: "1rem",
                                gridTemplateColumns: "repeat(auto-fill, 5rem",
                              }}
                            >
                              {showdate.showtimes.map((showtime) => (
                                <div
                                  key={uniqueId("showtime")}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {showtime.time}
                                  {showtime.links.map((link) => (
                                    <Tooltip
                                      key={uniqueId("link")}
                                      title={link.link}
                                    >
                                      <div
                                        role="button"
                                        style={{
                                          alignItems: "center",
                                          cursor: "pointer",
                                          backgroundColor: "#CCC",
                                          borderRadius: "3px",
                                          display: "flex",
                                          padding: "0.25rem",
                                          width: "100%",
                                          marginTop: "0.25rem",
                                        }}
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            link.link,
                                          );
                                        }}
                                      >
                                        <span
                                          style={{
                                            width: "4rem",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            fontSize: "10px",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {link.vendor}
                                        </span>{" "}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="16"
                                          height="16"
                                        >
                                          <title>content-copy</title>
                                          <path
                                            d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </div>
                                    </Tooltip>
                                  ))}
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default APIResults;
