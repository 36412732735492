/*
 ** Ascending and Descending Sorts for any property
 */
const ApropComparator = (propName) => (a, b) =>
  a[propName] === b[propName] ? 0 : a[propName] < b[propName] ? -1 : 1;
const DpropComparator = (propName) => (a, b) =>
  a[propName] === b[propName] ? 0 : a[propName] < b[propName] ? 1 : -1;

/*
 ** createSortHandler
 */
export const createSortHandler = (property, object) => (event) => {
  var newstate = "asc";
  if (object.state.order === "asc") newstate = "desc";

  var newData = object.state.dataSource;

  var i;
  for (i = 0; i < newData.length; i++) {
    if (newData[i][property] == null) {
      newData[i][property] = "";
    }
  }

  if (newstate === "asc") newData.sort(ApropComparator(property));
  else newData.sort(DpropComparator(property));

  object.setState({
    orderBy: property,
    order: newstate,
    dataSource: newData,
  });
};

/*
 ** createSortHandler
 */
export const newSearchSort = (property, order, oldData) => {
  var newData = oldData;

  var i;
  for (i = 0; i < newData.length; i++) {
    if (newData[i][property] == null) {
      newData[i][property] = "";
    }
  }

  if (order === "asc") newData.sort(ApropComparator(property));
  else newData.sort(DpropComparator(property));

  return newData;
};
