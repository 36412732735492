import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import Token from "../components/Token";
import SearchBar from "../components/SearchBar/SearchBar";
import HouseTable from "../components/HouseTable";
import { styles } from "../components/CsStyles";

/*
 ** Houses - a list of houses based on search criteria passed-in
 */
class Houses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search_house_id: this.props.match.params["house_id"],
      search_name: "",
      search_chain_id: "",
      search_chain_name: "",
      search_altid: "",
      search_dbzid: "",
      search_city: "",
      search_state: "",
      search_country: "",
      ancholEl: null,
      open: false,
    };

    // Title
    if (this.props.setTitle != null) {
      this.props.setTitle("Houses");
    }
  }

  /*
   ** componentDidMount - when first mounting, do query, which is probably empty
   */
  componentDidMount() {
    this.setState(this.props.match.params);
    this.handleSearchCriteria();
  }

  /*
   **
   ** fetchData
   **
   */
  handleSearchCriteria = (data) => () => {
    if (data) {
      if (data.main !== undefined && data.main !== "") {
        if (isNaN(data.main)) {
          if (data.mainField === "house_id") data.house_id = undefined;

          data.name = data.main;
          data.mainField = "name";
        } else {
          if (data.mainField === "name") data.name = undefined;

          data.house_id = data.main;
          data.mainField = "house_id";
        }
      }

      this.setState({
        search_house_id: data.house_id === undefined ? "" : data.house_id,
        search_name: data.name === undefined ? "" : data.name,
        search_chain_name: data.chain_name === undefined ? "" : data.chain_name,
        search_chain_id: data.chain_id === undefined ? "" : data.chain_id,
        search_altid: data.altid === undefined ? "" : data.altid,
        search_dbzid: data.dbzid === undefined ? "" : data.dbzid,
        search_city: data.city === undefined ? "" : data.city,
        search_state: data.state === undefined ? "" : data.state,
        search_country: data.country === undefined ? "" : data.country.join(','),
      });
    }
  };

  /*
   ** render
   **
   */
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Token />
        <CssBaseline />

        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />

            <div
              className={classes.advancedSearch}
              noValidate
              autoComplete="off"
            >
              <SearchBar
                {...this.props}
                onSave={this.handleSearchCriteria}
                searchCriteria={[
                  ["Name", "name"],
                  ["House Id", "house_id"],
                  ["Chain Name", "chain_name"],
                  ["Chain Id", "chain_id"],
                  ["Alt Id", "altid"],
                  ["DBZ Id", "dbzid"]
                ]}
                show_country
                show_state_and_city
              />
            </div>

            <div className={classes.tableContainer}>
              <HouseTable
                house_id={this.state.search_house_id}
                search={this.state.search_name}
                chain_id={this.state.search_chain_id}
                chain_name={this.state.search_chain_name}
                altid={this.state.search_altid}
                city={this.state.search_city}
                state={this.state.search_state}
                country={this.state.search_country}
                dbzid={this.state.search_dbzid}
              />
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Houses.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Houses);
